import React from 'react';
import {theme} from '@gcui/react';
import {Provider} from 'react-redux';
import {styled} from '@mui/material';
import {useHistory} from "react-router-dom";
import {Security} from '@okta/okta-react';
import {ToastContainer} from 'react-toastify';
import {LocalizationProvider} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {grey} from "@mui/material/colors";

import { Router } from './Router';
import { store } from "../store";
import ErrorBoundary from './ErrorBoundary';

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_OKTA_CALLBACK_PATH;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const HOST = window.location.host;
const REDIRECT_URI = `${window.location.protocol}//${HOST}${CALLBACK_PATH}`;

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
};

const oktaAuth = new OktaAuth(config);

// Without creating theme in app, some styles were broken in GCUI
// TODO: Move gray color palette to dependency
const {palette: themePalette} = theme;
const muiTheme = createTheme({
    ...theme,
    palette: {
        ...themePalette,
        grey: grey
    }
});

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history?.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
      <ErrorBoundary>
        <AppWrapper>
          <LocalizationProvider dateAdapter={DateAdapter}>
                <ThemeProvider theme={muiTheme}>
                  <Provider store={store}>
                    <Security
                        restoreOriginalUri={restoreOriginalUri}
                        oktaAuth={oktaAuth}
                    >
                      <Router/>
                    </Security>
                  </Provider>
                </ThemeProvider>
              {/*TODO: Remove react-toastify*/}
              <ToastContainer
                  position='top-center'
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
          </LocalizationProvider>
        </AppWrapper>
      </ErrorBoundary>
  );
}

const AppWrapper = styled('div')(() => ({}));

export default App;
