import React, {FC, useMemo} from 'react';

import {
    TaskFilters,
    TaskPhase,
    taskPhaseList
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskPhaseFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const TaskPhaseFilterBar: FC<TaskPhaseFilterBarProps> = ({
    filters,
    setFilters,
}) => {
    const phaseFilterOptions = useMemo<IOption[]>(() => {
        const phaseList = Object.keys(taskPhaseList) as TaskPhase[];
        if (phaseList.length) {
            return phaseList.map(phase => ({
                label: taskPhaseList[phase]?.label,
                value: taskPhaseList[phase]?.value,
            }))
        }
        return [];
    }, []);


    return (
        <MultiSelectPopover
            togglerTitle={"PHASE"}
            selectedValues={filters.phases || []}
            options={phaseFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, phases: selectedItems.map(item => item.value as TaskPhase)})
            }}
            onReset={() => setFilters({...filters, phases: []})}
            searchPlaceholder={'Search for a Phase'}
        />
    );
};