import React from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {DatePicker, LoadingButton} from "@mui/lab";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import {
    Program,
    ProgramLOB,
    programLOBList,
    ProgramType,
    programTypeList
} from "../../types";
import {useGetClientListQuery} from "../../services";

interface UpdateProgramModalProps {
    open: boolean;
    onClose: () => void;
    onEditProgram: (values: Program) => void;
    loading: boolean;
    programData: Program;
}

export const UpdateProgramModal: React.FC<UpdateProgramModalProps> = ({
    open,
    onClose,
    onEditProgram,
    loading,
    programData,
}) => {
    const {data: clientListData} = useGetClientListQuery({});

    const editProgramSchema = yup.object().shape({
        name: yup.string().required(),
        client_id: yup.number().required(),
        class_of_business: yup.string().required(),
        type: yup.string().required(),
        target_date: yup.date().required(),
        expiration_date: yup.string().required(),
    });

    const editProgramForm = useFormik({
        initialValues: {
           ...programData,
            client_id: programData.client.id
        },
        validationSchema: editProgramSchema,
        onSubmit: (values) => {
            onEditProgram(values);
        }
    });


    const { values, handleSubmit, handleChange, setFieldValue, touched, errors, handleBlur, isValid, dirty } = editProgramForm;

    return (
        <Dialog open={open} onClose={onClose} scroll="paper" fullWidth maxWidth={"sm"}>
            <DialogTitle color={'text.primary'}>
                Program Details
            </DialogTitle>
            <DialogContent>
                <Container disableGutters>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: {xs: 'flex-start', md: 'flex-end'},
                            gap: 2,
                            mt: 4
                        }}>

                            {/* Name */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant="body1" fontWeight={700}>Program name:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <TextField
                                        fullWidth
                                        name={"name"}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        color="primary"
                                        variant="outlined"
                                        error={touched.name ? !!errors.name : undefined}
                                    />
                                </Grid>
                            </Grid>

                            {/* Client */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant="body1" fontWeight={700}>Client:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Select
                                        fullWidth
                                        name={'client_id'}
                                        value={values.client_id}
                                        onChange={handleChange}
                                    >
                                        {
                                            clientListData && clientListData.data.length > 0 &&
                                            clientListData.data.map((client, i) => (
                                                <MenuItem key={`client-${i}`} value={client.id}>
                                                    {client.common_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </Grid>

                            {/* LOB */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant={"body1"} fontWeight={700}>LOB:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Select
                                        fullWidth
                                        name={'class_of_business'}
                                        value={values.class_of_business}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled value={''}>
                                            <em>LOB</em>
                                        </MenuItem>
                                        {
                                            (Object.keys(programLOBList) as ProgramLOB[]).map((item, i) => (
                                                <MenuItem
                                                    key={`program-lob-item-${i}`}
                                                    value={programLOBList[item].value}
                                                >
                                                    {programLOBList[item].label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </Grid>

                            {/* Type */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant={"body1"} fontWeight={700}>Type:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Select
                                        fullWidth
                                        name={'type'}
                                        value={values.type}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled value={''}>
                                            <em>Type</em>
                                        </MenuItem>
                                        {
                                            (Object.keys(programTypeList) as ProgramType[]).map((item, i) => (
                                                <MenuItem
                                                    key={`program-tpe-item-${i}`}
                                                    value={programTypeList[item].value}
                                                >
                                                    {programTypeList[item].label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            </Grid>

                            {/* Effective Date */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant={"body1"} fontWeight={700}>Effective date:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <DatePicker
                                        value={values.target_date}
                                        onChange={(newValue) => {
                                            setFieldValue('target_date', newValue)
                                        }}
                                        renderInput={(params) => <TextField variant="outlined" fullWidth {...params} />}
                                    />
                                </Grid>
                            </Grid>

                            {/* Expiration Date */}
                            <Grid container columnSpacing={3}>
                                <Grid item md={4} xs={12} display="flex" alignItems="center" p={0} sx={{justifyContent: {md: 'flex-end'} }}>
                                    <Typography variant={"body1"} fontWeight={700}>Expiration date:</Typography>
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <DatePicker
                                        value={values.expiration_date}
                                        onChange={(newValue) => {
                                            setFieldValue('expiration_date', newValue)
                                        }}
                                        renderInput={(params) => <TextField variant="outlined" fullWidth {...params} />}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 10}}>
                            <Button onClick={onClose}>
                                CANCEL
                            </Button>
                            <LoadingButton
                                type='submit'
                                disabled={!dirty || !isValid}
                                loading={loading}
                            >
                                SAVE
                            </LoadingButton>
                        </Box>
                    </form>
                </Container>
            </DialogContent>
        </Dialog>
    );
};
