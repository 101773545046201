import React from "react";
import {Container, Typography} from "@mui/material";

export const TableEmptyList: React.FC = () => {
    return (
        <Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minHeight: '300px'}}>
            <Typography>No items found.</Typography>
        </Container>
    );
};

