import {theme} from "@gcui/react";
import {User} from "./User";
import {Task} from "./Task";

// TODO: Check is it Right Type
export enum ActivityType {
    Message = 'message',
    FileUpload = 'file_upload',
    Approval = 'approval',
    Denial = 'denial',
    ArchiveTask = 'archiveTask',
    UnarchiveTask = 'unArchiveTask',
    CompleteTask = 'completeTask',
    UncompleteTask = 'unCompleteTask',
}

export enum ActivityApprovalStatus {
    New = 'new',
    Approved = 'approved',
    Declined = 'declined'
}

export interface Activity {
    id: number;
    created_at: Date;
    updated_at: Date;
    type: ActivityType;
    author: User;
    text: string;
    upload_location: string;
    approval_status: ActivityApprovalStatus;
    file_path: string;
    filename: string;
    parent_id: number;
    parent?: Activity;
    task_id?: number;
    task?: Task;
    liked: boolean;
    likes_count: number;
}

export const activityTypeList: { [key in ActivityType]: any } = {
    message: {
        label: 'Comments', // Message
        value: 'message',
        key: 1,
        color: theme.palette.text.secondary
    },
    file_upload: {
        label: 'Uploads', // File Upload
        value: 'file_upload',
        key: 2,
        color: theme.palette.text.secondary
    },
    approval: {
        label: 'Approval',
        value: 'approval',
        key: 3,
        color: theme.palette.text.secondary
    },
    denial: {
        label: 'Denial',
        value: 'denial',
        key: 4,
        color: theme.palette.text.secondary
    },
    archiveTask: {
        label: 'Archive Task',
        value: 'archiveTask',
        key: 5,
        color: theme.palette.text.secondary
    },
    unArchiveTask: {
        label: 'Unarchive Task',
        value: 'unArchiveTask',
        key: 6,
        color: theme.palette.text.secondary
    },
    completeTask: {
        label: 'Complete Task',
        value: 'completeTask',
        key: 7,
        color: theme.palette.text.secondary
    },
    unCompleteTask: {
        label: 'Uncomplete Task',
        value: 'unCompleteTask',
        key: 8,
        color: theme.palette.text.secondary
    },
};