import { theme } from "@gcui/react";
import {Client} from "./Client";

export type ProgramOrderBy = 'id' | 'name' | 'type' | 'class_of_business' | 'year' | 'updated_at' | 'target_date' | 'status' | 'client';

export enum ProgramType {
    Aggregate = 'aggregate',
    PerRisk  = 'perRisk',
    QuotaShare = 'quotaShare',
    SurplusShare = 'surplusShare',
    PerOccurrence = 'perOccurrence',
    Facultative = 'facultative',
}

export enum ProgramLOB {
    Cyber = 'cyber',
    FinancialLines = 'financialLines',
    Casualty = 'casualty',
    PoliticalRiskAndTradeCredit = 'politicalRiskAndTradeCredit',
    Property = 'property',
    Surety = 'surety',
    WorkersComp = 'workersComp',
    AccidentAndHealth = 'accidentAndHealth',
    Captives = 'captives',
    CropOrAgriculture = 'cropOrAgriculture',
    EAndS = 'EAndS',
    Florida = 'florida',
    Life = 'life',
    MedicalProfessional = 'medicalProfessional',
    MGAOrProgram = 'MGAOrProgram',
    Mortgage = 'mortgage',
    MutualOrRegional = 'mutualOrRegional',
    PersonalLines = 'personalLines',
    PublicEntity = 'publicEntity',
    PublicSector = 'publicSector',
    WCStateFunds = 'WCStateFunds',
    Insuretech = 'insuretech',
    StartUps = 'startUps',
    ProfLiability = 'profLiability',
}

export enum ProgramStatus {
    InProgress = 'in_progress',
    Complete = 'complete',
}

export interface Program {
    id: number;
    client_id: number;
    client: Client;
    name: string;
    status: ProgramStatus;
    type: ProgramType;
    class_of_business: ProgramLOB;
    target_date: Date;
    start_date: Date;
    created_at: Date;
    updated_at: Date;
    expiration_date: Date;
    year: number;
    is_active: boolean;
    notes: string;
    next_steps: string;
}

export const programStatusList: { [key in ProgramStatus]: any }  = {
    in_progress: {
        label: 'In Progress',
        value: 'in_progress',
        key: 1,
        color: theme.palette.text.secondary
    },
    complete: {
        label: 'Complete',
        value: 'complete',
        key: 2,
        color: theme.palette.text.secondary
    },
};

export const programTypeList: { [key in ProgramType]: any } = {
    aggregate: {
        label: 'Aggregate',
        value: 'aggregate',
        key: 1,
        color: theme.palette.text.secondary
    },
    perRisk: {
        label: 'Per Risk',
        value: 'perRisk',
        key: 2,
        color: theme.palette.text.secondary
    },
    quotaShare: {
        label: 'Quota Share',
        value: 'quotaShare',
        key: 3,
        color: theme.palette.text.secondary
    },
    surplusShare: {
        label: 'Surplus Share',
        value: 'surplusShare',
        key: 4,
        color: theme.palette.text.secondary
    },
    perOccurrence: {
        label: 'Per Occurrence',
        value: 'perOccurrence',
        key: 5,
        color: theme.palette.text.secondary
    },
    facultative: {
        label: 'Facultative',
        value: 'facultative',
        key: 6,
        color: theme.palette.text.secondary
    },
};

export const programLOBList: { [key in ProgramLOB]: any } = {
    cyber: {
        label: 'Cyber',
        value: 'cyber',
        key: 1,
        color: theme.palette.text.secondary
    },
    financialLines: {
        label: 'Financial Lines',
        value: 'financialLines',
        key: 2,
        color: theme.palette.text.secondary
    },
    casualty: {
        label: 'Casualty',
        value: 'casualty',
        key: 3,
        color: theme.palette.text.secondary
    },
    politicalRiskAndTradeCredit: {
        label: 'Political Risk & Trade Credit',
        value: 'politicalRiskAndTradeCredit',
        key: 4,
        color: theme.palette.text.secondary
    },
    property: {
        label: 'Property',
        value: 'property',
        key: 5,
        color: theme.palette.text.secondary
    },
    surety: {
        label: 'Surety',
        value: 'surety',
        key: 6,
        color: theme.palette.text.secondary
    },
    workersComp: {
        label: 'Workers Comp',
        value: 'workersComp',
        key: 7,
        color: theme.palette.text.secondary
    },
    accidentAndHealth: {
        label: 'Accident & Health',
        value: 'accidentAndHealth',
        key: 8,
        color: theme.palette.text.secondary
    },
    captives: {
        label: 'Captives',
        value: 'captives',
        key: 9,
        color: theme.palette.text.secondary
    },
    cropOrAgriculture: {
        label: 'Crop/Agriculture',
        value: 'cropOrAgriculture',
        key: 10,
        color: theme.palette.text.secondary
    },
    EAndS: {
        label: 'E&S',
        value: 'EAndS',
        key: 11,
        color: theme.palette.text.secondary
    },
    florida: {
        label: 'Florida',
        value: 'florida',
        key: 12,
        color: theme.palette.text.secondary
    },
    life: {
        label: 'Life',
        value: 'life',
        key: 13,
        color: theme.palette.text.secondary
    },
    medicalProfessional: {
        label: 'Medical Professional',
        value: 'medicalProfessional',
        key: 14,
        color: theme.palette.text.secondary
    },
    MGAOrProgram: {
        label: 'MGA/Program',
        value: 'MGAOrProgram',
        key: 15,
        color: theme.palette.text.secondary
    },
    mortgage: {
        label: 'Mortgage',
        value: 'mortgage',
        key: 16,
        color: theme.palette.text.secondary
    },
    mutualOrRegional: {
        label: 'Mutual/Regional',
        value: 'mutualOrRegional',
        key: 17,
        color: theme.palette.text.secondary
    },
    personalLines: {
        label: 'Personal Lines',
        value: 'personalLines',
        key: 18,
        color: theme.palette.text.secondary
    },
    publicEntity: {
        label: 'Public Entity',
        value: 'publicEntity',
        key: 19,
        color: theme.palette.text.secondary
    },
    publicSector: {
        label: 'Public Sector',
        value: 'publicSector',
        key: 20,
        color: theme.palette.text.secondary
    },
    WCStateFunds: {
        label: 'WC State Funds',
        value: 'WCStateFunds',
        key: 21,
        color: theme.palette.text.secondary
    },
    insuretech: {
        label: 'Insuretech',
        value: 'insuretech',
        key: 22,
        color: theme.palette.text.secondary
    },
    startUps: {
        label: 'Start Ups',
        value: 'startUps',
        key: 23,
        color: theme.palette.text.secondary
    },
    profLiability: {
        label: 'Prof Liability',
        value: 'profLiability',
        key: 24,
        color: theme.palette.text.secondary,
    },
};

export const programYearsList = [
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
];
