import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button, ButtonGroup, Checkbox,
    Container, FormControlLabel,
    InputAdornment, Menu, MenuItem,
    OutlinedInput, Pagination
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {MainLayout} from '../../../layouts';
import {CreateProgramModal} from '../../../components/Modals';
import {useAuthContext} from '../../../contexts/AuthContext';
import {TemplatesTable} from '../../../components/Tables/TemplatesTable';
import {StatusTimeInfo, TableEmptyList, TableLoader,} from '../../../components/UI';
import {
    CreateProgramInput,
    ProgramFilters,
    ProgramLOB,
    ProgramSortParams,
    TaskType,
    TemplateFilters, TemplateStatus
} from '../../../types';
import {useAddProgramMutation, useGetProgramListQuery} from "../../../services";
import {useAppDispatch} from "../../../store/hooks";
import {AlertSuccessModel, openAlert} from "../../../store/alertSlice";
import {CreateTaskModal} from "../../../components/Modals/CreateTaskModal";
import {toPythonISO} from "../../../utils";
import {Routes} from "../../../consts";

import {ProgramLobFilterBar} from "../../../components/ProgramsFilterBars";
import {TaskTypeFilterBar} from "../../../components/TasksFilterBars";
import {TemplateStatusFilterBar} from "../../../components/TemplateFilterBars";

export const TemplatesPage: FC = () => {
    const dispatch = useAppDispatch();
    const { userIsManager, userIsAdmin } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [showArchivedTemplates, setShowArchivedTemplates] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [templateFilters, setTemplateFilters] = useState<TemplateFilters>(
        {
            taskTypes: [],
            programLobs: [],
            taskInfoGroups: [],
            statuses: [],
        }
    );

    const handleSetFilters = (filters: TemplateFilters) => {
        setTemplateFilters(filters);
        setPage(1);
    }

    // TODO: Think better logic
    // Debounce search value
    const debouncedSearchValueSetter = useMemo(() => {
        return debounce(setDebouncedSearchValue, 500);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        debouncedSearchValueSetter(searchValue);
        // eslint-disable-next-line
    }, [searchValue]);

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{ display: 'flex', flexDirection: 'column', margin: '32px auto' }}
            >
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <OutlinedInput
                        placeholder={'Search for a Template'}
                        value={searchValue}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setSearchValue(event.target.value)
                        }}
                        endAdornment={(
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        )}
                    />

                    {
                        (userIsAdmin || userIsManager) && (
                            <FormControlLabel
                                control={<Checkbox
                                    checked={showArchivedTemplates}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setShowArchivedTemplates(event.target.checked)
                                    }}
                                />}
                                label="Show archived"
                            />
                        )
                    }

                    {/* Add new Template trigger */}
                    {
                        (userIsAdmin || userIsManager) && (
                            <Box ml="auto">
                                <ButtonGroup variant="outlined" onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}>
                                    <Button size="large" variant="outlined" startIcon={<AddIcon fontSize="small" />}>
                                        CREATE NEW
                                    </Button>
                                    <Button variant="outlined" size="small">
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    <MenuItem onClick={() => {
                                        setAnchorEl(null);
                                    }}>
                                        Approval Template
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setAnchorEl(null);
                                    }}>
                                        Deliverable Template
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setAnchorEl(null);
                                    }}>
                                        Information Request Template
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )
                    }
                </Box>

                {/* Filters */}
                <Box sx={{
                    display: "flex",
                    gap: 3,
                    borderBottom: '2px solid',
                    borderColor: 'divider',
                    paddingY: 4,
                }}>
                    {/*Task Type Filter*/}
                    <TaskTypeFilterBar
                        selectedValues={templateFilters.taskTypes}
                        onApply={(selectedItems) => {
                            setTemplateFilters((prev) => ({
                                ...prev,
                                taskTypes: selectedItems.map(item => item.value as TaskType)
                            }))
                        }}
                        onReset={() => setTemplateFilters({...templateFilters, taskTypes: []})}
                    />
                    {/*Program Lob Filter*/}
                    <ProgramLobFilterBar
                        selectedValues={templateFilters.programLobs}
                        onApply={(selectedItems) => {
                            setTemplateFilters((prev) => ({
                                ...prev,
                                programLobs: selectedItems.map(item => item.value as ProgramLOB)
                            }))
                        }}
                        onReset={() => setTemplateFilters({...templateFilters, programLobs: []})}
                    />

                    {/*Type Filter*/}
                    {/*<ProgramTypeFilterBar filters={programFilters} setFilters={handleSetFilters} />*/}

                    {/* Template Status Filter*/}
                    <TemplateStatusFilterBar
                        selectedValues={templateFilters.statuses}
                        onApply={(selectedItems) => {
                            setTemplateFilters((prev) => ({
                                ...prev,
                                statuses: selectedItems.map(item => item.value as TemplateStatus)
                            }))
                        }}
                        onReset={() => setTemplateFilters({...templateFilters, statuses: []})}
                    />
                </Box>

                {/* Templates Table */}
                <Container disableGutters sx={{position: 'relative'}}>
                    {
                        5 > 9
                            ?
                            <TableLoader/>
                            :
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                            ?
                            <TemplatesTable data={[]}/>
                            :
                            <TableEmptyList />
                    }
                </Container>

                {/* Pagination */}
                {/*{*/}
                {/*    (programsData && programsData.pages > 1) && (*/}
                {/*        <Box sx={{display: 'flex', justifyContent: 'center'}} mt={3}>*/}
                {/*            <Pagination*/}
                {/*                count={programsData?.pages || 1}*/}
                {/*                page={page}*/}
                {/*                onChange={(e: ChangeEvent<unknown>, value: number) => {*/}
                {/*                    setPage(value)*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </Box>*/}
                {/*    )*/}
                {/*}*/}
            </Container>
        </MainLayout>
    );
};


