import {
    Task,
    // taskStatusList
} from "../types";

export const sortTasksByStatus = (list: Task[]): Task[] => {
    return list

    // TODO: make sorting when statuses will be from enum
    // if(list.length > 0) {
    //     return [...list].sort((a, b) => taskStatusList[a.status].key - taskStatusList[b.status].key);
    // } else {
    //     return list;
    // }
};