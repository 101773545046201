import React from "react";
import {Container} from "@mui/material";
import {styled} from "@mui/material/styles";

import {
    Header
} from "../components/UI";

interface AuthLayoutProps {
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({children}) => {
    return (
        <AuthWrapper maxWidth={false} disableGutters>
            <Header />
            <AuthContainer maxWidth="xl" disableGutters>
                {children}
            </AuthContainer>
        </AuthWrapper>
    );
};

const AuthWrapper = styled(Container)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
}));

const AuthContainer = styled(Container)(({theme}) => ({
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flex: 1,
}));


