import { datadogLogs, Logger } from '@datadog/browser-logs'

export let apiLogger: Logger;
export let componentLogger: Logger;

export function init() {
    datadogLogs.init({
        clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN || '',
        forwardErrorsToLogs: true,
        sampleRate: 10,
      });

      const globalContext = {
          'app': 'weave',
          'env': process.env.NODE_ENV,
          'okta_issuer': process.env.REACT_APP_OKTA_ISSUER
      }

      datadogLogs.setLoggerGlobalContext(globalContext)

      apiLogger = datadogLogs.createLogger('api');
      componentLogger = datadogLogs.createLogger('component')
}