import React from "react";
import {Container} from "@mui/material";
import {PulseLoader} from "react-spinners";

export const TableLoader: React.FC = () => {
    return (
        <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '50vh'}}>
            <PulseLoader size={12} color={'#012c77'} />
        </Container>
    );
};