import React, { FC, useMemo } from 'react';

import {TaskFilters} from "../../types";
import {useGetUserListQuery} from "../../services";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskOwnerFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const TaskOwnerFilterBar: FC<TaskOwnerFilterBarProps> = ({
    filters,
    setFilters,
}) => {
    const {data: userList} = useGetUserListQuery({});

    const ownerFilterOptions = useMemo<IOption[]>(() => {
        if (userList?.data) {
            return userList?.data?.map(user => ({
                label: user.name,
                value: user.id,
            }))
        }
        return [];
    }, [userList]);

    return (
        <MultiSelectPopover
            togglerTitle={'UPLOADED BY'}
            selectedValues={filters.ownerIds || []}
            options={ownerFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, ownerIds: selectedItems.map(item => +item.value)})
            }}
            onReset={() => setFilters({...filters, ownerIds: []})}
            searchPlaceholder={`Search for a Uploaded By`}
        />
    );
};