import { createApi } from '@reduxjs/toolkit/query/react'

import { Paginated, Activity, CreateActivityInput} from "../types";
import {Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";

export const activityApi = createApi({
    reducerPath: 'activityApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Activity'],
    endpoints: (builder) => ({
        getActivityList: builder.query<Paginated<Activity>, any>({
            query: ({task_id, ...params}) => ({
                url: Endpoints.Activity.List(task_id),
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'Activity')
        }),
        getActivityById: builder.query<Activity, any>({
            query: (id) => ({ url: Endpoints.Activity.Details(id) }),
            providesTags: (result, error, id) => [{ type: 'Activity', id }],
        }),
        addActivity: builder.mutation<Activity, CreateActivityInput>({
            query: (body) => ({
                url: Endpoints.Activity.Create,
                method: 'POST',
                body: body
            }),
            invalidatesTags: [{ type: 'Activity', id: 'LIST' }],
        }),
        uploadFileToActivity: builder.mutation<Activity, any>({
            query: (body) => ({
                url: Endpoints.Activity.UploadFile(body.activityId),
                method: 'POST',
                body: body.file
            }),
            invalidatesTags: [{ type: 'Activity', id: 'LIST' }],
        }),
        updateActivity: builder.mutation<Activity, Partial<Activity> & Pick<Activity, 'id'>>({
            query: (body) => ({
                url: Endpoints.Activity.Update(body.id),
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Activity', id: arg.id }],
        }),
        deleteActivityById: builder.mutation<any, any>({
            query: (id) => ({
                url: Endpoints.Activity.Delete(id),
                method: 'DELETE',
            }),

            // Manual Cache update
            async onQueryStarted(id, { dispatch, queryFulfilled, ...api }) {
                const queryCacheKeys = api.getState().activityApi.provided.Activity[id];
                try {
                    const { data } = await queryFulfilled;
                    for (let key of queryCacheKeys) {
                        dispatch(
                            activityApi.util.updateQueryData(
                                'getActivityList',
                                api.getState().activityApi.queries[key]?.originalArgs,
                                (draft) => {
                                    const activityIndex = draft.data.findIndex(ac => ac.id === id);
                                    if (activityIndex > -1) {
                                        draft.data.splice(activityIndex, 1);
                                    }
                                }
                            )
                        )
                    }
                } catch {}
            },
        }),
        likeActivity: builder.mutation<Activity, any>({
            query: (id) => ({
                url: Endpoints.Activity.Like(id),
                method: 'POST',
            }),

            // Manual Cache update
            async onQueryStarted(id, { dispatch, queryFulfilled, ...api }) {
                // Find getActivityList cache keys
                const queryCacheKeys = api.getState().activityApi.provided.Activity[id];
                try {
                    const { data: likedActivity } = await queryFulfilled;
                    for (let key of queryCacheKeys) {
                        dispatch(
                            activityApi.util.updateQueryData(
                                'getActivityList',
                                api.getState().activityApi.queries[key]?.originalArgs,
                                (draft) => {
                                    const activityIndex = draft.data.findIndex(ac => ac.id === likedActivity.id);
                                    if (activityIndex > -1) {
                                        draft.data[activityIndex] = likedActivity;
                                    }
                                }
                            )
                        )
                    }
                } catch {}
            },
        }),
        UnLikeActivity: builder.mutation<Activity, any>({
            query: (id) => ({
                url: Endpoints.Activity.UnLike(id),
                method: 'POST',
            }),

            // Manual Cache update
            async onQueryStarted(id, { dispatch, queryFulfilled, ...api }) {
                // Find getActivityList cache keys
                const queryCacheKeys = api.getState().activityApi.provided.Activity[id];
                try {
                    const { data: likedActivity } = await queryFulfilled;
                    for (let key of queryCacheKeys) {
                        dispatch(
                            activityApi.util.updateQueryData(
                                'getActivityList',
                                api.getState().activityApi.queries[key]?.originalArgs,
                    (draft) => {
                                    const activityIndex = draft.data.findIndex(ac => ac.id === likedActivity.id);
                                    if (activityIndex > -1) {
                                        draft.data[activityIndex] = likedActivity;
                                    }
                                }
                            )
                        )
                    }
                } catch {}
            },
        }),
    }),
});

export const {
    useGetActivityListQuery,
    useGetActivityByIdQuery,
    useAddActivityMutation,
    useUpdateActivityMutation,
    useDeleteActivityByIdMutation,
    useLikeActivityMutation,
    useUnLikeActivityMutation
} = activityApi