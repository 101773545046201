import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Program} from "../../types";

interface ArchiveProgramModalProps {
    open: boolean;
    onClose: () => void;
    program: Program;
    onArchiveProgram: (program: Program) => void;
    loading: boolean;
}
export const ArchiveProgramModal: FC<ArchiveProgramModalProps> = ({
  open,
  onClose,
  program,
  onArchiveProgram,
  loading
}) => {
    return (
        <Dialog open={open} onClose={onClose} scroll="paper" maxWidth={"sm"} fullWidth>
            <DialogTitle color={'text.primary'}>
                {`${program.is_active ? 'Archive' : 'Unarchive'} this placement`}
            </DialogTitle>
            <DialogContent>
                <Typography paddingY={3} textAlign="center">
                    {
                        program.is_active ?
                            'Are you sure? If you archive this placement, clients will no longer have access.'
                            :
                            'Are you sure? If you unarchive this placement, clients will have access.'
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>CANCEL</Button>
                <LoadingButton
                    onClick={() => onArchiveProgram({...program, is_active: !program.is_active})}
                    type="submit"
                    loading={loading}
                >
                    CONFIRM
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}