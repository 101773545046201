import { theme } from '@gcui/react';
import {Program} from "./Program";
import {User} from "./User";

export type TaskOrderBy = 'id' | 'name' | 'type' | 'updated_at' | 'target_date' | 'assignee_id' | 'status' | 'phase' | 'owner_id' | 'deliverable_type';

export enum TaskType {
    Request = 'request',
    Approval = 'approval',
    Deliverable = 'deliverable',
}

export enum TaskStatus {
    Open = 'open',
    Pending = 'pending_review',
    Completed = 'completed',
}

export enum TaskPhase {
    ModelingAndAnalytics = 'modelingAndAnalytics',
    Strategy = 'strategy',
    Contracts = 'contracts',
    Submission = 'submission',
    Placement = 'placement',
}

export enum DeliverableType {
    Presentations = 'presentations',
    AdditionInfo = 'addition_info',
    Contracts = 'contracts',
    Market_List = 'market_list',
}

export enum InformationGroup {
    CatModeling = 'catModeling',
    ClaimNarratives = 'claimNarratives',
    Contract = 'contract',
    COVID19Questions = 'COVID19Questions',
    Exposure = 'exposure',
    ExposureListingAllAccounts = 'exposureListingAllAccounts',
    ExposureListingCoSurety = 'exposureListingCoSurety',
    ExposureListingCommercialSurety = 'exposureListingCommercialSurety',
    ExposureListingContractSurety = 'exposureListingContractSurety',
    Loss = 'loss',
    LossListing = 'lossListing',
    LossTriangles = 'lossTriangles',
    Other = 'other',
    Premium = 'premium',
    RateChange = 'rateChange'
}

export interface Task {
    id: number;
    created_at?: Date;
    updated_at?: Date;
    name: string;
    description: string;
    is_active: boolean;
    phase: TaskPhase;
    status: TaskStatus;
    target_date: Date;
    type: TaskType;
    owner: User;
    program_id: number;
    program: Program;
    assignee_id: number;
    assignee: User;
    deliverable_type: DeliverableType;
}

export const taskTypeList: { [key in TaskType]: any } = {
    request: {
        label: 'Information Request',
        value: 'request',
        key: 1,
        color: theme.palette.error.main
    },
    approval: {
        label: 'Approval',
        value: 'approval',
        key: 2,
        color: theme.palette.warning.light
    },
    deliverable: {
        label: 'Deliverable',
        value: 'deliverable',
        key: 3,
        color: theme.palette.success.main
    },
};

export const taskPhaseList: { [key in TaskPhase]: any } = {
    modelingAndAnalytics: {
        label: 'Modeling & Analytics',
        value: 'modelingAndAnalytics',
        key: 1,
        color: theme.palette.text.primary
    },
    strategy: {
        label: 'Strategy',
        value: 'strategy',
        key: 2,
        color: theme.palette.text.primary
    },
    contracts: {
        label: 'Contracts',
        value: 'contracts',
        key: 3,
        color: theme.palette.text.primary
    },
    submission: {
        label: 'Submission',
        value: 'submission',
        key: 4,
        color: theme.palette.text.primary
    },
    placement: {
        label: 'Placement',
        value: 'placement',
        key: 5,
        color: theme.palette.text.primary
    },
};

export const taskStatusList: { [key in TaskStatus]: any } = {
    open: {
        label: 'Open',
        value: 'open',
        key: 1,
        color: theme.palette.success.main
    },
    pending_review: {
        label: 'Pending Review',
        value: 'pending_review',
        key: 2,
        color: theme.palette.success.main
    },
    completed: {
        label: 'Completed',
        value: 'completed',
        key: 3,
        color: theme.palette.text.secondary
    }
};

export const deliverableTypeList: { [key in DeliverableType]: any } = {
    presentations: {
        label: 'Presentations',
        value: 'presentations',
        key: 1,
        color: theme.palette.success.main
    },
    addition_info: {
        label: 'Final Submission & Supplemental Information',
        value: 'addition_info',
        key: 2,
        color: theme.palette.success.main
    },
    contracts: {
        label: 'Contracts and I&Ls',
        value: 'contracts',
        key: 3,
        color: theme.palette.text.secondary
    },
    market_list: {
        label: 'Market list',
        value: 'market_list',
        key: 4,
        color: theme.palette.text.secondary
    }
};

export const informationGroupList: { [key in InformationGroup]: any } = {
    catModeling: {
        label: 'Cat Modeling',
        value: 'catModeling',
        key: 1,
        color: theme.palette.success.main
    },
    claimNarratives: {
        label: 'Claim Narratives',
        value: 'claimNarratives',
        key: 2,
        color: theme.palette.success.main
    },
    contract: {
        label: 'Contract',
        value: 'contract',
        key: 3,
        color: theme.palette.success.main
    },
    COVID19Questions: {
        label: 'COVID 19 Questions',
        value: 'COVID19Questions',
        key: 4,
        color: theme.palette.success.main
    },
    exposure: {
        label: 'Exposure',
        value: 'exposure',
        key: 5,
        color: theme.palette.success.main
    },
    exposureListingAllAccounts: {
        label: 'Exposure Listing (All Accounts)',
        value: 'exposureListingAllAccounts',
        key: 6,
        color: theme.palette.success.main
    },
    exposureListingCoSurety: {
        label: 'Exposure Listing (Co-Surety)',
        value: 'exposureListingCoSurety',
        key: 7,
        color: theme.palette.success.main
    },
    exposureListingCommercialSurety: {
        label: 'Exposure Listing (Commercial Surety)',
        value: 'exposureListingCommercialSurety',
        key: 8,
        color: theme.palette.success.main
    },
    exposureListingContractSurety: {
        label: 'Exposure Listing (Contract Surety)',
        value: 'exposureListingContractSurety',
        key: 9,
        color: theme.palette.success.main
    },
    loss: {
        label: 'Loss',
        value: 'loss',
        key: 10,
        color: theme.palette.success.main
    },
    lossListing: {
        label: 'Loss Listing',
        value: 'lossListing',
        key: 11,
        color: theme.palette.success.main
    },
    lossTriangles: {
        label: 'Loss Triangles',
        value: 'lossTriangles',
        key: 12,
        color: theme.palette.success.main
    },
    other: {
        label: 'Other',
        value: 'other',
        key: 13,
        color: theme.palette.success.main
    },
    premium: {
        label: 'Premium',
        value: 'premium',
        key: 14,
        color: theme.palette.success.main
    },
    rateChange: {
        label: 'Rate Change',
        value: 'rateChange',
        key: 15,
        color: theme.palette.success.main
    },
};