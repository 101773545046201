import React, {useMemo} from 'react';

import {
    ProgramFilters,
    ProgramType,
    programTypeList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface ProgramTypeFilterBarProps {
    filters: ProgramFilters;
    setFilters: (filters: ProgramFilters) => void;
}

export const ProgramTypeFilterBar: React.FC<ProgramTypeFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const typesFilterOptions = useMemo<IOption[]>(() => {
        const typesList = Object.keys(programTypeList) as ProgramType[];
        if (typesList.length) {
            return typesList.map(type => ({
                label: programTypeList[type]?.label,
                value: programTypeList[type]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Type"}
            selectedValues={filters.types}
            options={typesFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, types: selectedItems.map(item => item.value as ProgramType)})
            }}
            onReset={() => setFilters({...filters, types: []})}
            searchPlaceholder={'Search for a Type'}
        />
    );
};