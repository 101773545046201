
import {theme} from "@gcui/react";

// TODO: ensure enum is correct
export enum TemplateStatus {
    Archived = 'archived',
    Draft = 'draft',
    Published = 'published'
}

export interface Template {
    [key: string]: any
}

export const templateStatusList: { [key in TemplateStatus]: any }  = {
    archived: {
        label: 'Archived',
        value: 'archived',
        key: 1,
        color: theme.palette.text.secondary
    },
    draft: {
        label: 'Draft',
        value: 'draft',
        key: 2,
        color: theme.palette.error.main
    },
    published: {
        label: 'Published',
        value: 'published',
        key: 3,
        color: theme.palette.text.primary
    },
};