import React, {FC, useState, useMemo, MouseEvent} from "react";
import {NavLink, useLocation} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import {styled} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import BallotIcon from '@mui/icons-material/Ballot'
// import AddAlertIcon from '@mui/icons-material/AddAlert';
import {
    AppBar,
    Box,
    Container,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Avatar,
    Badge,
    Tab,
    Tabs
} from '@mui/material';

import {Endpoints, Routes} from "../../../../consts";
import {useAuthContext} from "../../../../contexts/AuthContext";
import {Images, MainLogoIcon} from "../../../../environment";
import {useNavigation} from "../../../../hooks";

export const MainHeader: FC = () => {
    const {navigate} = useNavigation();
    const location = useLocation();
    const {user, userIsAdmin, userIsManager, logOut, getUserLoading} = useAuthContext();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleNavigate = (route: string) => {
        navigate(route);
    };

    const activeTabValue = useMemo(() => {
        switch (location.pathname) {
            case Routes.home:
                return Routes.home;
            case Routes.templates:
                return Routes.templates;
            case Routes.placements:
                return Routes.placements;
            default:
                return false
        }
    }, [location.pathname]);

    const tabsData = useMemo(() => {
        if (!getUserLoading) {
            return [
                {
                    order: 1,
                    label: 'Home',
                    to: Routes.home,
                    value: Routes.home,
                    visible: true,
                },
                {
                    order: 2,
                    label: 'Placements',
                    to: Routes.placements,
                    value: Routes.placements,
                    visible: true,
                },
                {
                    order: 3,
                    label: 'Templates',
                    to: Routes.templates,
                    value: Routes.templates,
                    visible: userIsAdmin || userIsManager,
                }
            ]
        }

        return []
    }, [getUserLoading, userIsAdmin, userIsManager]);

    return (
        <AppBar
            position="static"
            sx={{backgroundColor: (userIsAdmin || userIsManager) ? '#000' : 'primary'}}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{minHeight: {md: '92px'}}}>
                    <Box sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}>
                        <a href={Routes.home}>
                            <MainLogoIcon/>
                        </a>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                            keepMounted
                            transformOrigin={{vertical: 'top', horizontal: 'left',}}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{display: {xs: 'block', md: 'none'}}}
                        >
                            <MenuItem key={'home'} onClick={() => handleNavigate(Routes.home)}>
                                <Typography textAlign="center">
                                    Home
                                </Typography>
                            </MenuItem>
                            <MenuItem key={'placements'} onClick={() => handleNavigate(Routes.placements)}>
                                <Typography textAlign="center">
                                    Placements
                                </Typography>
                            </MenuItem>
                            {/* TODO: use from tab items*/}
                            {
                                (userIsAdmin || userIsManager) && (
                                    <MenuItem key={'templates'} onClick={() => handleNavigate(Routes.templates)}>
                                        <Typography textAlign="center">
                                            Templates
                                        </Typography>
                                    </MenuItem>
                                )
                            }
                            <MenuItem key={'tasks'} onClick={() => handleNavigate(Routes.tasks)}>
                                <Typography textAlign="center">
                                    Tasks
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* Desktop */}
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <a href={'/'}>
                            <MainLogoIcon/>
                        </a>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {
                            (user && !getUserLoading) && (
                                <Tabs
                                    value={activeTabValue}
                                    onChange={(e, value) => handleNavigate(value)}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                >
                                    {
                                        tabsData.map(tabItem => {
                                            if (!tabItem.visible) return null;

                                            return (
                                                <Tab
                                                    key={`item-${tabItem.to}`}
                                                    component={NavLink}
                                                    label={tabItem.label}
                                                    value={tabItem.value}
                                                    to={tabItem.to}
                                                    onClick={(event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement>) => {
                                                        event.preventDefault();
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </Tabs>
                            )
                        }
                    </Box>

                    <Box sx={{display: {xs: 'none', md: 'flex'}, marginRight: 2}}>
                        <IconButton
                            size="large"
                            aria-label="show 4 new mails"
                            color="inherit"
                            onClick={() => navigate(Routes.tasks)}
                        >
                            <Badge badgeContent={3} color="secondary">
                                <BallotIcon/>
                            </Badge>
                        </IconButton>
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    aria-label="show 17 new notifications"*/}
                        {/*    color="inherit"*/}
                        {/*    onClick={() => navigate(Routes.notifications)}*/}
                        {/*>*/}
                        {/*    <Badge badgeContent={1} color="secondary">*/}
                        {/*        <AddAlertIcon/>*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                    </Box>

                    {
                        user
                            ?
                            (
                                <Box sx={{flexGrow: 0}}>
                                    <Tooltip title={user.name || ''}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                            <Avatar sx={{width: {xs: 40, md: 48}, height: {xs: 40, md: 48}}}>
                                                {user ? user.name.slice(0, 1) : ''}
                                            </Avatar>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{mt: '45px'}}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <ProfileMenuItem key={'account'} onClick={logOut}>
                                            Log out
                                        </ProfileMenuItem>
                                    </Menu>
                                </Box>
                            )
                            :
                            <Skeleton
                                variant="circular"
                                sx={{bgcolor: 'grey.900', width: {xs: 40, md: 48}, height: {xs: 40, md: 48}}}
                            />
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const ProfileMenuItem = styled(MenuItem)(({theme}) => ({
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
}))