import { createApi } from '@reduxjs/toolkit/query/react'

import { Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";
import {Client, Paginated} from "../types";

export const clientApi = createApi({
    reducerPath: 'clientApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Client'],
    endpoints: (builder) => ({
        getClientList: builder.query<Paginated<Client>, any>({
            query: (params) => ({
                url: Endpoints.Client.List,
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'Client')
        }),
        getClientById: builder.query<Client, any>({
            query: (id) => ({ url: Endpoints.Client.Details(id) }),
            providesTags: (result, error, id) => [{ type: 'Client', id }],
        }),
        addClient: builder.mutation<Client, Partial<Client>>({
            query: (body) => ({
                url: Endpoints.Client.Create,
                method: 'POST',
                body: body
            }),
            invalidatesTags: [{ type: 'Client', id: 'LIST' }],
        }),
    }),
});

export const { useGetClientListQuery, useGetClientByIdQuery, useLazyGetClientByIdQuery, useAddClientMutation } = clientApi