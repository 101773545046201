import React, {FC} from "react";
import {IconButton} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {styled} from "@mui/material/styles";

import {ProgramSortParams, TaskSortParams} from "../../../types";

interface TableSortButtonProps {
    onClick: () => void;
    active?: boolean;
    value: string;
    orderBy: TaskSortParams | ProgramSortParams
}

export const TableSortButton: FC<TableSortButtonProps> = ({
  onClick,
  active = false,
  value,
  orderBy
}) => {
    return (
        <IconButton onClick={onClick} size="small" sx={{marginLeft: 1}}>
            {
                orderBy.value === value
                    ?
                    orderBy.direction === 'asc' ?
                        <ArrowUpwardIcon fontSize="small"/>
                        :
                        <ArrowDownwardIcon fontSize="small"/>
                    :
                    <HorizontalRuleIconHidden fontSize="small" />
            }
        </IconButton>
    );
};


const HorizontalRuleIconHidden = styled(HorizontalRuleIcon)`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;