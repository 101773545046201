import { createApi } from '@reduxjs/toolkit/query/react'

import {CreateTaskInput, Paginated, Task} from "../types";
import {Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";

export const taskApi = createApi({
    reducerPath: 'taskApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Task'],
    endpoints: (builder) => ({
        getTaskList: builder.query<Paginated<Task>, any>({
            query: ({programId, type, ...params}) => ({
                url: Endpoints.Task.List(programId, type),
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'Task')
        }),
        getMyTaskList: builder.query<Paginated<Task>, any>({
            query: (params) => ({
                url: Endpoints.Task.MyList,
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'Task')
        }),
        getTaskById: builder.query<Task, number>({
            query: (id) => ({ url: Endpoints.Task.Details(id) }),
            providesTags: (result, error, id) => [{ type: 'Task', id }],
        }),
        addTask: builder.mutation<Task, CreateTaskInput>({
            query: (body) => ({
                url: Endpoints.Task.Create,
                method: 'POST',
                body: body
            }),
            invalidatesTags: [{ type: 'Task', id: 'LIST' }]
        }),
        updateTask: builder.mutation<Task, Partial<Task> & Pick<Task, 'id'>>({
            query: (body) => ({
                url: Endpoints.Task.Update(body.id),
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Task', id: arg.id }],
        })
    }),
});

export const {
    useLazyGetTaskListQuery,
    useGetMyTaskListQuery,
    useGetTaskByIdQuery,
    useAddTaskMutation,
    useUpdateTaskMutation
} = taskApi