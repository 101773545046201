import store2 from "store2";
import {fetchBaseQuery, retry} from "@reduxjs/toolkit/dist/query/react";
import {BASE_URL, StorageKey} from "../consts";

export function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T
) {
    return resultsWithIds
        ? [
            { type: tagType, id: 'LIST' },
            ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}

export const staggeredBaseQuery = retry(fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const oktaStorage = store2.get(StorageKey.OktaStorage);
            const oktaToken = oktaStorage?.accessToken.accessToken || '';

            headers.set('Authorization', `Bearer ${oktaToken}`);
            return headers;
        },
    }),
    { maxRetries: 0}
)