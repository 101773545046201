import React, {FC, Fragment, useMemo} from "react";
import {NavLink} from "react-router-dom";
import {
    Avatar,
    Card,
    CardContent,
    CardHeader, Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {Routes} from "../../consts";
import {DashboardData, TaskType} from "../../types";
import Skeleton from "@mui/material/Skeleton";

interface TasksInfoCardProps {
    data: DashboardData | undefined;
    loading: boolean;
}

export const TasksInfoCard: FC<TasksInfoCardProps> = ({data, loading}) => {
   const items = useMemo(() => [
        {
            title: 'Requests',
            subTitle: 'Items outstanding',
            totalCount: data?.assignee_task_count_requests || 0,
            route: Routes.tasks,
            queryParam: TaskType.Request
        },
        {
            title: 'Approvals',
            subTitle: 'Approval needs your attention',
            totalCount: data?.assignee_task_count_approvals || 0,
            route: Routes.tasks,
            queryParam: TaskType.Approval
        },
        {
            title: 'Deliverables',
            subTitle: 'New files were posted for your use',
            totalCount: data?.assignee_task_count_deliverables || 0,
            route: Routes.tasks,
            queryParam: TaskType.Deliverable
        },
    ], [data]);


    return (
        <Card sx={{width: '100%', bgcolor: 'grey.50'}}>
            <CardHeader
                title="Task Status"
                action={
                    <IconButton component={NavLink} to={Routes.tasks}  aria-label="settings" color="primary">
                        <ArrowForwardIcon />
                    </IconButton>
                }
            />

            <CardContent>
                <List sx={{bgcolor: 'white'}} disablePadding>
                    {
                        items.map((item, i) => (
                            <Fragment key={`item-${i}`}>
                                <ListItem alignItems="flex-start" key={`item-${i}`}>
                                    <ListItemAvatar>
                                        {
                                            loading
                                            ? <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                            : (
                                                    <Avatar
                                                        sx={{
                                                            backgroundColor: 'grey.50',
                                                            color: 'primary.main',
                                                            fontWeight: 700,
                                                            width: 40,
                                                            height: 40
                                                        }}
                                                    >
                                                        {item.totalCount}
                                                    </Avatar>
                                            )
                                        }

                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={(
                                            <StyledNavLink to={{pathname: item.route, search: `filterType=${item.queryParam}`}}>
                                                <Typography variant="h6" mb={1}>{item.title}</Typography>
                                            </StyledNavLink>
                                        )}
                                        secondary={
                                                loading
                                                ? <Skeleton height={24} />
                                                : (
                                                    <Typography variant="body1" color="text.secondary">
                                                        {`${item.totalCount} ${item.subTitle}`}
                                                    </Typography>
                                                )
                                        }
                                    />
                                </ListItem>

                                <Divider />
                            </Fragment>
                        ))
                    }
                </List>
            </CardContent>
        </Card>
    )
}

const StyledNavLink = styled(NavLink)(() => ({
    textDecoration: 'none'
}))