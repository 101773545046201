import {TaskType} from "../types";
import {Routes} from "../consts";

type Output = {
    label: string;
    to: string
}

export const getTaskDetailsCorrectBreadcrumb = (taskType: TaskType, programId: number): Output => {
    if (taskType === TaskType.Request) {
        return {
            label: 'Requests',
            to: Routes.placementRequests(programId)
        }
    }

    if (taskType === TaskType.Approval) {
        return {
            label: 'Approvals',
            to: Routes.placementApprovals(programId)
        }
    }

    return {
        label: 'Deliverables',
        to: Routes.placementDeliverables(programId)
    }
};