import React, {useState} from 'react';
import {Grid, Popover} from "@mui/material";
import {Button, Box} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

import {ActivitiesFilters} from "../../types";
import DateRangePicker, {IDatePickerDates} from "../UI/DateRangePicker";

interface ActivityDateFilterBarProps {
    filters: ActivitiesFilters;
    setFilters: (filters: ActivitiesFilters) => void;
}

export const ActivityDateFilterBar: React.FC<ActivityDateFilterBarProps> = ({
    filters,
    setFilters,
}) => {
    const [dateRange, setDateRange] = useState<IDatePickerDates | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        setFilters({...filters, date: { from: dateRange?.startDate?.toDate() || null, to: dateRange?.endDate?.toDate() || null } });
        handleClose();
    };

    const handleClear = () => {
        setFilters({...filters, date: { from: null, to: null } });
        setDateRange(null);
        handleClose();
    }

    return (
        <Box>
            <Button
                variant="contained"
                size="small"
                type="button"
                onClick={handleShow}
                color="inherit"
                endIcon={<ExpandMoreIcon/>}
            >
                {`Date ${(filters.date.from || filters.date.to) ? `(${
                    filters.date.from ? moment(filters.date.from).format('MM/DD/YY') : ''
                } - ${
                    filters.date.to ? moment(filters.date.to).format('MM/DD/YY') : ''
                })` : ''}`}
            </Button>

            <Popover
                sx={{marginTop: 2}}
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={0} p={0} minWidth={320} maxWidth="min-content" overflow="hidden">
                    <Grid item xs={12}>
                        <DateRangePicker
                            startDate={dateRange?.startDate || null}
                            endDate={dateRange?.endDate || null}
                            onDatesChange={(arg) => setDateRange(arg)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1, p: 1}}>
                            <Button color="secondary" onClick={handleClear} sx={{marginRight: 'auto'}}>Clear</Button>
                            <Button color="secondary" onClick={handleClose}>Cancel</Button>
                            <Button color="secondary" onClick={handleApply}>OK</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
        </Box>
    );
};