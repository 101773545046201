import React from "react";
import {Chip} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {styled} from "@mui/material/styles";


export const ApprovalApprovedChip = () => (
    <StyledSuccessChip variant="outlined" color="success" label="Approved" icon={<CheckCircleOutlineIcon />} />
)

export const ApprovalDeclinedChip = () => (
    <StyledErrorChip variant="outlined" color="error" label="Declined" icon={<DoNotDisturbIcon />} />
)

const StyledSuccessChip = styled(Chip)(({theme}) => ({
    fontWeight: 700,
    borderRadius: 4,
    height: 40,
    // TODO: use bg color from theme
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #14853D'
}))

const StyledErrorChip = styled(Chip)(({theme}) => ({
    fontWeight: 700,
    borderRadius: 4,
    height: 40,
    // TODO: use bg color from theme
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C53532'
}))