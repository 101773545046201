import {ReactComponent as MessageIcon} from './messageIcon.svg';
import {ReactComponent as LockIcon} from './lockIcon.svg';
import {ReactComponent as HelpIcon} from './helpIcon.svg';
import {ReactComponent as NotificationIcon} from './notificationIcon.svg';
import {ReactComponent as MainLogoIcon} from './mainLogoIcon.svg';
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as FilterIcon} from './filterIcon.svg';
import {ReactComponent as SearchIcon} from './searchIcon.svg';
import {ReactComponent as ArrowDown} from './arrowDown.svg';
import {ReactComponent as ArrowUp} from './arrowUp.svg';
import {ReactComponent as GoBackIcon} from './goBackIcon.svg';
import {ReactComponent as DownloadIcon} from './downloadIcon.svg';
import {ReactComponent as RoundBlueIcon} from './roundBlueIcon.svg';
import {ReactComponent as TasksIcon} from './tasksIcon.svg';
import {ReactComponent as BigArrowDown} from './bigArrowDown.svg';
import {ReactComponent as HelpIconBlue} from './helpIconBlue.svg';
import {ReactComponent as DoneIcon} from './doneIcon.svg';
import {ReactComponent as CloseIcon} from './close-icon.svg';
import {ReactComponent as ArrowRightIcon} from './arrowRightIcon.svg';
import {ReactComponent as ArrowLeftIcon} from './arrowLeftIcon.svg';
import {ReactComponent as PdfIcon} from './pdfIcon.svg';
import {ReactComponent as CalendarIcon} from './calendarIcon.svg';
import {ReactComponent as TimeLineIcon} from './timeLineIcon.svg';
import {ReactComponent as ArchiveIcon} from "./archiveIcon.svg";
import {ReactComponent as UnarchiveIcon} from "./unArchiveIcon.svg";

import {ReactComponent as GCTimesIcon} from './gc_times.svg';
import {ReactComponent as GCTimesRoundedIcon} from './gc_times_rounded.svg';
import {ReactComponent as GCChevronDownIcon} from './gc_chevronDown.svg';
import {ReactComponent as GCChevronUpIcon} from './gc_chevronUp.svg';
import {ReactComponent as GCTrashIcon} from './gc_trash.svg';
import {ReactComponent as GCPenIcon} from './gc_pen.svg';
import {ReactComponent as GCCornerDownIcon} from './gc_cornerDown.svg';
import {ReactComponent as GCCornerUpIcon} from './gc_cornerUp.svg';
import {ReactComponent as GCCalendarSmallIcon} from './gc_calendar_small.svg';
import {ReactComponent as GCCheckIcon} from './gc_check_icon.svg';



export { 
    MessageIcon,
    LockIcon,
    HelpIcon,
    NotificationIcon,
    MainLogoIcon,
    Logo,
    FilterIcon,
    SearchIcon,
    ArrowDown,
    ArrowUp,
    DownloadIcon,
    GoBackIcon,
    RoundBlueIcon,
    TasksIcon,
    BigArrowDown,
    HelpIconBlue,
    DoneIcon,
    CloseIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    PdfIcon,
    CalendarIcon,
    TimeLineIcon,
    ArchiveIcon,
    UnarchiveIcon,

    GCTimesIcon,
    GCTimesRoundedIcon,
    GCChevronDownIcon,
    GCChevronUpIcon,
    GCTrashIcon,
    GCPenIcon,
    GCCornerDownIcon,
    GCCornerUpIcon,
    GCCalendarSmallIcon,
    GCCheckIcon
}