import React, {FC, useMemo} from 'react';

import {
    TaskFilters,
    TaskStatus,
    taskStatusList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskStatusFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const TaskStatusFilterBar: FC<TaskStatusFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const statusFilterOptions = useMemo<IOption[]>(() => {
        const statusList = Object.keys(taskStatusList) as TaskStatus[];
        if (statusList.length) {
            return statusList.map(status => ({
                label: taskStatusList[status]?.label,
                value: taskStatusList[status]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Status"}
            selectedValues={filters.statuses || []}
            options={statusFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, statuses: selectedItems.map(item => item.value as TaskStatus)})
            }}
            onReset={() => setFilters({...filters, statuses: []})}
            searchPlaceholder={'Search for a Status'}
        />
    );
};