import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Box, IconButton, Typography, TableCell, TableRow, Tooltip} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {Routes} from "../../../../consts";
import {Template} from "../../../../types";


interface RowItemProps {
    template: Template;
}

export const RowItem: FC<RowItemProps> = ({ template }) => {
    return (
        <TableRow sx={{borderBottom: '1px solid',borderColor: 'divider'}}>
            <TableCell>
                <Typography variant="body2">
                    Information Request
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    Premium
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    Property
                </Typography>
            </TableCell>
            <TableCell>
                <TableLink to={Routes.home}>
                    <Typography variant="body2" color="secondary">
                        Location and Policy Level Limits Profiles
                    </Typography>
                </TableLink>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    Subject Premium split by business unit, by line ...
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    08/02/2021
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2">
                    Published
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{display: 'flex', gap: 0.5}}>
                    <Tooltip title="Edit">
                        <IconButton size="small">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Archive">
                        <IconButton size="small">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    )
};

const TableLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'none',
}));