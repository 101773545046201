import React from "react";
import {Container} from "@mui/material";
import {styled} from "@mui/material/styles";
import { MainHeader } from "../components/UI";

interface MainLayoutProps {
    children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    return (
        <AppWrapper maxWidth={false} disableGutters>
            <MainHeader />
            <MainContainer maxWidth="xl">
                {children}
            </MainContainer>
        </AppWrapper>
    );
};

const AppWrapper = styled(Container)(() => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
}));

const MainContainer = styled(Container)(() => ({
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flex: 1,
}));
