import React, {FC, useState} from "react";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {Box, Collapse, Grid, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import ArchiveIcon from '@mui/icons-material/Archive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {Program, programLOBList, ProgramStatus, programStatusList, programTypeList} from "../../../../types";
import {Routes} from "../../../../consts";
import {getProgramStatusColor} from "../../../../utils";


interface RowItemProps {
    program: Program;
    showClientColumn: boolean;
}

export const RowItem: FC<RowItemProps> = ({
  program,
  showClientColumn,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <TableRow sx={{
                '& > *': { borderBottom: 'unset' },
                bgcolor: program.status === ProgramStatus.Complete ? 'grey.100' : ''
            }}>
                <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                        <TableLink to={Routes.placementRequests(program.id)}>
                            {program.name}
                        </TableLink>
                        {!program.is_active && <ArchiveIcon fontSize="small" color="disabled" />}
                    </Box>

                </TableCell>
                {
                    showClientColumn && (
                        <TableCell>{program?.client?.common_name || '-'}</TableCell>
                    )
                }
                <TableCell>{programTypeList[program.type]?.label}</TableCell>

                <TableCell>{programLOBList[program.class_of_business]?.label}</TableCell>

                <TableCell>{program.year ? program.year : '-'}</TableCell>

                <TableCell>{program.updated_at ? moment(program.updated_at).format('M/D/YYYY') : '-'}</TableCell>

                <TableCell>{program.target_date ? moment(program.target_date).format('MM/DD/YYYY') : ''}</TableCell>

                <TableCell sx={{color: getProgramStatusColor(program)}}>
                    {programStatusList[program.status]?.label}
                </TableCell>

                <TableCell>
                    <IconButton size="small" onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

            </TableRow>
            {/*Expanding part of row*/}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={collapsed} timeout="auto" unmountOnExit>
                        {/* TODO: Update nested row item*/}
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2} pt={3} pb={4}>
                                <Grid item xs={6} display="flex" alignItems="center" flexDirection="column">
                                    <Typography variant="caption" fontWeight={700} color="text.primary">
                                        Notes
                                    </Typography>

                                    <Typography variant="caption" color="text.primary">
                                        {program.notes ? program.notes : ''}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} display="flex" alignItems="center" flexDirection="column">
                                    <Typography variant="caption" fontWeight={700} color="text.primary">
                                        Next Steps
                                    </Typography>

                                    <Typography variant="caption" color="text.primary">
                                        {program.next_steps ? program.next_steps : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
};

const TableLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main
}));