import React from "react";
import {NavLink} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link, Typography, Breadcrumbs as MUIBreadcrumbs, Button, Box} from "@mui/material";

import {useNavigation} from "../../../hooks";

// TODO: update props
interface BreadcrumbsProps {
    items: IBreadcrumbItem[];
    backRoute?: string | undefined;
}

export interface IBreadcrumbItem {
    label: string;
    to?: string;
    active?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({items, backRoute}) => {
    const { goBack, navigate } = useNavigation();

    const handleGoBack = () => {
        backRoute ? navigate(backRoute) : goBack();
    };

    return (
        <Box sx={{display: "flex", gap: 2, alignItems: {xs: 'flex-start', md: 'center'}, flexDirection: {xs: 'column', md: 'row'}}}>
            <Button size="medium" onClick={handleGoBack} startIcon={<ArrowBackIcon fontSize="small" />}>
                Back
            </Button>
            <MUIBreadcrumbs aria-label="breadcrumb">
                {
                    items.length > 0 &&
                    items.map((item, i) => (
                        item.to
                            ?
                            <Link
                                key={`bread-item-${i}`}
                                component={NavLink}
                                underline="hover"
                                color="inherit"
                                to={item.to}
                            >
                                {item.label}
                            </Link>
                            :
                            <Typography
                                key={`bread-item-${i}`}
                                color={item.active ? 'text.primary' : 'text.secondary'}
                            >
                                {item.label}
                            </Typography>
                    ))
                }
            </MUIBreadcrumbs>
        </Box>
    );
};
