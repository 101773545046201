import React, {useCallback} from "react";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {IconButton, Tooltip, TableCell, Box, TableRow} from "@mui/material";
import {styled} from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ArchiveIcon from "@mui/icons-material/Archive";

import {Endpoints, Routes} from "../../../../consts";
import {useToast} from "../../../../hooks";
import {ActivityType, deliverableTypeList, Task, TaskStatus} from "../../../../types";
import {download} from "../../../../utils";
import {RoundedCheckIcon} from "../../../UI";
import {useGetActivityListQuery} from "../../../../services";

interface RowItemProps {
    task: Task;
}

export const RowItem: React.FC<RowItemProps> = ({task}) => {
    const toast = useToast();
    const {data: fileActivityList} = useGetActivityListQuery({
        task_id: task.id,
        type: ActivityType.FileUpload,
        order_by: '-created_at'
    });

    // On CLick Download Link download latest (created) file activity
    const handleDownloadLatestActivity = useCallback(() => {
        if (fileActivityList && fileActivityList.data.length > 0) {
            download(Endpoints.Activity.DownloadFile(fileActivityList.data[0].upload_location), fileActivityList.data[0].filename);
        } else {
            toast.info('No activities found');
        }
    }, [fileActivityList, toast]);

    return (
        <TableRow sx={{
            '& > *': { borderBottom: 'unset' },
            bgcolor: task.status === TaskStatus.Completed ? 'grey.100' : ''
        }}>
            <TableCell>
                <RoundedCheckIcon
                    type={
                        task.status === TaskStatus.Pending ? 'defaultChecked' :
                            task.status === TaskStatus.Completed ? 'checked' : 'unChecked'
                    }
                />
            </TableCell>

            <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                    <TableLink to={Routes.placementTaskDetails(task.program_id, task.id)}>
                        {task.name}
                    </TableLink>
                    {!task.is_active && (
                        <Tooltip title={task.is_active ? 'Active' : 'Archived'}>
                            <ArchiveIcon fontSize="small" color="disabled" />
                        </Tooltip>
                    )}
                </Box>
            </TableCell>

            <TableCell>{deliverableTypeList[task.deliverable_type]?.label || '-'}</TableCell>

            <TableCell>{task.updated_at ? moment(task.updated_at).format('MM/DD/YYYY') : '-'}</TableCell>

            <TableCell>{task.owner?.name || '-'}</TableCell>

            <TableCell align="right">
                {
                    fileActivityList && fileActivityList.data.length > 0 && (
                        <Tooltip title="Download last uploaded document">
                            <IconButton size="small" onClick={handleDownloadLatestActivity}>
                                <DownloadIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    )
                }
            </TableCell>
        </TableRow>
    );
};

const TableLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main
}));