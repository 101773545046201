import React, {FC, useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {styled} from "@mui/material/styles";
import {
    Box,
    Avatar,
    Typography,
    IconButton,
    Button,
    MenuItem,
    Menu,
    InputAdornment,
    OutlinedInput,
    Tooltip,
    Collapse
} from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';

import {Dot} from '..';
import {Activity, ActivityApprovalStatus, ActivityType} from '../../../types';
import {Endpoints} from '../../../consts';
import {download} from '../../../utils';
import {useAuthContext} from "../../../contexts/AuthContext";
import {
    useAddActivityMutation,
    useDeleteActivityByIdMutation,
    useLikeActivityMutation,
    useUnLikeActivityMutation
} from "../../../services";

interface ActivityCardProps {
    activity: Activity;
    isChild?: boolean;
}

export const ActivityCard: FC<ActivityCardProps> = ({
    activity,
    isChild = false,
}) => {
    const {user, userIsAdmin, userIsManager} = useAuthContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [showReplyInput, setShowReplyInput] = useState(false);
    const [replyMessageValue, setReplyMessageValue] = useState('');

    const [
        addActivity, {isLoading: addActivityLoading, isSuccess: addActivitySuccess}
    ] = useAddActivityMutation();

    const [
        deleteActivity, {isLoading: deleteActivityLoading, isSuccess: deleteActivitySuccess}
    ] = useDeleteActivityByIdMutation();

    const [likeActivity, {isLoading: likeActivityLoading}] = useLikeActivityMutation();
    const [unLikeActivity, {isLoading: unLikeActivityLoading}] = useUnLikeActivityMutation();

    const handleDownload = useCallback(() => {
        download(Endpoints.Activity.DownloadFile(activity.upload_location), activity.filename);
    }, [activity]);

    // Get activity action
    const getActivityActionName = () => {
        switch (activity.type) {
            case ActivityType.Message:
                return 'commented.';
            case ActivityType.FileUpload:
                return 'uploaded a document.';
            case ActivityType.CompleteTask:
                return 'marked this request as complete.';
            case ActivityType.UncompleteTask:
                return 'reopened this request.';
            case ActivityType.ArchiveTask:
                return 'archived this item.';
            case ActivityType.UnarchiveTask:
                return 'unarchived this item.';
            case ActivityType.Approval:
                return activity.text;
            case ActivityType.Denial:
                return activity.text;
            default:
                return 'commented.'
        }
    };

    const handleShowMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setAnchorEl(null);
    };

    const handleReply = () => {
        addActivity({
            type: ActivityType.Message,
            approval_status: ActivityApprovalStatus.New,
            text: replyMessageValue,
            author_id: user?.id,
            task_id: activity.task_id,
            parent_id: activity.id,
        })
    };

    useEffect(() => {
        if (addActivitySuccess) {
            setReplyMessageValue('');
            setShowReplyInput(false);
        }
    }, [addActivitySuccess]);

    const handleDeleteActivity = useCallback(() => {
        deleteActivity(activity.id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (deleteActivitySuccess) {
            handleCloseMoreMenu();
        }
    }, [deleteActivitySuccess]);

    const handleLikeUnLikeActivity = () => {
        if (activity.liked) {
            unLikeActivity(activity.id);
            return;
        }

        likeActivity(activity.id);
    }

    return (
        <CardWrapper sx={{marginLeft: {xs: isChild ? 2 : 0, md: isChild ? 9 : 0}}}>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: 'flex-start', gap: 2}}>

                {/* Card Avatar */}
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {!isChild && <Dot/>}

                    <Tooltip title={activity.author?.name ?? ''}>
                        <Avatar sx={{width: 40, height: 40, marginLeft: 2}}>
                            {activity.author ? activity.author.name.slice(0, 1) : ''}
                        </Avatar>
                    </Tooltip>
                </Box>

                {/* Card Body */}
                <Box>

                    {/*  */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        bgcolor={'#F2F2F2'}
                        paddingY={1}
                        paddingX={2}
                        borderRadius={1}
                    >

                        <Box display="flex">
                            <Typography variant="body2" fontWeight={700}>
                                {activity.author?.name || ''}
                            </Typography>

                            <Typography ml={1} variant="body2">
                                {getActivityActionName()}
                                {
                                    activity?.filename?.length > 0 &&
                                    (activity.type === ActivityType.Approval || activity.type === ActivityType.Denial)
                                    &&
                                    `“${activity.filename}”.`
                                }
                            </Typography>

                            <Typography ml={2} mr={3} variant="caption" color="text.secondary">
                                {moment(activity.created_at).format('MMMM D, YYYY')}
                            </Typography>


                            {/* Draw More Icon for edit/delete if conditions are true */}
                            {
                                (activity.author?.id === user?.id) && (
                                    activity.type === ActivityType.Message ||
                                    activity.type === ActivityType.FileUpload
                                ) && (
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, marginLeft: 'auto'}}>
                                        <IconButton size="small" onClick={handleShowMoreMenu}>
                                            <MoreHorizIcon fontSize="small"/>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={!!anchorEl}
                                            onClose={handleCloseMoreMenu}
                                        >
                                            <MenuItem disabled>Edit</MenuItem>
                                            {
                                                (userIsAdmin || userIsManager) && (
                                                    <MenuItem
                                                        onClick={handleDeleteActivity}
                                                        disabled={deleteActivityLoading}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                )
                                            }
                                        </Menu>
                                    </Box>
                                )
                            }
                        </Box>


                        {/* Card Content */}
                        <Box marginLeft={4}>
                            {
                                // FIXME:
                                (
                                    activity.type === ActivityType.Message ||
                                    activity.type === ActivityType.CompleteTask ||
                                    activity.type === ActivityType.UncompleteTask ||
                                    activity.type === ActivityType.ArchiveTask ||
                                    activity.type === ActivityType.UnarchiveTask
                                ) &&
                                (
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                        <Typography variant="body2">
                                            {activity.text}
                                        </Typography>
                                    </Box>
                                )
                            }
                            {
                                activity.type === ActivityType.FileUpload && (
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="body2" mr={1}>
                                            {activity.filename}
                                        </Typography>
                                        <IconButton size="small" onClick={handleDownload}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    {/*  */}

                    {/*  Actions  */}
                    <Box display="flex" gap={1}>
                        {/* Like Button */}
                        <Button
                            color="primary"
                            sx={{display: 'flex', flexDirection: 'column'}}
                            onClick={handleLikeUnLikeActivity}
                            disabled={likeActivityLoading || unLikeActivityLoading}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                                {
                                    activity.liked
                                    ? <FavoriteIcon />
                                    : <FavoriteBorderIcon />
                                }
                                <Typography variant="body2" color="primary">
                                    {activity.likes_count > 0 ? activity.likes_count : ''}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="primary">Like</Typography>
                        </Button>

                        <Button
                            onClick={() => setShowReplyInput(s => !s)}
                            color="primary"
                            sx={{display: 'flex', flexDirection: 'column'}}
                            disabled={isChild}
                        >
                            <AddCommentIcon />
                            <Typography variant="body2" color="primary">
                                Reply
                            </Typography>
                        </Button>
                    </Box>


                    {/*  Reply Input  */}
                    <Collapse in={showReplyInput} timeout="auto" unmountOnExit>
                        <Box display="flex" alignItems="center" gap={2} marginTop={2}>
                            <Avatar sx={{width: 40, height: 40, marginLeft: 2}}>
                                {activity.author ? activity.author.name.slice(0, 1) : ''}
                            </Avatar>

                            <OutlinedInput
                                placeholder="Write a reply..."
                                multiline
                                maxRows={2}
                                value={replyMessageValue}
                                onChange={(e) => {
                                    setReplyMessageValue(e.target.value)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleReply}
                                            edge="end"
                                            color="primary"
                                            disabled={replyMessageValue.length === 0 || addActivityLoading}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                    </Collapse>

                </Box>
            </Box>
        </CardWrapper>
    );
};

const CardWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,

    '&:last-child': {
        marginBottom: 0,
    }
}));