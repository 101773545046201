import { TaskType } from "./types";
export const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const StorageKey = {
    Token: 'gc-access-token',
    TokenType: 'gc-access-token-type',
    UserId: 'gc-user-id',
    OktaStorage: 'okta-token-storage'
};

export const Routes = {
    home: '/dashboard',
    placements: '/',
    placementRequests: (placementId: number) => `/${placementId}/requests`,
    placementApprovals: (placementId: number) => `/${placementId}/approvals`,
    placementDeliverables: (placementId: number) => `/${placementId}/deliverables`,
    placementEvents: (placementId: number) => `/${placementId}/events`,
    placementTeam: (placementId: number) => `/${placementId}/team`,
    placementTaskDetails: (programId: number, taskId: number) => `/${programId}/tasks/${taskId}`,
    login: '/login',
    notifications: '/notifications',
    tasks:'/tasks',
    leaderShip: '/leadership',
    templates: '/templates'
};

export const Endpoints = {
    Auth: {
        Login: 'auth/login',
    },
    User: {
        List: `users/`,
        Details: (userId: number) => `users/${userId}`,
        Update: (userId: number) => `users/${userId}`,
        Avatar: (avatarFilename: string) => `${BASE_URL}/users/avatar/${avatarFilename}`,
        Profile: 'users/self'
    },
    Client: {
        List: `client/`,
        Create: `client/`,
        Details: (clientId: number) => `client/${clientId}`
    },
    Program: {
        // search: string, skip: number, limit: number, orderBy: string = 'status', is_active: boolean,
        // type: string = '', lob: string = '', year: string = '', status: string = ''
        List: `program/`,
        Details: (programId: number) => `program/${programId}`,
        Create: 'program/',
        Update: (programId: number) => `program/${programId}`
    },
    Task: {
        List: (programId: number, taskType: TaskType) => (`task/${programId}/${taskType}`),
        MyList: `task/my`,
        Details: (taskId: number) => `task/${taskId}`,
        Create: 'task/',
        Update: (taskId: number) => `task/${taskId}`,
    },
    Activity: {
        List: (taskId: number) => `activity/task/${taskId}`,
        Details: (activityId: number) => `/activity/${activityId}`,
        Create: '/activity/',
        Update: (activityId: number) => `/activity/${activityId}`,
        Delete: (activityId: number) => `/activity/${activityId}`,
        UploadFile: (activityId: number) => `/activity/upload-file/${activityId}`,
        DownloadFile: (uploadLocation: string) => `/activity/download-file/${uploadLocation}`,
        Like: (activityId: number) => `/activity/${activityId}/like`,
        UnLike: (activityId: number) => `/activity/${activityId}/remove-like`,
    },
    Event: {
        List: (programId: number) => `program/${programId}/event`,
        Details: (programId: number, eventId: number) => `program/${programId}/event/${eventId}`,
        Create: (programId: number) => `program/${programId}/event`,
        Update: (programId: number, eventId: number) => `program/${programId}/event/${eventId}`,
        Delete: (eventId: number) => `program/event/${eventId}`,
    },
    Dashboard: '/dashboard/'
};