import React, {FC} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Task} from "../../types";

interface ArchiveTaskModalProps {
    open: boolean;
    onClose: () => void;
    task: Task | undefined;
    onArchiveTask: () => void;
    loading: boolean;
    archiveTaskComment: string;
    setArchiveTaskComment: (comment: string) => void;
}
export const ArchiveTaskModal: FC<ArchiveTaskModalProps> = ({
    open,
    onClose,
    task,
    onArchiveTask,
    loading,
    archiveTaskComment,
    setArchiveTaskComment
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={onClose}
        >
            <DialogTitle color={'text.primary'}>
                {`${task?.is_active ? 'Archive' : 'Unarchive'} this item`}
            </DialogTitle>
            <DialogContent>
                <Box p={1}>
                    <TextField
                        autoFocus={true}
                        fullWidth
                        placeholder={'Please add a reason...'}
                        multiline
                        rows={8}
                        value={archiveTaskComment}
                        onChange={(e) => {
                            setArchiveTaskComment(e.target.value)
                        }}
                        helperText={'Required'}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                <LoadingButton
                    loading={loading}
                    disabled={archiveTaskComment.length === 0}
                    onClick={onArchiveTask}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}