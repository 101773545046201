import React from "react";
import {useParams} from "react-router-dom";
import {Container} from "@mui/material";

import {MainLayout} from "../../../layouts";
import {PageLoader, TableEmptyList, TableLoader} from "../../../components/UI";
import {PlacementHeading} from "../../../components/PlacementHeading";
import {useGetProgramByIdQuery, useGetUserListQuery} from "../../../services";
import {PlacementsTeamTable} from "../../../components/Tables/PlacementsTeamTable";
import {PlacementTeamActionPanel} from "../../../components/PlacementActionPanels";
import {useAuthContext} from "../../../contexts/AuthContext";

export const PlacementsTeamPage: React.FC = () => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const {placementId} = useParams<{placementId: string}>();
    const {data: programData} = useGetProgramByIdQuery(+placementId);
    const {data: userList, isLoading: getUserListLoading} = useGetUserListQuery({});

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{display: 'flex', flexDirection: 'column', margin: '32px auto'}}
            >
                {
                    programData
                        ?
                        <>
                            <PlacementHeading placement={programData} />
                            {(userIsAdmin || userIsManager) && <PlacementTeamActionPanel program={programData} />}

                            {
                                !getUserListLoading
                                ?
                                    userList?.data && userList.data.length > 0
                                    ?
                                        <PlacementsTeamTable data={userList.data}/>
                                        :
                                        <TableEmptyList />
                                :
                                    <TableLoader />
                            }

                        </>
                        :
                        <PageLoader/>
                }
            </Container>
        </MainLayout>
    );
};
