import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Alert, AlertTitle, Box, Slide, Snackbar, Typography} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {AlertState, AlertSuccessModel, closeAlert} from "../store/alertSlice";

export const AlertProvider: FC = () => {
    const dispatch = useAppDispatch();
    const {
        isOpen, title, variant, severity, color, duration, direction, successModel, linkItems
    } = useAppSelector<AlertState>(state => state.alert);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(closeAlert());
    };

    const getContent = () => {
        switch (successModel) {
            case AlertSuccessModel.Task:
                return (
                    <Box sx={{display: 'flex', gap: 0.5}}>
                        <Typography variant="body2" color="primary.contrastText">Your</Typography>
                        <StyledLink to={linkItems?.[0]?.path} >
                            <Typography variant="body2" color="primary.contrastText" fontWeight={700} >
                                {linkItems?.[0]?.label}
                            </Typography>
                        </StyledLink>
                        <Typography variant="body2" color="primary.contrastText">has been created for the</Typography>
                        <StyledLink to={linkItems?.[1]?.path}>
                            <Typography variant="body2" color="primary.contrastText" fontWeight={700}>
                                {linkItems?.[1]?.label}
                            </Typography>
                        </StyledLink>
                        <Typography variant="body2" color="primary.contrastText">placement.</Typography>
                    </Box>
                );
            case AlertSuccessModel.Program:
                return (
                    <Box sx={{display: 'flex', gap: 0.5}}>
                        <Typography variant="body2" color="primary.contrastText">Your</Typography>
                        <StyledLink to={linkItems?.[0]?.path} >
                            <Typography variant="body2" color="primary.contrastText" fontWeight={700} >
                                {linkItems?.[0]?.label}
                            </Typography>
                        </StyledLink>
                        <Typography variant="body2" color="primary.contrastText">placement has been saved.</Typography>
                    </Box>
                );
            case AlertSuccessModel.None:
                return null;
            default:
                return null
        }
    }

    if (!isOpen) {
        return null
    }

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            onClose={handleClose}
            TransitionComponent={(props) => (
                <Slide {...props} direction={direction} />
            )}
        >
            <Alert
                onClose={handleClose}
                variant={variant}
                severity={severity}
                color={color}
                sx={{ width: '100%' }}
                iconMapping={{
                    success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
            >
                <AlertTitle color={'primary.contrastText'}>{title}</AlertTitle>
                {getContent()}
            </Alert>
        </Snackbar>
    )
}

const StyledLink = styled(NavLink)(({theme}) => ({
    color: theme.palette.primary.contrastText
}));