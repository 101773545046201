import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import moment from "moment";
import {useDropzone} from "react-dropzone";
import sortBy from "lodash/sortBy";
import {DesktopDatePicker, LoadingButton} from "@mui/lab";
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    Container,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import MoreVertRounded from "@mui/icons-material/MoreVertRounded";
import DownloadIcon from "@mui/icons-material/Download";

import {Dot, ApprovalApprovedChip, ApprovalDeclinedChip} from "../UI";
import {Endpoints} from "../../consts";
import {download, sortByDate} from "../../utils";
import {useAuthContext} from "../../contexts/AuthContext";
import {Activity, ActivityApprovalStatus, Task, TaskPhase, taskPhaseList, User} from "../../types";
import {useGetUserListQuery} from "../../services";



interface NewActivityDetails {
    parent: Activity;
    status: ActivityApprovalStatus;
    comment: string;
}

interface ApprovalTaskProps {
    task: Task;
    approver: User | undefined;
    onUploadFile: (formData: FormData) => void;
    uploadLoading: boolean;
    uploadPercent: number;
    fileActivities: Activity[];
    onApproveOrDecline: (parentActivity: Activity, approvalActionStatus: ActivityApprovalStatus, comment: string) => void;
    onApproveOrDeclineLoading: boolean;
    onApproveOrDeclineSuccess: boolean;
    onTaskUpdate: (task: Task) => void;
    updateLoading: boolean;
    updateSuccess: boolean;
    onTaskArchive: () => void;
}

export const ApprovalTask:React.FC<ApprovalTaskProps> = ({
     task,
     approver,
     onUploadFile,
     uploadLoading,
     uploadPercent,
     fileActivities,
     onApproveOrDecline,
     onApproveOrDeclineLoading,
     onApproveOrDeclineSuccess,
     onTaskUpdate,
     updateLoading,
     updateSuccess,
     onTaskArchive,
}) => {
    const {data: userList} = useGetUserListQuery({});
    const {userIsAdmin, userIsManager} = useAuthContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [newActivityDetails, setNewActivityDetails] = useState<NewActivityDetails | null>(null);

    const updateTaskSchema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        assignee_id: yup.string().required(''),
        target_date: yup.string().required(''),
        phase: yup.string().required('')
    });

    const updateTaskForm = useFormik({
        initialValues: {
            name: task.name,
            description: task.description,
            assignee_id: task.assignee_id,
            target_date: new Date(task.target_date),
            phase: task.phase
        },
        validationSchema: updateTaskSchema,
        onSubmit: (values, {resetForm}) => {
            onTaskUpdate({...task, ...values});
        }
    });

    const handleDownload = (activity: Activity) => {
        download(Endpoints.Activity.DownloadFile(activity.upload_location), activity.filename);
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        disabled: uploadLoading,
        onDropAccepted: (files: File[]) => {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('fileb', files[0], files[0].name);
                onUploadFile(formData);
            }
        }
    });

    const {values, handleSubmit, handleChange, setFieldValue, isValid, dirty, resetForm} = updateTaskForm;

    useEffect(() => {
        if (updateSuccess) {
            resetForm({values});
        }
        // eslint-disable-next-line
    }, [updateSuccess]);

    const handleShowMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setAnchorEl(null);
    };

    const handleToggleArchiveModal = () => {
        onTaskArchive();
        handleCloseMoreMenu();
    };

    // SHOW Confirm Modal
    const handleShowApproveOrDeclineModal = (activity: Activity, status: ActivityApprovalStatus) => {
        if (activity.approval_status === status) return;
        setNewActivityDetails({
            parent: activity,
            status: status,
            comment: ''
        })
    }

    // Confirm Approve or Decline
    const handleApproveOrDecline = () => {
        if (newActivityDetails) {
            onApproveOrDecline(newActivityDetails.parent, newActivityDetails.status, newActivityDetails.comment);
        }
    }

    useEffect(() => {
        if (onApproveOrDeclineSuccess) {
            setNewActivityDetails(null)
        }
    }, [onApproveOrDeclineSuccess])

    return (
        <Container disableGutters sx={{display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
            <form onSubmit={handleSubmit}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column-reverse', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginTop: 6,
                    marginBottom: 4,
                    gap: 6
                }}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: 3, minWidth: {xs: 'auto', md: 400}}}>
                        {
                            (userIsAdmin || userIsManager) ?
                                (
                                    <TextField
                                        name="name"
                                        fullWidth
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                )
                                :
                                <Typography variant="h4" fontWeight={700}>{task.name}</Typography>
                        }

                        {(userIsAdmin || userIsManager) && !task.is_active && (<Chip label="Archived" />)}
                    </Box>


                    {(userIsAdmin || userIsManager) && (
                        <Box sx={{display: 'flex', alignItems: 'center', gap: {xs: 1, md: 3}}}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                loading={updateLoading}
                                disabled={!dirty || !isValid}
                            >
                                Save
                            </LoadingButton>

                            <IconButton type="button" size="large" onClick={handleShowMoreMenu}>
                                <MoreVertRounded color={"action"}/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={handleCloseMoreMenu}
                            >
                                <MenuItem onClick={handleToggleArchiveModal}>
                                    {task.is_active ? "Archive" : "Unarchive"}
                                </MenuItem>
                            </Menu>
                        </Box>
                    )}
                </Box>

                {/* Task description */}
                {
                    (userIsAdmin || userIsManager)
                        ?
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1}}>
                            <TextField
                                name="description"
                                fullWidth
                                placeholder="Description"
                                multiline
                                rows={10}
                                value={values.description}
                                onChange={handleChange}
                            />
                        </Box>
                        :
                        <Typography variant="body2" sx={{lineBreak: 'normal'}}>
                            {task.description}
                        </Typography>
                }


                <Box sx={{display: 'flex', flexWrap: 'wrap', marginY: 3, alignItems: 'center', gap: 3}}>
                    {/* Task Approver */}
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <Typography variant="body2" fontWeight={700}>
                            Approver:
                        </Typography>
                        {
                            (userIsAdmin || userIsManager)
                                ?
                                <Select
                                    variant="standard"
                                    labelId="approver-label"
                                    name="assignee_id"
                                    value={values.assignee_id}
                                    onChange={handleChange}
                                    label="Approver"
                                >
                                    <MenuItem value="" disabled>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        (userList && userList.data.length > 0) &&
                                        sortBy(userList.data, ['name']).map((user) => (
                                            <MenuItem key={`user-${user.id}`} value={user.id}>{user.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                :
                                <Typography variant="body2">
                                    {approver ? approver.name : ' - '}
                                </Typography>
                        }
                    </Box>

                    {/* Approval Target Date */}
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <Typography variant="body2" fontWeight={700}>
                            Target Date:
                        </Typography>
                        {
                            (userIsAdmin || userIsManager)
                                ?
                                <DesktopDatePicker
                                    value={values.target_date}
                                    onChange={(newValue) => {
                                        setFieldValue('target_date', newValue);
                                    }}
                                    renderInput={(params) => <TextField variant="standard" {...params} />}
                                />
                                :
                                <Typography variant="body2">
                                    {task ? moment(task.target_date).format('MM/DD/YYYY') : ' - '}
                                </Typography>
                        }
                    </Box>

                    {/* Approval Phase */}
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <Typography variant="body2" fontWeight={700}>
                            Phase:
                        </Typography>
                        {
                            (userIsAdmin || userIsManager)
                                ?
                                <Select
                                    variant="standard"
                                    labelId="phase-label"
                                    name="phase"
                                    value={values.phase}
                                    onChange={handleChange}
                                    label="Phase"
                                >
                                    <MenuItem value="" disabled>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        Object.keys(taskPhaseList).map((key ) => (
                                            <MenuItem key={`phase-${key}`} value={key}>
                                                {taskPhaseList[key as TaskPhase]?.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                :
                                <Typography variant="body2">
                                    {taskPhaseList[task?.phase]?.label || ' - '}
                                </Typography>
                        }
                    </Box>
                </Box>

                {/*  Dropzone area   */}
                {
                    (userIsAdmin || userIsManager) && (
                        <Box>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: '#DAF0F7',
                                    border: '1px dashed',
                                    borderColor: isDragActive ? 'primary.light' : 'primary.dark'
                                }}>
                                    <Box sx={{display: 'flex', justifyContent: 'center', padding: 7}}>
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    color="primary"*/}
                                        {/*    size="large"*/}
                                        {/*    disabled={uploadLoading}*/}
                                        {/*>*/}
                                        {/*    {uploadLoading ? `Uploaded ${uploadPercent}%` : 'UPLOAD FILE'}*/}
                                        {/*</Button>*/}
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={uploadLoading}
                                            loading={uploadLoading}
                                        >
                                            UPLOAD FILE
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    )
                }
            </form>

            {/*  Approval Documents   */}
            <Container
                disableGutters
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    boxSizing: 'border-box',
                    paddingTop: 3,
                }}
            >
                <Typography variant="h6" fontWeight={700} color="text.primary" mb={3}>
                    Document Review
                </Typography>

                {
                    fileActivities?.length > 0
                        ?
                        sortByDate<Activity>(fileActivities, 'created_at', 'descending')
                            .map((activity, i) => (
                            //    TODO: Create component
                            <Box
                                key={`activity-approval-item-${activity.id}`}
                                display="flex"
                                flexDirection="column"
                                mb={2}
                                sx={{gap: 1}}
                            >
                                <Box display="flex" sx={{
                                    gap: {xs: 1, md: 4},
                                    alignItems: {xs: 'flex-start', md: 'flex-end'},
                                    flexDirection: {xs: 'column', md: 'row'}
                                }}>

                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Dot/>
                                        <Typography variant="body2" mr={2} ml={2}>
                                            {activity.filename}
                                        </Typography>
                                        <IconButton onClick={() => handleDownload(activity)}>
                                            <DownloadIcon/>
                                        </IconButton>
                                    </Box>

                                    <Box>
                                        <ButtonGroup color="primary" sx={{display: 'block'}}>
                                            <Button
                                                size="small"
                                                variant={
                                                    activity.approval_status === ActivityApprovalStatus.Approved
                                                    ? 'contained' : 'outlined'
                                                }
                                                onClick={() => {
                                                    handleShowApproveOrDeclineModal(activity, ActivityApprovalStatus.Approved)
                                                }}
                                                disabled={
                                                    onApproveOrDeclineLoading ||
                                                    i > 0 ||
                                                    activity.approval_status === ActivityApprovalStatus.Approved
                                                }
                                            >
                                                APPROVE
                                            </Button>

                                            <Button
                                                size="small"
                                                variant={
                                                    activity.approval_status === ActivityApprovalStatus.Declined
                                                    ? 'contained' : 'outlined'
                                                }
                                                onClick={() => {
                                                    handleShowApproveOrDeclineModal(activity, ActivityApprovalStatus.Declined)
                                                }}
                                                disabled={
                                                    onApproveOrDeclineLoading ||
                                                    i > 0 ||
                                                    activity.approval_status === ActivityApprovalStatus.Declined
                                                }
                                            >
                                                DECLINE
                                            </Button>
                                        </ButtonGroup>
                                    </Box>

                                    <Box>
                                        {activity.approval_status === ActivityApprovalStatus.Approved && (
                                            <ApprovalApprovedChip />
                                        )}

                                        {activity.approval_status === ActivityApprovalStatus.Declined && (
                                            <ApprovalDeclinedChip />
                                        )}
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography variant="caption" ml={2} color="text.secondary">
                                        {moment(activity.created_at).format('MMMM D, YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                        :
                        <Typography variant="body2" fontWeight={500}>
                            Uploaded documents are missing
                        </Typography>
                }
            </Container>

            {/* Add Comment to Approve or Decline Activity */}
            {/*TODO: Create components*/}
            <Dialog
                open={!!newActivityDetails}
                onClose={() => setNewActivityDetails(null)}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle color={'text.primary'}>
                    {`Are you sure you want to ${
                        newActivityDetails?.status === ActivityApprovalStatus.Approved ? 'Approve' : 'Decline'
                    } this item?`}
                </DialogTitle>

                <DialogContent>
                    {
                        newActivityDetails && (
                            <TextField
                                multiline
                                fullWidth
                                rows={4}
                                placeholder={newActivityDetails?.status === ActivityApprovalStatus.Approved
                                    ? 'Add a comment...'
                                    : 'Please provide a reason for the decline...'
                                }
                                helperText={newActivityDetails?.status === ActivityApprovalStatus.Approved
                                    ? 'Optional'
                                    : '*Required'
                                }
                                value={newActivityDetails.comment}
                                onChange={e => {
                                    setNewActivityDetails({...newActivityDetails, comment: e.target.value})
                                }}
                            />
                        )
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setNewActivityDetails(null)}>Cancel</Button>

                    <LoadingButton
                        loading={onApproveOrDeclineLoading}
                        disabled={
                            newActivityDetails?.status === ActivityApprovalStatus.Approved
                            ? false
                            : newActivityDetails?.comment.length === 0
                        }
                        onClick={handleApproveOrDecline}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
};