import { createApi } from '@reduxjs/toolkit/query/react'

import {IEvent, Paginated} from "../types";
import {Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";

export const eventApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Event'],
    endpoints: (builder) => ({
        getEventList: builder.query<Paginated<IEvent>, any>({
            query: ({program_id, ...params}) => ({
                url: Endpoints.Event.List(program_id),
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'Event')
        }),
        getEventById: builder.query<IEvent, any>({
            query: ({program_id, id}) => ({ url: Endpoints.Event.Details(program_id, id) }),
            providesTags: (result, error, id) => [{ type: 'Event', id }],
        }),
        addEvent: builder.mutation<IEvent, any>({
            query: (body) => ({
                url: Endpoints.Event.Create(body.program_id),
                method: 'POST',
                body: body
            }),
            invalidatesTags: [{ type: 'Event', id: 'LIST' }],
        }),
        updateEvent: builder.mutation<IEvent, Partial<IEvent> & Pick<IEvent, 'id'> & Pick<IEvent, 'program_id'>>({
            query: ({program_id, id, ...body}) => ({
                url: Endpoints.Event.Update(program_id, id),
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Event', id: arg.id }],
        }),
        deleteEventById: builder.mutation<any, any>({
            query: (id) => ({
                url: Endpoints.Event.Delete(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'Event', id },
                { type: 'Event', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetEventListQuery, useLazyGetEventListQuery, useGetEventByIdQuery, useAddEventMutation, useUpdateEventMutation, useDeleteEventByIdMutation
} = eventApi