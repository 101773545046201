import { createApi } from '@reduxjs/toolkit/query/react'

import {Endpoints} from "../consts";
import {DashboardData} from "../types";
import {staggeredBaseQuery} from "./helpers";

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Dashboard'],
    endpoints: (builder) => ({
        getDashboard: builder.query<DashboardData, any>({
            query: () => ({ url: Endpoints.Dashboard }),
            providesTags: (result, error, id) => [{ type: 'Dashboard', id }],
        }),
    }),
});

export const { useGetDashboardQuery } = dashboardApi