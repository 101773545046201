import React, {useCallback, useState} from "react";
import moment from "moment";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import {Typography, IconButton, Box} from "@mui/material";

import { Dot } from "..";
import { Activity } from "../../../types";
import { Endpoints } from "../../../consts";
import { download } from "../../../utils";
import {styled} from "@mui/material/styles";
import {useAuthContext} from "../../../contexts/AuthContext";
import {useDeleteActivityByIdMutation} from "../../../services";

interface ActivityFileCardProps {
    activity: Activity;
}

export const ActivityFileCard: React.FC<ActivityFileCardProps> = ({activity}) => {
    const {user, userIsAdmin, userIsManager} = useAuthContext();

    const [
        deleteActivity, {isLoading: deleteActivityLoading}
    ] = useDeleteActivityByIdMutation();

    const handleDownload = useCallback(() => {
        download(Endpoints.Activity.DownloadFile(activity.upload_location), activity.filename)
    }, [activity]);

    const handleDeleteActivity = () => {
        deleteActivity(activity.id);
        // eslint-disable-next-line
    }

    return (
        <CardWrapper>
            <Box sx={{display: 'flex', alignItems: 'center', minWidth: '200px'}}>
                <Dot />

                <Typography variant="body2" ml={2} mr={2}>
                    {activity.filename}
                </Typography>

                <IconButton size="small" onClick={handleDownload} sx={{marginLeft: 'auto'}}>
                    <DownloadIcon fontSize="small" />
                </IconButton>

                {
                    (userIsAdmin || userIsManager) && (activity.author?.id === user?.id) && (
                        <IconButton
                            size="small"
                            sx={{marginRight: 1}}
                            onClick={handleDeleteActivity}
                            disabled={deleteActivityLoading}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )
                }
            </Box>

            <Typography variant="caption" color="text.secondary" ml={3}>
                {moment(activity.created_at).format('MMMM D, YYYY')}
            </Typography>
        </CardWrapper>
    );
};

const CardWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,

    '&:last-child': {
        marginBottom: 0,
    }
}));