import React, {useState} from "react";
import {
    Avatar, Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import {LoadingButton} from "@mui/lab";

import {User} from "../../../types";
import {Endpoints} from "../../../consts";
import {useGetClientListQuery} from "../../../services";
import {useAuthContext} from "../../../contexts/AuthContext";

interface TableProps {
    data: User[];
}

export const PlacementsTeamTable: React.FC<TableProps> = ({data}) => {
    // TODO: FIX
    const {userIsAdmin, userIsManager} = useAuthContext();
    const {data: clientList} = useGetClientListQuery({});
    const [memberToDelete, setMemberToDelete] = useState<User | null>(null);

    return (
        <Container disableGutters sx={{marginTop: 5}}>
            <Grid
                container
                spacing={0}
                sx={{borderBottom: '1px solid', borderColor: 'divider'}}
            >
                <Grid item xs={1} p={2} gap={1}></Grid>

                <Grid item xs={2} p={2} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">Name</Typography>
                </Grid>
                <Grid item xs={2} p={2} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">Company</Typography>
                </Grid>
                <Grid item xs={2} p={2} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">Role</Typography>
                </Grid>
                <Grid item xs={2} p={2} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">Email</Typography>
                </Grid>
                <Grid item xs={2} p={2} display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle2">Phone</Typography>
                </Grid>

                <Grid item xs={1} p={2} gap={1}></Grid>
            </Grid>

            {
                data.map(user => (
                    <Grid container spacing={0} sx={{borderBottom: '1px solid', borderColor: 'divider'}} key={`user-${user.id}`}>
                        <Grid item xs={1} py={1} px={2} gap={1}>
                            <Avatar sx={{width: 42, height: 42}}>
                                {user ? user.name.slice(0, 1) : ''}
                            </Avatar>
                        </Grid>

                        <Grid item xs={2} py={1} px={2} display="flex" alignItems="center">
                            <Typography variant="body2">{user.name}</Typography>
                        </Grid>
                        <Grid item xs={2} py={1} px={2} display="flex" alignItems="center">
                            <Typography variant="body2">
                                {clientList?.data?.find(client => client.id === user.client_id)?.common_name || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} py={1} px={2} display="flex" alignItems="center">
                            <Typography variant="body2" sx={{textTransform: 'capitalize'}}>{user.role}</Typography>
                        </Grid>
                        <Grid item xs={2} py={1} px={2} display="flex" alignItems="center">
                            <Typography variant="body2">{user.email}</Typography>
                        </Grid>
                        <Grid item xs={2} py={1} px={2} display="flex" alignItems="center">
                            <Typography variant="body2">123-456-7890</Typography>
                        </Grid>

                        <Grid item xs={1} py={1} px={2}>
                            {(userIsAdmin || userIsManager) && (
                                <IconButton onClick={() => setMemberToDelete(user)}>
                                    <DoDisturbOnIcon />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))
            }


            {/*  Delete Member Modal  */}
            <Dialog open={!!memberToDelete} onClose={() => setMemberToDelete(null)}>
                <DialogTitle color={'text.primary'}>Remove this team member</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure? {memberToDelete?.name} will no longer have access to this placement.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setMemberToDelete(null)}>CANCEL</Button>

                    <LoadingButton
                        color="primary"
                        loading={false}
                        onClick={() => {}}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
