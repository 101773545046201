// TODO: Create Single helper in utils
import {Program} from "../types";
import moment from "moment";

export const getProgramStatusColor = (program: Program): string => {
    // TODO: 7 should be business days?
    const soonDate = new Date().setDate(new Date().getDate() + 7);

    if (moment(new Date()).isAfter(program?.target_date)) {
        return 'error.main';
    }
    if (moment(program?.target_date).isBetween(new Date(), soonDate)) {
        return 'warning.main';
    }
    return 'text.primary';
};