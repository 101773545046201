import React, {FC, useMemo} from 'react';

import {
    TaskFilters,
    DeliverableType,
    deliverableTypeList
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskDeliverableTypeFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const TaskDeliverableTypeFilterBar: FC<TaskDeliverableTypeFilterBarProps> = ({
    filters,
    setFilters,
}) => {
    const typesFilterOptions = useMemo<IOption[]>(() => {
        const typesList = Object.keys(deliverableTypeList) as DeliverableType[];
        if (typesList.length) {
            return typesList.map(type => ({
                label: deliverableTypeList[type]?.label,
                value: deliverableTypeList[type]?.value,
            }))
        }
        return [];
    }, []);


    return (
        <MultiSelectPopover
            togglerTitle={"DELIVERABLE TYPE"}
            selectedValues={filters.deliverableTypes || []}
            options={typesFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, deliverableTypes: selectedItems.map(item => item.value as DeliverableType)})
            }}
            onReset={() => setFilters({...filters, deliverableTypes: []})}
            searchPlaceholder={'Search for a Deliverable Type'}
        />
    );
};