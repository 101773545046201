import {useHistory} from "react-router-dom";


export const useNavigation = () => {
    const history = useHistory();

    const navigate = (route: string): void => {
        history.push(route);
    }

    const replace = (route: string): void => {
        history.replace(route);
    }

    const goBack = (): void => {
        history.goBack();
    }

    return { navigate, goBack, replace};
}