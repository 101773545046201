import React from "react";
import { Route, Redirect } from "react-router-dom";
import { SecureRoute } from "@okta/okta-react";


export interface IRoute {
    path: string;
    component: any;
    exact: boolean;
    secure: boolean;
    routes?: IRoute[];
    redirect?: string;
}

export const RouteWithSubRoutes = (route: IRoute) => {
    if (!route.secure) {
        return (
            <Route
                exact={route.exact}
                path={route.path}
                render={props => (
                    <route.component {...props} routes={route.routes} />
                )}
            >
                {route.redirect && <Redirect to={route.redirect} />}
            </Route>
        )
    }

    return (
        <SecureRoute
            exact={route.exact}
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
}