import React, { useMemo } from 'react';

import {ActivitiesFilters } from "../../types";
import {useGetUserListQuery} from "../../services";
import {IOption, MultiSelectPopover} from "../UI";

interface ActivityAuthorFilterBarProps {
    filters: ActivitiesFilters;
    setFilters: (filters: ActivitiesFilters) => void;
}

export const ActivityAuthorFilterBar: React.FC<ActivityAuthorFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const {data: userList} = useGetUserListQuery({});

    const authorFilterOptions = useMemo<IOption[]>(() => {
        if (userList?.data) {
            return userList?.data?.map(user => ({
                label: user.name,
                value: user.id,
            }))
        }
        return [];
    }, [userList]);

    return (
        <MultiSelectPopover
            togglerTitle={"Author"}
            selectedValues={filters.authorIds}
            options={authorFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, authorIds: selectedItems.map(item => +item.value)})
            }}
            onReset={() => setFilters({...filters, authorIds: []})}
            searchPlaceholder={'Search for an author'}
        />
    );
};