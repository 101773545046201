import React, {useMemo} from "react";
import {TableBody, TableCell, TableContainer, TableHead} from "@mui/material";

import {TableEmptyList, TableLoader, TableSortButton} from "../../UI";
import {
    Task,
    TaskSortParams,
    TaskOrderBy,
} from "../../../types";
import {RowItem} from "./RowItem";
import {StyledHeadRow, StyledTable} from "../components";

interface TableProps {
    data: Task[];
    loading: boolean;
    orderBy: TaskSortParams;
    onOrderByChange: (orderBy: TaskSortParams) => void;
}

export const PlacementsRequestsTable: React.FC<TableProps> = ({
  data,
  loading,
  orderBy,
  onOrderByChange
}) => {
    // Sort Table Items
    const handleOrderByChange = (value: TaskOrderBy) => {
        if (orderBy.value === value) {
            // Unsort
            if (orderBy.direction === 'desc') {
                onOrderByChange({
                    value: 'id',
                    direction: 'asc'
                })
                return;
            }

            onOrderByChange({
                value: value,
                direction: orderBy.direction === 'asc' ? 'desc' : 'asc'
            })
            return;
        }

        onOrderByChange({
            value: value,
            direction: 'asc'
        });
    };

    const columns = useMemo(() => [
        {label: '', key: '', visible: true},
        {label: 'Request', key: 'name', visible: true},
        {label: 'Updated', key: 'updated_at', visible: true},
        {label: 'Target Date', key: 'target_date', visible: true},
        {label: 'Sender', key: 'assignee_id', visible: true},
        {label: 'Status', key: 'status', visible: true},
        {label: '', key: '', visible: true},
    ], []);

    if (loading) {
        return <TableLoader />;
    }

    if (data.length === 0) {
        return <TableEmptyList />;
    }

    return (
        <TableContainer sx={{marginTop: 2}}>
            <StyledTable>
                <TableHead>
                    <StyledHeadRow>
                        {
                            columns.map((column, i) => {
                                if (!column.visible) return null

                                return (
                                    <TableCell key={`column-item-${i}`}>
                                        <span>{column.label}</span>
                                        {
                                            !!column.key && (
                                                <TableSortButton
                                                    onClick={() => handleOrderByChange(column.key as TaskOrderBy)}
                                                    active={orderBy.value === column.key}
                                                    orderBy={orderBy}
                                                    value={column.key}
                                                />
                                            )
                                        }
                                    </TableCell>
                                )
                            })
                        }
                    </StyledHeadRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((task) => (
                            <RowItem
                                key={`task-item-${task.id}`}
                                task={task}
                            />
                        ))
                    }
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
};
