import React, { useMemo } from 'react';

import {ProgramFilters, ProgramStatus, programStatusList} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface ProgramStatusFilterBarProps {
    filters: ProgramFilters;
    setFilters: (filters: ProgramFilters) => void;
}

export const ProgramStatusFilterBar: React.FC<ProgramStatusFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const statusFilterOptions = useMemo<IOption[]>(() => {
        const statusList = Object.keys(programStatusList) as ProgramStatus[];
        if (statusList.length) {
            return statusList.map(status => ({
                label: programStatusList[status]?.label,
                value: programStatusList[status]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Status"}
            selectedValues={filters.statuses}
            options={statusFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, statuses: selectedItems.map(item => item.value as ProgramStatus)})
            }}
            onReset={() => setFilters({...filters, statuses: []})}
            searchPlaceholder={'Search for a Status'}
        />
    );
};