import React from "react";
import moment from 'moment';
import {styled} from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import EditIcon from "@mui/icons-material/Edit";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {Box, Avatar, IconButton, Typography} from "@mui/material";

import {useAuthContext} from '../../contexts/AuthContext';
import {IEvent} from '../../types';
import {useGetUserByIdQuery, useGetUserListQuery} from "../../services";
import {Endpoints} from "../../consts";

interface EventCardProps {
    event: IEvent;
    onDelete: () => void;
    onEdit: () => void;
}

export const EventCard = ({
  event,
  onDelete,
  onEdit
}: EventCardProps) => {
    const {userIsAdmin, userIsManager} = useAuthContext();

    const {data: userList, isLoading: getUserListLoading} = useGetUserListQuery({});
    const {data: organizer} = useGetUserByIdQuery(event.organizator_id);

    return (
        <CardContainer>
            <Box>
                <CalendarTodayIcon fontSize="large"/>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, gap: 3}}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography variant="body2" fontWeight={700} mb={0.5}>{event.name}</Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <Typography variant="caption">
                            {moment(new Date(event.date)).format('DD-MMM-YYYY')}
                        </Typography>
                        <Typography variant="caption">
                            {
                                new Date(event.start_time).getHours() === new Date(event.end_time).getHours()
                                    ? 'All Day'
                                    : (
                                        `${moment(new Date(event.start_time)).format('h:mma')} to 
                                        ${moment(new Date(event.end_time)).format('h:mma')} EST`
                                    )
                            }
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography variant="body2" fontWeight={700}>INVITEES ({`${event.attendees.length + 1}`})</Typography>
                    {
                        getUserListLoading
                            ?
                            <Skeleton variant="circular" width={40} height={40}/>
                            :
                            (userList?.data && userList.data.length > 0)
                                ?
                                (
                                    <Box sx={{display: 'flex', gap: 0.5}}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '2px'
                                        }}>
                                            <Avatar sx={{width: '42px', height: '42px'}}>
                                                {organizer ? organizer.name.slice(0, 1) : ''}
                                            </Avatar>

                                            <Typography variant="caption">Organizer</Typography>
                                        </Box>
                                        {
                                            event.attendees.length > 0 && (
                                                event.attendees.map(attendee => (
                                                    <Box
                                                        key={`attendee-${attendee.id}`}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            gap: '2px'
                                                        }}
                                                    >
                                                        <Avatar sx={{width: '42px', height: '42px'}}>
                                                            {attendee ? attendee.name.slice(0, 1) : ''}
                                                        </Avatar>
                                                    </Box>
                                                ))
                                            )
                                        }
                                    </Box>
                                )
                                :
                                <Typography variant="body2">No Guests found</Typography>
                    }
                </Box>
            </Box>
            {
                (userIsAdmin || userIsManager)
                    ?
                    <Box gap={1}>
                        <IconButton onClick={onEdit} size="small">
                            <EditIcon/>
                        </IconButton>
                        {/*<IconButton onClick={onDelete} size="small">*/}
                        {/*    <DeleteIcon/>*/}
                        {/*</IconButton>*/}
                    </Box>
                    :
                    null
            }
        </CardContainer>
    );
};

const CardContainer = styled('div')(() => ({
  background: 'linear-gradient(0deg, rgba(0, 44, 119, 0.08), rgba(0, 44, 119, 0.08)), #FFFFFF',
  borderRadius: '4px',
  padding: '12px',
  display: 'flex',
  gap: '12px',
}));