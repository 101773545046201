import React, {FC, useEffect, useState} from "react";
import {Box, Button, Container} from "@mui/material";

import {CreateEventInput, Program} from "../../types";
import {CreateEventModal} from "../Modals";
import {useAddEventMutation} from "../../services";

interface PlacementEventActionPanelProps {
    program: Program;
}

export const PlacementEventActionPanel: FC<PlacementEventActionPanelProps> = ({program}) => {
    const [showAddEventModal, setShowAddEventModal] = useState(false);
    const [addEvent, {isSuccess: addEventSuccess, isLoading: addEventLoading}] = useAddEventMutation();

    const handleCreateEvent = async (data: CreateEventInput) => {
        addEvent(data);
    };

    useEffect(() => {
        if (addEventSuccess) {
            setShowAddEventModal(false);
        }
    }, [addEventSuccess]);

    return (
        <Container disableGutters>
            <Box sx={{display: 'flex', pb: 3}}>
                <Button
                    variant="outlined"
                    size="large"
                    type="button"
                    onClick={() => setShowAddEventModal(true)}
                >
                    ADD AN EVENT
                </Button>
            </Box>

            {/* Add new Event Modal */}
            <CreateEventModal
                open={showAddEventModal}
                onClose={() => setShowAddEventModal(false)}
                onCreateEvent={handleCreateEvent}
                loading={addEventLoading}
                createEventSuccess={addEventSuccess}
                program={program}
            />

        </Container>
    )
}