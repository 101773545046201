import React, {useEffect, useState} from 'react';
import { Moment } from 'moment';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import './DateRangePicker.scss'
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';


export interface IDatePickerDates {
    startDate: Moment | null, 
    endDate: Moment | null,
}

export interface IDateRangePicker {
    startDate: Moment | null
    endDate: Moment | null
    onDatesChange: (arg: IDatePickerDates) => void;
}

export default function DateRangePicker(props: IDateRangePicker) {
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>('startDate')
    const [startDate, setStartDate] = useState<Moment | null>(props.startDate)
    const [endDate, setEndDate] = useState<Moment | null>(props.endDate)

    const handleDatesChange = (arg: { startDate: Moment | null, endDate: Moment | null }) => {
        setStartDate(arg.startDate);
        setEndDate(arg.endDate);
        if(arg.startDate && arg.endDate) {
            props.onDatesChange(arg);
        }
    };

    const handleFocusChange = (arg: FocusedInputShape | null) => {
        setFocusedInput(arg || 'startDate')
    }

    return (
        <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            initialVisibleMonth={null}
            numberOfMonths={2}
        />
    );
}