
import React, {FC, useState} from "react";
import {Box, Button, Container} from "@mui/material";

import {Program} from "../../types";

interface PlacementTeamActionPanelProps {
    program: Program;
}

export const PlacementTeamActionPanel: FC<PlacementTeamActionPanelProps> = ({program}) => {
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);

    return (
        <Container disableGutters>
            <Box sx={{display: 'flex', pb: 3}}>
                <Button
                    variant="outlined"
                    size="large"
                    type="button"
                    onClick={() => setShowAddMemberModal(true)}
                >
                    ADD A TEAM MEMBER
                </Button>
            </Box>
        </Container>
    )
}