import React, { useEffect } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from 'moment';
import {LoadingButton, LocalizationProvider, TimePicker, DesktopDatePicker} from "@mui/lab";
import {
    Autocomplete, Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";

import {useAuthContext} from "../../contexts/AuthContext";
import {CreateEventInput, IEvent, Program} from "../../types";
import {useGetUserListQuery} from "../../services";

interface CreateEventModalProps {
    open: boolean;
    onClose: () => void;
    onCreateEvent: (values: CreateEventInput) => void;
    loading: boolean;
    createEventSuccess: boolean;
    isEditMode?: boolean;
    event?: IEvent | null;
    program: Program;
}

export const CreateEventModal: React.FC<CreateEventModalProps> = ({
    open,
    onClose,
    onCreateEvent,
    loading,
    createEventSuccess,
    isEditMode = false,
    event,
    program
}) => {
    const {user} = useAuthContext();

    const {data: userList} = useGetUserListQuery({});

    const createEventSchema = yup.object().shape({
        name: yup.string().required(),
        date: yup.string().required(),
        start_time: yup.string().required(),
        end_time: yup.string().required(),
        allDayEvent: yup.boolean(),
        attendees: yup.array().of(yup.number()),
    });

    const createEventForm = useFormik({
        initialValues: {
            name: '',
            date: new Date(),
            start_time: new Date(new Date().setMinutes(new Date().getMinutes() + 15)),
            end_time: new Date(new Date().setHours(new Date().getHours() + 1, new Date().getMinutes() + 15)),
            allDayEvent: false,
            attendees: user ? [user.id] : []
        },
        validationSchema: createEventSchema,
        onSubmit: (values) => {
            handleCreateEvent(values);
        }
    });

    const handleCreateEvent = (values: any) => {
        if (user) {
            const data: CreateEventInput = {
                id: event?.id,
                name: values.name,
                date: moment(new Date()).format('YYYY-MM-DD'),
                start_time: values.start_time,
                end_time: values.end_time,
                program_id: program.id,
                organizator_id: user.id,
                attendees: [...values.attendees].filter(id => id !== user.id),
            };

            onCreateEvent(data);
        }
    };

    const {values, handleSubmit, handleChange, setFieldValue, isValid, dirty, resetForm} = createEventForm;

    useEffect(() => {
        if (createEventSuccess) {
            resetForm();
        }
        /* eslint-disable-next-line */
    }, [createEventSuccess]);

    useEffect(() => {
        if (isEditMode && event) {
            // FIXME:
            const attendees = event?.attendees ? event.attendees :[];

            setFieldValue('name', event.name);
            setFieldValue('date', new Date(event.date));
            setFieldValue('start_time', new Date(event.start_time));
            setFieldValue('end_time', new Date(event.end_time));
            setFieldValue('attendees', [event.organizator_id, ...attendees]);
        }

        /* eslint-disable-next-line */
    }, [isEditMode, event]);


    // Event Day Change
    const handleDayChange = (newDate: any) => {
        setFieldValue('date', newDate);
        setFieldValue('start_time', new Date(new Date(newDate).setMinutes(new Date(newDate).getMinutes() + 15, 0, 0)));
        setFieldValue('end_time', new Date(new Date(newDate).setHours(
            new Date(newDate).getHours() + 1,
            new Date(newDate).getMinutes() + 15
        )));
    }

    // Select all day event
    const handleAllDayChange = (e: any) => {
        handleChange(e);
        if (values.date) {
            setFieldValue('start_time', new Date(new Date(values.date).setHours(0, 0, 0, 0)));
            setFieldValue('end_time', new Date(new Date(values.date).setHours(24, 0, 0, 0)));
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={onClose}>
            <DialogTitle color={'text.primary'}>
                {`${isEditMode ? 'Edit' : 'New'} event`}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Container disableGutters>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, py: 2}}>
                                {/* Event Name */}
                                <FormControl>
                                    <TextField
                                        fullWidth
                                        placeholder="Add Event Title"
                                        name={'name'}
                                        value={values.name}
                                        onChange={handleChange}
                                        variant="standard"
                                    />
                                </FormControl>

                                {/* Event Date */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={values.date}
                                        onChange={handleDayChange}
                                        renderInput={(params) => <TextField variant="standard" {...params} />}
                                    />

                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        flexDirection: {xs: 'column', md: 'row'},
                                        gap: 2
                                    }}>
                                        <TimePicker
                                            disabled={values.allDayEvent}
                                            label="Start Time"
                                            value={values.start_time}
                                            onChange={(newValue) => {
                                                setFieldValue('start_time', newValue)
                                            }}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                        />

                                        <TimePicker
                                            disabled={values.allDayEvent}
                                            label="End Time"
                                            minTime={values.start_time}
                                            value={values.end_time}
                                            onChange={(newValue) => {
                                                setFieldValue('end_time', newValue)
                                            }}
                                            renderInput={(params) => <TextField variant="standard" {...params} />}
                                        />

                                        <FormControl sx={{width: {xs: 'auto', md: 240}}}>
                                            <FormControlLabel
                                                disabled={!values.date}
                                                sx={{whiteSpace: 'no-wrap'}}
                                                control={<Checkbox />}
                                                label="All Day Event"
                                                name={'allDayEvent'}
                                                value={values.allDayEvent}
                                                onChange={handleAllDayChange}
                                            />
                                        </FormControl>

                                    </Box>
                                </LocalizationProvider>


                                {/* Attendees */}
                                <FormControl>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        disableClearable
                                        options={userList?.data?.map(u => +u.id) || []}
                                        renderOption={(props, option) => {
                                            let user = userList?.data?.find(u => u.id === option);
                                            if (!user) return null;
                                            return (
                                                <Box component="li" {...props}>
                                                    <Avatar sx={{width: '32px', height: '32px', mr: 2, flexShrink: 0}}>
                                                        {user ? user.name.slice(0, 1) : ''}
                                                    </Avatar>
                                                    {user?.name || ''} &nbsp; CEO &nbsp; {user.email}
                                                </Box>
                                            )
                                        }}
                                        value={values.attendees}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                            />
                                        )}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    label={`${userList?.data.find(u => u.id === option)?.name || '-'} ${
                                                        user?.id === option ? '(organizer)' : ''
                                                    }` || ''}
                                                    {...getTagProps({ index })}
                                                    onDelete={user?.id === option ? undefined : () => {
                                                        setFieldValue('attendees', values.attendees.filter(id => id !== option))
                                                    }}
                                                />
                                            ))
                                        }
                                        onChange={(event, value) => {
                                            setFieldValue('attendees', value);
                                        }}
                                    />
                                    <Typography variant="caption" color="text.secondary">Invitees</Typography>
                                </FormControl>

                            </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>Cancel</Button>

                    <LoadingButton
                        color="primary"
                        loading={loading}
                        disabled={!isValid || !dirty}
                        type='submit'
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};