import React, {FC, useMemo} from 'react';

import {
    TaskType,
    taskTypeList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskTypeFilterBarProps {
    selectedValues?: TaskType[];
    onApply: (selectedItems: IOption[]) => void;
    onReset: () => void;
}

export const TaskTypeFilterBar: FC<TaskTypeFilterBarProps> = ({
  selectedValues,
  onApply,
  onReset,
}) => {
    const typesFilterOptions = useMemo<IOption[]>(() => {
        const typesList = Object.keys(taskTypeList) as TaskType[];
        if (typesList.length) {
            return typesList.map(type => ({
                label: taskTypeList[type]?.label,
                value: taskTypeList[type]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"TASK TYPE"}
            selectedValues={selectedValues || []}
            options={typesFilterOptions}
            onApply={onApply}
            onReset={onReset}
            withSearch={false}
            searchPlaceholder={'Search for a Type'}
        />
    );
};