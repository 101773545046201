import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
// import { toast } from "react-toastify";
import store2 from 'store2';
import { StorageKey, BASE_URL } from "./consts";
import { apiLogger } from "./logger";

// export const BASE_URL = "https://gcweaver.alphawaves.io:/app/";
// export const BASE_URL = "https://lilitoys.ru/";

export const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    }
});

instance.interceptors.response.use((response) => response, (error) => {
    const message = error?.response?.data?.detail || 'Server error';
    apiLogger.error(message);
    // toast.error(message);
    // throw error;
});

export const API = {
    get: <T = any, R = AxiosResponse<T>>(url = '', params: AxiosRequestConfig<any> = {}, additionalHeaders: AxiosRequestHeaders = {}): Promise<R> => {
        const oktaStorage = store2.get(StorageKey.OktaStorage);
        const oktaToken = oktaStorage?.accessToken.accessToken || '';
        return instance.get(url, { 
            ...params, 
            headers: {
                "Authorization": `Bearer ${oktaToken}`,
                ...additionalHeaders
            }
        });
    },
    post: <T = any, R = AxiosResponse<T>>(url = '', data = {}, params: AxiosRequestConfig = {}, additionalHeaders: AxiosRequestHeaders = {}): Promise<R> => {
        const oktaStorage = store2.get(StorageKey.OktaStorage);
        const oktaToken = oktaStorage?.accessToken.accessToken || '';
        return  instance.post(url, data,  { 
            
            ...params, 
            headers: {
                "Authorization": `Bearer ${oktaToken}`,
                ...additionalHeaders
            }
        });
    },
    put: <T = any, R = AxiosResponse<T>>(url = '', data = {}, params: AxiosRequestConfig = {}, additionalHeaders: AxiosRequestHeaders = {}): Promise<R> => {
        const oktaStorage = store2.get(StorageKey.OktaStorage);
        const oktaToken = oktaStorage?.accessToken.accessToken || '';
        return  instance.put(url, data,  { 
            ...params, 
            headers: {
                "Authorization": `Bearer ${oktaToken}`,
                ...additionalHeaders
            }
        });
    },
    delete: <T = any, R = AxiosResponse<T>>(url = '', params: AxiosRequestConfig = {}, additionalHeaders: AxiosRequestHeaders = {}): Promise<R> => {
        const oktaStorage = store2.get(StorageKey.OktaStorage);
        const oktaToken = oktaStorage?.accessToken.accessToken || '';
        return instance.delete(url, { 
            ...params, 
            headers: {
                "Authorization": `Bearer ${oktaToken}`,
                ...additionalHeaders
            }
        });
    },
    download: (url = '', params: AxiosRequestConfig = {}, additionalHeaders: AxiosRequestHeaders = {}) => {
        const oktaStorage = store2.get(StorageKey.OktaStorage);
        const oktaToken = oktaStorage?.accessToken.accessToken || '';
        return instance.get(url, { 
            ...params, 
            headers: {
                "Authorization": `Bearer ${oktaToken}`,
                ...additionalHeaders
            }
        });
    },
};
