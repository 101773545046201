import React, {FC, useMemo} from 'react';

import {ActivitiesFilters, ActivityType, activityTypeList} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface ActivityTypeFilterBarProps {
    filters: ActivitiesFilters;
    setFilters: (filters: ActivitiesFilters) => void;
}

export const ActivityTypeFilterBar: FC<ActivityTypeFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const typeFilterOptions = useMemo<IOption[]>(() => {
        const typesList = Object.keys(activityTypeList);
        if (typesList.length) {
            return [
                {
                    label: activityTypeList.message.label,
                    value: activityTypeList.message.value,
                },
                {
                    label: activityTypeList.file_upload.label,
                    value: activityTypeList.file_upload.value,
                },
                {
                    label: 'Updates',
                    value: typesList.filter(type => !(type === ActivityType.Message || type === ActivityType.FileUpload)).join(','),
                },
            ]
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Type"}
            selectedValues={filters.types}
            options={typeFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, types: selectedItems.map(item => item.value.toString() as ActivityType)})
            }}
            onReset={() => setFilters({...filters, types: []})}
            searchPlaceholder={'Search for a type'}
        />
    );
};