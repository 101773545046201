import React from "react";
import {styled} from "@mui/material/styles";

interface SelectProps {
    className?: string;
    value: string;
    name?: string;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    children: React.ReactNode;
    textColor?: string;
}

export const Select = ({
    className = '',
    value,
    name = '',
    placeholder = '',
    onChange,
    children,
    textColor,
}: SelectProps) => {

    return (
        <BaseSelect
            className={className}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            color={textColor}
        >
            {children}
        </BaseSelect>
    );
};

export const BaseSelect = styled('select')(({theme}) => ({color: textColor}) => ({
    maxWidth: '100%',
    padding: '4px',
    cursor: 'pointer',
    outline: 'none',
    color: textColor ? textColor : theme.palette.text.secondary,
}));

export const Option = styled('option')(({theme}) => ({color}) => ({
    color: color ? color : theme.palette.text.secondary,
}));

