import React from "react";
import {TableCell, TableContainer, TableHead, TableBody} from "@mui/material";

import {Task} from "../../../types";
import {RowItem} from "./RowItem";
import {StyledTable, StyledHeadRow} from "../components";

interface TasksTableProps {
    data: Task[];
}

export const TasksTable: React.FC<TasksTableProps> = ({data}) => {

    return (
        <TableContainer>
            <StyledTable>
                <TableHead>
                    <StyledHeadRow>
                        <TableCell>Task Name</TableCell>
                        <TableCell>Task Type</TableCell>
                        <TableCell>Placement</TableCell>
                        <TableCell>Target Due Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell />
                    </StyledHeadRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((task) => (
                            <RowItem key={`task-item-${task.id}`} task={task}/>
                        ))
                    }
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
};
