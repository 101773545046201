import React from "react";
import {useParams} from "react-router-dom";
import {Container} from "@mui/material";

import {MainLayout} from "../../../layouts";
import {PageLoader} from "../../../components/UI";
import {useAuthContext} from "../../../contexts/AuthContext";
import {useGetEventListQuery, useGetProgramByIdQuery} from "../../../services";
import {PlacementHeading} from "../../../components/PlacementHeading";
import {PlacementEventsTimeline} from "../../../components/PlacementEventsTimeline";
import {PlacementEventActionPanel} from "../../../components/PlacementActionPanels";

export const PlacementEventsPage: React.FC = () => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const {placementId} = useParams<{ placementId: string }>();
    const {data: programData} = useGetProgramByIdQuery(+placementId);
    const {data: eventList, isLoading: getEventListLoading} = useGetEventListQuery({program_id: +placementId});

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{display: 'flex', flexDirection: 'column', margin: '32px auto'}}
            >
                {
                    programData
                        ?
                        <>
                            <PlacementHeading placement={programData} />
                            {(userIsAdmin || userIsManager) && <PlacementEventActionPanel program={programData} />}

                            {
                                   (programData && eventList)
                                    ?
                                    <PlacementEventsTimeline
                                        programData={programData}
                                        events={eventList.data}
                                        getEventListLoading={getEventListLoading}
                                    />
                                    :
                                    <PageLoader />
                            }
                        </>
                        :
                        <PageLoader/>
                }
            </Container>

        </MainLayout>
    );
};