import React, {FC, useMemo} from 'react';

import {
    ProgramFilters,
    programYearsList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface ProgramYearFilterBarProps {
    filters: ProgramFilters;
    setFilters: (filters: ProgramFilters) => void;
}

export const ProgramYearFilterBar: FC<ProgramYearFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const yearFilterOptions = useMemo<IOption[]>(() => {
        if (programYearsList.length) {
            return programYearsList.map(year => ({
                label: year,
                value: year,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Year"}
            selectedValues={filters.years}
            options={yearFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, years: selectedItems.map(item => item.value.toString())})
            }}
            onReset={() => setFilters({...filters, years: []})}
            searchPlaceholder={'Search for a Year'}
        />
    );
};