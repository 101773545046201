import React, {FC, MouseEvent, useMemo} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import {Routes} from "../../../consts";
import {useNavigation} from "../../../hooks";

export const DashboardNavigation: FC = () => {
    const location = useLocation();
    const {navigate} = useNavigation();

    const tabsData = useMemo(() => [
        {
            order: 1,
            label: 'DASHBOARD',
            to: Routes.home,
            value: Routes.home,
        },
        {
            order: 2,
            label: 'MY PLACEMENTS',
            to: Routes.placements,
            value: Routes.placements,
        },
        {
            order: 3,
            label: 'MY TASKS',
            to: Routes.tasks,
            value: Routes.tasks,
        }
    ], []);

    const handleNavigate = (route: string) => {
        navigate(route);
    };

    const activeTabValue = useMemo(() => {
        switch (location.pathname) {
            case Routes.home:
                return Routes.home;
            case Routes.placements:
                return Routes.placements;
            case Routes.tasks:
                return Routes.tasks;
            default:
                return false
        }
    }, [location.pathname]);

    return (
        <Box sx={{display: {xs: 'none', md: 'flex'}, paddingBottom: 6}}>
            <Tabs
                value={activeTabValue}
                onChange={(e, value) => handleNavigate(value)}
                indicatorColor="secondary"
                textColor="inherit"
            >
                {
                    tabsData.map(tabItem => (
                        <Tab
                            key={`item-${tabItem.to}`}
                            component={NavLink}
                            label={tabItem.label}
                            value={tabItem.value}
                            to={tabItem.to}
                            onClick={(event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement>) => {
                                event.preventDefault();
                            }}
                        />
                    ))
                }
            </Tabs>
        </Box>
    )
}