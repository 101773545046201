import fileDownload from 'js-file-download';
import { API } from '../API';

export const download = async (url: string, fileName: string) => {
    try {
        const res = await API.get(url, {responseType: 'blob'});
        fileDownload(res.data, fileName);
    } catch (err) {

    }
};