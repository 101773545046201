import React, {FC} from 'react';
import {NavLink} from "react-router-dom";
import moment from "moment";
import {TableCell, TableRow} from "@mui/material";
import {styled} from "@mui/material/styles";

import {Task, taskStatusList, taskTypeList} from "../../../../types";
import {Routes} from "../../../../consts";
import {getTaskStatusColor} from "../../../../utils";


interface RowItemProps {
    task: Task;
}

export const RowItem: FC<RowItemProps> = ({task}) => {
    return (
        <TableRow>
            <TableCell>{task.name}</TableCell>
            <TableCell>{taskTypeList[task.type]?.label}</TableCell>
            <TableCell>{task.program?.name || '-'}</TableCell>
            <TableCell>
                {task.target_date ? moment(task?.target_date).format(('MM/DD/YYYY')) : ''}
            </TableCell>
            <TableCell sx={{color: getTaskStatusColor(task)}}>
                {taskStatusList[task.status]?.label || "-"}
            </TableCell>
            <TableCell align="right">
                <TableLink to={Routes.placementTaskDetails(task.program_id, task.id)}>
                    {'Go to task >'}
               </TableLink>
            </TableCell>
        </TableRow>
    );
};

const TableLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.main
}));