import React, {useMemo} from "react";
import {TableHead, TableBody, TableCell, TableContainer} from "@mui/material";
import {
    Program,
    ProgramOrderBy,
    ProgramSortParams,
} from "../../../types";
import {TableSortButton} from "../../UI";
import {RowItem} from "./RowItem";
import {StyledTable, StyledHeadRow} from "../components";

interface TableProps {
    data: Program[];
    orderBy: ProgramSortParams;
    onOrderByChange: (orderBy: ProgramSortParams) => void;
    showClientColumn: boolean;
}

export const PlacementsTable: React.FC<TableProps> = ({
  data,
  orderBy,
  onOrderByChange,
  showClientColumn
}) => {
    const handleOrderByChange = (value: ProgramOrderBy) => {
        if (orderBy.value === value) {
            // Unsort
            if (orderBy.direction === 'desc') {
                onOrderByChange({
                    value: 'id',
                    direction: 'asc'
                })
                return;
            }

            onOrderByChange({
                value: value,
                direction: orderBy.direction === 'asc' ? 'desc' : 'asc'
            })
            return;
        }

        onOrderByChange({
            value: value,
            direction: 'asc'
        })
    };

    const columns = useMemo(() => [
        {label: 'Placement Name', key: 'name', visible: true},
        {label: 'Client', key: 'client', visible: showClientColumn},
        {label: 'Type', key: 'type', visible: true},
        {label: 'LOB', key: 'class_of_business', visible: true},
        {label: 'Year', key: 'year', visible: true},
        {label: 'Updated', key: 'updated_at', visible: true},
        {label: 'Effective Date', key: 'target_date', visible: true},
        {label: 'Status', key: 'status', visible: true},
        {label: '', key: '', visible: true},

    ], [showClientColumn]);

    return (
        <TableContainer sx={{marginTop: 5}}>
            <StyledTable>
                <TableHead>
                    <StyledHeadRow>
                        {
                            columns.map((column, i) => {
                                if (!column.visible) return null

                                return (
                                    <TableCell key={`column-item-${i}`}>
                                        <span>{column.label}</span>
                                        {
                                            !!column.key && (
                                                <TableSortButton
                                                    onClick={() => handleOrderByChange(column.key as ProgramOrderBy)}
                                                    active={orderBy.value === column.key}
                                                    orderBy={orderBy}
                                                    value={column.key}
                                                />
                                            )
                                        }
                                    </TableCell>
                                )
                            })
                        }
                    </StyledHeadRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((program) => (
                            <RowItem
                                key={`program-item-${program.id}`}
                                program={program}
                                showClientColumn={showClientColumn}
                            />
                        ))
                    }
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
};
