import {FC} from "react";
import moment from "moment";
import {Avatar, Box, IconButton, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Images} from "../../../environment";

interface FileCardProps {
    file: File
}
export const FileCard: FC<FileCardProps> = ({file}) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, width: '100%'}}>
            <Avatar src={Images.PDF} sx={{width: 40, height: 40}} />
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="body1">{file.name}</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2, width: '100%'}}>
                    <Typography variant="caption" color="text.secondary">
                        {file.type}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {/* 1024 * 1024 */}
                        {`${(file.size / 1048576).toFixed(2)}MB`}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {moment().format('DD MMM YYYY')}
                    </Typography>
                </Box>
            </Box>
            <IconButton size="small">
                <MoreVertIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}