export const Images = {
    Avatar: '/images/avatar.png',
    Avatar2: '/images/av6.png',
    Avatar3: '/images/av4.png',
    Avatar4: '/images/avatar5.png',
    MainLogo: '/images/logo.png',
    Img1: '/images/pic1.png',
    Lider1: '/images/lider1.png',
    Lider2: '/images/lider2.png',
    Lider3: '/images/lider3.png',
    PDF: '/images/pdf.jpg'
}