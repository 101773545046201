import React from "react";
import {
    Box,
    Container,
    Grid,
    Typography
} from "@mui/material";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";

import {MainLayout} from "../../layouts";
import {useAuthContext} from "../../contexts/AuthContext";
import {PlacementsInfoCard, TasksInfoCard, EventsInfoCard} from "../../components/Dashboard";
import {useGetDashboardQuery} from "../../services";
import {DashboardNavigation} from "../../components/UI";

export const HomePage: React.FC = () => {
    const {user} = useAuthContext();
    const {data: dashboardData, isLoading: getDashboardDataLoading} = useGetDashboardQuery({});

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{display: 'flex', flexDirection: 'column', paddingTop: 4, paddingBottom: 4}}
            >
                {/* Internal Navigation*/}
                <DashboardNavigation />

                <Box sx={{display: 'flex', flexDirection: 'column', mb: 5}}>
                    <Typography variant="h4" fontWeight={700} mb={3}>Dashboard</Typography>
                    {
                        user
                        ? (
                                <Typography variant="h5" color="text.primary" fontWeight={500}>
                                    Good morning, {user.name}!
                                </Typography>
                            )
                        : <Skeleton width={180} height={32} />
                    }
                    <Typography variant="body1">{moment().format('dddd, MMM YY')}</Typography>
                </Box>

                <Grid container spacing={3} display="flex">
                    <Grid item xs={12} md={4} display="flex">
                        <PlacementsInfoCard data={dashboardData} loading={getDashboardDataLoading} />
                    </Grid>

                    <Grid item xs={12} md={4} display="flex">
                        <TasksInfoCard data={dashboardData} loading={getDashboardDataLoading} />
                    </Grid>


                    <Grid item xs={12} md={4} display="flex">
                        <EventsInfoCard data={dashboardData} loading={getDashboardDataLoading}  />
                    </Grid>
                </Grid>
            </Container>
        </MainLayout>
    );
};




