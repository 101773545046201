import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {AlertColor} from "@mui/material";

export enum AlertSuccessModel {
    None = 'none',
    Task = 'task',
    Program = 'program'
}

interface AlertLinkItem {label: string, path: string}

export interface AlertState {
    isOpen: boolean;
    title: string;
    color: AlertColor | undefined;
    severity: AlertColor | undefined;
    variant: "filled" | "standard" | "outlined" | undefined;
    duration: number;
    direction: 'left' | 'up' | 'right' | 'down';
    successModel: AlertSuccessModel;
    linkItems: AlertLinkItem[];
}

const initialState: AlertState = {
    isOpen: false,
    title: '',
    color: 'success',
    severity: 'success',
    variant: 'filled',
    duration: 7000,
    direction: 'right',
    successModel: AlertSuccessModel.None,
    linkItems: [],
}

interface OpenAlertState {
    title: string;
    color?: AlertColor
    severity?: AlertColor;
    variant?: "filled" | "standard" | "outlined";
    duration?: number;
    direction?: 'left' | 'up' | 'right' | 'down';
    successModel?: AlertSuccessModel;
    linkItems?: AlertLinkItem[];
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        openAlert: (state, {payload}: PayloadAction<OpenAlertState>) => {
            state.isOpen = true;
            state.title = payload.title;
            state.color = payload.color || 'success';
            state.severity = payload.severity || 'success';
            state.variant = payload.variant || 'filled';
            state.duration = payload.duration || 5000;
            state.direction = payload.direction || 'right';
            state.successModel = payload.successModel || AlertSuccessModel.None;
            state.linkItems = payload.linkItems || [];
        },
        closeAlert: (state) => {
            state.isOpen = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { openAlert, closeAlert } = alertSlice.actions

export default alertSlice.reducer