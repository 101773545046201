import React, {FC, useMemo, useState} from "react";
import {Box, Button, ButtonGroup, Checkbox, Container, FormControlLabel, Menu, MenuItem,} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {StatusTimeInfo} from "../UI";
import {Program, TaskFilters, TaskType} from "../../types";
import {useAuthContext} from "../../contexts/AuthContext";
import {CreateTaskModal} from "../Modals/CreateTaskModal";
import {
    TaskAssigneeFilterBar,
    TaskDeliverableTypeFilterBar,
    TaskOwnerFilterBar,
    TaskPhaseFilterBar,
    TaskStatusFilterBar,
    TaskTargetDateFilterBar
} from "../TasksFilterBars";

interface PlacementTaskActionPanelProps {
    program: Program;
    createTaskType: TaskType;
    showArchived: boolean;
    setShowArchived: (show: boolean) => void;
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const PlacementTaskActionPanel: FC<PlacementTaskActionPanelProps> = ({
    program,
    createTaskType,
    showArchived,
    setShowArchived,
    filters,
    setFilters
}) => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const addButtonTitle = useMemo<string>(() => {
        if (createTaskType === TaskType.Approval) {
            return `ADD AN ${createTaskType}`
        }
        return `ADD A ${createTaskType}`
    }, [createTaskType])

    return (
        <Container disableGutters>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                pb: 3,
                width: '100%',
                boxSizing: 'border-box'
            }}>
                {
                    (userIsAdmin || userIsManager) &&
                    <Box sx={{
                        display: 'flex',
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        gap: 2,
                        mb: 3
                    }}>

                        <ButtonGroup variant="outlined">
                            <Button size="large" variant="outlined" onClick={() => setShowAddTaskModal(true)}>
                                {addButtonTitle}
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </ButtonGroup>
                        <Menu
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem onClick={() => setAnchorEl(null)}>
                                {`Select standard ${createTaskType}`}
                            </MenuItem>
                            <MenuItem onClick={() => setAnchorEl(null)}>
                                {`Custom ${createTaskType}`}
                            </MenuItem>
                        </Menu>

                        {/* Archive Checkbox*/}
                        <FormControlLabel
                            control={<Checkbox
                                checked={showArchived}
                                onChange={(event) => setShowArchived(event.target.checked)}
                            />}
                            label="Show archived"
                        />
                    </Box>
                }

                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    alignItems: {xs: 'flex-start', md: 'center'},
                    justifyContext: {xs: 'flex-start', md: 'space-between'},
                    width: '100%',
                    pb: 3,
                    boxSizing: 'border-box',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: {xs: 'flex-start', md: 'center'},
                        gap: 3,
                        marginBottom: {xs: 4, md: 0},
                    }}>

                    {/* Filter By Target Date for Requests and Approvals  */}
                    {
                        (createTaskType === TaskType.Request || createTaskType === TaskType.Approval) &&
                        (filters.targetDate !== undefined) &&
                        (
                            <TaskTargetDateFilterBar filters={filters} setFilters={setFilters} />
                        )
                    }

                    {/* Filter By Deliverable Type for Deliverables  */}
                    {
                        (createTaskType === TaskType.Deliverable) &&
                        (filters.deliverableTypes !== undefined) &&
                        (
                            <TaskDeliverableTypeFilterBar filters={filters} setFilters={setFilters} />
                        )
                    }

                    {/* Filter By Assignee ID for Requests and Approvals  */}
                    {
                        (createTaskType === TaskType.Request || createTaskType === TaskType.Approval) &&
                        (filters.assigneeIds !== undefined) &&
                        (
                            <TaskAssigneeFilterBar filters={filters} setFilters={setFilters} taskType={createTaskType} />
                        )
                    }

                    {/* Filter By Phase for Approvals  */}
                    {
                        (createTaskType === TaskType.Approval) &&
                        (filters.phases !== undefined) &&
                        (
                            <TaskPhaseFilterBar filters={filters} setFilters={setFilters} />
                        )
                    }

                    {/* Filter By status for Requests and Approvals */}
                    {
                        (createTaskType === TaskType.Request || createTaskType === TaskType.Approval) &&
                        (filters.statuses !== undefined) &&
                        (
                            <TaskStatusFilterBar filters={filters} setFilters={setFilters} />
                        )
                    }

                    {/* Filter By Owner for Deliverables  */}
                    {
                        (createTaskType === TaskType.Deliverable) &&
                        (filters.ownerIds !== undefined) &&
                        (
                            <TaskOwnerFilterBar filters={filters} setFilters={setFilters} />
                        )
                    }
                    </Box>

                    <Box sx={{
                        display: 'flex', flex: 1, justifyContent: {xs: 'flex-start', md: 'flex-end', width: '100%'
                    }}}>
                        <StatusTimeInfo/>
                    </Box>
                </Box>

            </Box>

            {/* Add new Task Modal */}
            <CreateTaskModal
                 open={showAddTaskModal}
                 onClose={() => setShowAddTaskModal(false)}
                 parentProgram={program}
                 createTaskType={createTaskType}
            />

        </Container>
    )
}