import React, {useState} from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    FormControl,
    Select,
    MenuItem,
    TextField,
    Box,
    Button,
    InputLabel,
    Container,
    Dialog,
    DialogTitle,
    DialogContent
} from "@mui/material";
import {DatePicker, LoadingButton} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from "moment";
import sortBy from "lodash/sortBy";

import {
    ProgramType,
    programLOBList,
    ProgramLOB,
    programTypeList,
    CreateProgramInput,
} from "../../types";
import {useAuthContext} from "../../contexts/AuthContext";
import {useGetClientListQuery} from "../../services";


interface CreateProgramModalProps {
    open: boolean;
    onClose: () => void;
    onCreateProgram: (values: CreateProgramInput) => void;
    loading: boolean;
}

type AddMode = 'new' | 'renewal';

export const CreateProgramModal: React.FC<CreateProgramModalProps> = ({
  open,
  onClose,
  onCreateProgram,
  loading,
}) => {
    const { client } = useAuthContext();
    const {data: clientsData} = useGetClientListQuery({});
    const [addMode, setAddMode] = useState<AddMode>('new');

    const createProgramSchema = yup.object().shape({
        name: yup.string().required(),
        type: yup.string().required(),
        class_of_business: yup.string().required(),
        start_date: yup.date().required(),
        client_id: yup.number(),
    });

    const createProgramForm = useFormik({
        initialValues: {
            name: '',
            type: '',
            class_of_business: '',
            start_date: new Date(),
            client_id: client?.id,
        },
        validationSchema: createProgramSchema,
        onSubmit: (values) => {
            onCreateProgram({
                ...values,
                //TODO: Check how should be handeled below date fields
                target_date: moment(values.start_date).add(1, 'year').toDate(),
                expiration_date: moment(values.start_date).add(1, 'year').toDate(),
                year: new Date(values.start_date).getFullYear(),
                notes: '',
                next_steps: '',
                is_active: true
            });
        }
    });

    const { values, handleSubmit, handleChange, setFieldValue, touched, errors, handleBlur, isValid } = createProgramForm;

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle color={'text.primary'}>
               New Placement
            </DialogTitle>
            <DialogContent>
                <Container disableGutters>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>

                            {/* Placement Add mode */}
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel>Placement Type *</InputLabel>
                                <Select
                                    disabled
                                    label="Placement Type *"
                                    variant="outlined"
                                    name={'addMode'}
                                    value={addMode}
                                    onChange={(e) => setAddMode(e.target.value as AddMode)}
                                >
                                    <MenuItem value={'new'}>
                                        New Placement
                                    </MenuItem>
                                    <MenuItem value={'renewal'}>
                                        Placement Renewal
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    name={"name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={'Placement Name *'}
                                    placeholder="Placement Name"
                                    color="primary"
                                    variant="outlined"
                                    error={touched.name ? !!errors.name : undefined}
                                    fullWidth
                                />
                            </FormControl>

                            {/* Placement Client */}
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel>Client</InputLabel>
                                <Select
                                    label="Client"
                                    variant="outlined"
                                    name={'client_id'}
                                    value={values.client_id}
                                    onChange={(e) => {
                                        setFieldValue('client_id', +e.target.value)
                                    }}
                                >
                                    {
                                        sortBy(clientsData?.data, ['common_name']).map((client, i) => (
                                            <MenuItem key={`client-item-${i}`} value={client.id}>
                                                {client.common_name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {/* Reinsurance type */}
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel>Reinsurance Type</InputLabel>
                                <Select
                                    variant="outlined"
                                    label="Reinsurance Type"
                                    placeholder="Select Reinsurance Type"
                                    name={'type'}
                                    value={values.type}
                                    onChange={handleChange}
                                >
                                    {
                                        sortBy(Object.keys(programTypeList) as ProgramType[])
                                        .map((item, i) => (
                                            <MenuItem
                                                key={`program-tpe-item-${i}`}
                                                value={programTypeList[item].value}
                                            >
                                                {programTypeList[item].label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {/* Line of Business*/}
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel>Line of Business</InputLabel>
                                <Select
                                    variant="outlined"
                                    label="Line of Business"
                                    placeholder={'Select Line of Business'}
                                    name={'class_of_business'}
                                    value={values.class_of_business}
                                    onChange={handleChange}
                                >
                                    {
                                        sortBy(Object.keys(programLOBList) as ProgramLOB[], (lob) => programLOBList[lob]?.label)
                                        .map((item, i) => (
                                            <MenuItem
                                                key={`program-lob-item-${i}`}
                                                value={programLOBList[item]?.value}
                                            >
                                                {programLOBList[item]?.label}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {/* Renewal Date*/}
                            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Renewal Date"
                                        value={values.start_date}
                                        onChange={(newValue) => {
                                            setFieldValue('start_date', newValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField variant="outlined" {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>


                            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 1}}>
                                <Button onClick={onClose}>
                                    CANCEL
                                </Button>
                                <LoadingButton type='submit' disabled={!isValid} loading={loading}>
                                    ADD NEW PLACEMENT
                                </LoadingButton>
                            </Box>
                        </Box>
                    </form>
                </Container>
            </DialogContent>
        </Dialog>
    );
};