import React from "react";
import {Container, Typography} from "@mui/material";

export const Notifications: React.FC = () => {
    return (
        <Container sx={{display: 'flex', flexDirection: 'column', gap: 2, marginY: 56, marginX: 118}}>
            <Typography variant="caption">
                Notifications
            </Typography>
        </Container>
    );
};
