import React, {useState} from "react";
import {TabContext, TabList} from "@mui/lab";
import {Box, Container, Pagination, Tab, Typography} from "@mui/material";

import {MainLayout} from "../../layouts";
import {TasksTable} from "../../components/Tables/TasksTable";
import {TableEmptyList, TableLoader, StatusTimeInfo, DashboardNavigation} from "../../components/UI";
import {useGetMyTaskListQuery} from "../../services";
import {TaskFilters, TaskStatus, TaskType} from "../../types";
import {TaskStatusFilterBar, TaskTypeFilterBar} from "../../components/TasksFilterBars";
import {useSearchQuery, toPythonISO} from "../../utils";

type TaskPeriod = 'all' | 'today' | 'week';

export const TasksPage = () => {
    const query = useSearchQuery();
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState<TaskPeriod>('all');
    const [myTasksFilters, setMyTasksFilters] = useState<TaskFilters>({
            types: query.get('filterType') ? [query.get('filterType') as TaskType] : [],
            statuses: query.get('filterStatus') ? [query.get('filterStatus') as TaskStatus] : [],
            targetDate: { from: undefined, to: undefined }
        }
    );

    const {data: myTaskListData, isLoading: getMyTaskListLoading} = useGetMyTaskListQuery({
        is_active: true,
        skip: (page - 1) * 10,
        limit: 10,
        type: myTasksFilters.types?.length ? myTasksFilters.types.join(',') : undefined,
        status: myTasksFilters.statuses?.length ? myTasksFilters.statuses.join(',') : undefined,
        gt_target_date: myTasksFilters.targetDate?.from ? toPythonISO(myTasksFilters.targetDate.from) : undefined,
        lt_target_date: myTasksFilters.targetDate?.to ? toPythonISO(myTasksFilters.targetDate.to) : undefined,
    });

    const handleSetFilters = (filters: TaskFilters) => {
        setMyTasksFilters(filters);
        setPage(1)
    }

    const handleSetActiveTab = (value: TaskPeriod) => {
        // TODO: Make correct range
        switch (value) {
            case 'today':
                handleSetFilters({
                    ...myTasksFilters,
                    targetDate: {
                        from: new Date(new Date().setHours(0)),
                        to: new Date(new Date().setHours(24))
                    }
                })
                break;
            case 'week':
                handleSetFilters({
                    ...myTasksFilters,
                    targetDate: {
                        from: new Date(new Date().setHours(0)),
                        to: new Date(new Date().setHours(168)),
                    }
                })
                break;
            case 'all':
                handleSetFilters({
                    ...myTasksFilters,
                    targetDate: {
                        from: undefined,
                        to: undefined,
                    }
                })
                break
            default:
        }

        setActiveTab(value)
    }

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingY: 4
                }}
            >

                {/* Internal Navigation*/}
                <DashboardNavigation />

                <Typography variant="h4" fontWeight={700} mb={4}>
                    My Tasks
                </Typography>

                {/* Filters */}
                <Box sx={{display: "flex", flexWrap: 'wrap', gap: 3, mb: 3}}>
                    {/* Type */}
                    <TaskTypeFilterBar
                        selectedValues={myTasksFilters.types}
                        onApply={(selectedItems) => {
                            handleSetFilters({...myTasksFilters, types: selectedItems.map(item => item.value as TaskType)})
                        }}
                        onReset={() => handleSetFilters({...myTasksFilters, types: []})}
                    />
                    {/* Status */}
                    <TaskStatusFilterBar filters={myTasksFilters} setFilters={handleSetFilters} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        mb: 3,
                        paddingBottom: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <TabContext value={activeTab}>
                        <TabList onChange={(e, value) => {handleSetActiveTab(value)}}>
                            <Tab sx={{width: {md: '160px'}, mx: {md: 5}}} label="TODAY" value="today" />
                            <Tab sx={{width: {md: '160px'}, mx: {md: 5}}} label="THIS WEEK" value="week" />
                            <Tab sx={{width: {md: '160px'}, mx: {md: 5}}} label="All" value="all" />
                        </TabList>
                    </TabContext>
                </Box>

                <Box sx={{display: 'flex', justifyContent: {xs: 'flex-start', md: 'flex-end'}, mb: 3}}>
                    <StatusTimeInfo />
                </Box>

                {
                    getMyTaskListLoading
                        ? <TableLoader />
                        : myTaskListData?.data && myTaskListData.data.length > 0
                            ? <TasksTable data={myTaskListData.data} />
                            : <TableEmptyList />
                }

                {/* Pagination */}
                {
                    (myTaskListData && myTaskListData.pages > 1) && (
                        <Box sx={{display: 'flex', justifyContent: 'center'}} mt={3}>
                            <Pagination
                                count={myTaskListData?.pages || 1}
                                page={page}
                                onChange={(e: React.ChangeEvent<unknown>, value: number) => setPage(value)}
                            />
                        </Box>
                    )
                }
            </Container>
        </MainLayout>
    );
};





