type SortType = "ascending" | "descending"


export const sortByDate = <T = any>(list: any[] , key: string , sortType: SortType): T[] => {
    if(list.length > 0) {
        if(sortType === 'ascending') {
            return [...list].sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
        }   else {
            return [...list].sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime());
        }
    } else {
        return list;
    }
};