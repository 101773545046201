export * from './auth';
export * from './program';
export * from './task';
export * from './event';
export * from './activity';
export * from './dashboard';
export * from './template';

export interface Paginated<T = any> {
    pages: number;
    total_count: number;
    data: T[];
}