import React, {FC, useState} from 'react';
import { Grid, Popover } from "@mui/material";
import { Button, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import DateRangePicker, { IDatePickerDates } from '../UI/DateRangePicker';

import { TaskFilters } from "../../types";

interface TaskTargetDateFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
}

export const TaskTargetDateFilterBar: FC<TaskTargetDateFilterBarProps> = ({
    filters,
    setFilters,
}) => {
    const [dateRange, setDateRange] = useState<IDatePickerDates | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        setFilters({
            ...filters,
            targetDate: { from: dateRange?.startDate?.toDate() || null, to: dateRange?.endDate?.toDate() || null }
        });
        handleClose();
    };

    const handleReset = () => {
        setFilters({...filters, targetDate: { from: null, to: null } });
        setDateRange(null);
        handleClose();
    }

    return (
        <Box>
            <Button
                variant="contained"
                size="small"
                type="button"
                onClick={handleShow}
                color="inherit"
                endIcon={<ExpandMoreIcon />}
            >
                {`TARGET DATE ${(filters.targetDate?.from || filters.targetDate?.to) ? `(${filters.targetDate?.from ? moment(filters.targetDate?.from).format('MM/DD/YY') : ''
                } - ${filters.targetDate?.to ? moment(filters.targetDate?.to).format('MM/DD/YY') : ''
                })` : ''}`}
            </Button>

            <Popover
                sx={{ marginTop: 2 }}
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={0} p={0} minWidth={320} maxWidth="min-content" overflow="hidden">
                    <Grid item xs={12}>
                        <DateRangePicker
                            startDate={dateRange?.startDate || null}
                            endDate={dateRange?.endDate || null}
                            onDatesChange={(arg) => setDateRange(arg)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, p: 1 }}>
                            <Button color="secondary" onClick={handleReset} sx={{marginRight: 'auto'}}>RESET</Button>
                            <Button color="secondary" onClick={handleClose}>Cancel</Button>
                            <Button color="secondary" onClick={handleApply}>OK</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
        </Box>
    );
};