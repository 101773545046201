export enum UserRole {
    Admin = 'admin',
    Manager = 'manager',
    User = 'user',
}
export interface User {
    id: number;
    created_at?: Date;
    updated_at?: Date;
    name: string;
    role: UserRole;
    email:	string;
    client_id: number;
}

export interface OktaUser {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    headers: {
        "cache-control": string;
        "content-type": string;
        date: string;
        expires: string;
        pragma: string;
        "x-rate-limit-limit": string;
        "x-rate-limit-remaining": string;
        "x-rate-limit-reset": string;
    };
    locale: string;
    mobilePhone: string;
    name: string;
    preferred_username: string;
    sub: string;
    updated_at: number;
    zoneinfo: string;
}

