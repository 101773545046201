import React, {createContext, useContext, useEffect} from "react";
import {useOktaAuth} from '@okta/okta-react';

import {Client, LoginData, User, UserRole} from "../types";
import {Routes} from "../consts";
import {useNavigation} from "../hooks";
import {useLazyGetClientByIdQuery, useLazyGetUserProfileQuery} from "../services";

interface IAuthContext {
    user: undefined | User;
    client: undefined | Client;
    login: (loginData: LoginData) => void;
    logOut: () => void;
    userIsAdmin: boolean;
    userIsManager: boolean;
    getUserLoading: boolean;
}

const AuthContext = createContext<IAuthContext>({
    user: undefined,
    client: undefined,
    login: () => undefined,
    logOut: () => undefined,
    userIsAdmin: false,
    userIsManager: false,
    getUserLoading: false,
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const useAuthContext = (): IAuthContext => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const { navigate } = useNavigation();
    const {authState, oktaAuth} = useOktaAuth();
    const [getUser, {data: user, isLoading: getUserLoading}] = useLazyGetUserProfileQuery();
    const [getClient, {data: client}] = useLazyGetClientByIdQuery();

    useEffect(() => {
        if (authState?.isAuthenticated) {
            getUser({});
        }

        // eslint-disable-next-line
    }, [authState]);

    useEffect(() => {
        if (user) {
            getClient(user.client_id)
        }
        // eslint-disable-next-line
    }, [user]);

    const login = () => {
        navigate(Routes.placements);
    };

    const logOut = () => {
        if (authState?.isAuthenticated) {
            oktaAuth.signOut();
        } else {
            login();
        }
    };

    return (
        <AuthContext.Provider value={{
            user,
            client,
            login,
            logOut,
            userIsAdmin: user?.role === UserRole.Admin,
            userIsManager: user?.role === UserRole.Manager,
            getUserLoading: getUserLoading,
        }}>
            {children}
        </AuthContext.Provider>
    );
};