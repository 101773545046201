import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Container, Pagination} from "@mui/material";

import {MainLayout} from "../../../layouts";
import {PageLoader} from "../../../components/UI";
import {TaskFilters, TaskSortParams, TaskType} from "../../../types";
import {useGetProgramByIdQuery, useLazyGetTaskListQuery} from "../../../services";
import {PlacementHeading} from "../../../components/PlacementHeading";
import {PlacementsApprovalsTable} from "../../../components/Tables/PlacementsApprovalsTable";
import {PlacementTaskActionPanel} from "../../../components/PlacementActionPanels";
import {toPythonISO} from "../../../utils";

export const PlacementApprovalsPage: React.FC = () => {
    const {placementId} = useParams<{ placementId: string }>();
    const {data: programData} = useGetProgramByIdQuery(+placementId);
    const [getTaskList, {data: taskListData, isLoading: getTaskListLoading}] = useLazyGetTaskListQuery();

    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [orderBy, setOrderBy] = useState<TaskSortParams>({value: 'id', direction: 'asc'});
    const [showArchivedTasks, setShowArchivedTasks] = useState(false);
    const [taskFilters, setTaskFilters] = useState<TaskFilters>({
        targetDate: {
            from: null,
            to: null
        },
        phases: [],
        assigneeIds: [],
        statuses: [],
    });

    useEffect(() => {
        if (programData) {
            getTaskList({
                programId: programData.id,
                type: TaskType.Approval,
                skip: (page - 1) * limit,
                limit: limit,
                is_active: showArchivedTasks ? 2 : 0,
                order_by: `${orderBy.direction === 'asc' ? '' : '-'}${orderBy.value}`,
                gt_target_date: taskFilters.targetDate?.from ? toPythonISO(taskFilters.targetDate?.from) : undefined,
                lt_target_date: taskFilters.targetDate?.to ? toPythonISO(taskFilters.targetDate?.to) : undefined,
                assignee_id: taskFilters.assigneeIds?.length ? taskFilters.assigneeIds?.join(',') : undefined,
                phase: taskFilters.phases?.length ? taskFilters.phases.join(',') : undefined,
                status: taskFilters.statuses?.length ? taskFilters.statuses.join(',') : undefined,
            });
        }
        // eslint-disable-next-line
    }, [programData, orderBy, page, showArchivedTasks, taskFilters]);

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{display: 'flex', flexDirection: 'column', margin: '32px auto'}}
            >
                {
                    programData
                        ?
                        <>
                            <PlacementHeading placement={programData} />
                            <PlacementTaskActionPanel
                                program={programData}
                                createTaskType={TaskType.Approval}
                                showArchived={showArchivedTasks}
                                setShowArchived={setShowArchivedTasks}
                                filters={taskFilters}
                                setFilters={setTaskFilters}
                            />
                            <PlacementsApprovalsTable
                                data={taskListData?.data || []}
                                loading={getTaskListLoading}
                                orderBy={orderBy}
                                onOrderByChange={setOrderBy}
                            />
                        </>
                        :
                        <PageLoader/>
                }
                {/* Pagination */}
                {
                    (taskListData && taskListData.pages > 1) && (
                        <Box sx={{display: 'flex', justifyContent: 'center'}} mt={3}>
                            <Pagination
                                count={taskListData?.pages || 1}
                                page={page}
                                onChange={(e: React.ChangeEvent<unknown>, value: number) => setPage(value)}
                            />
                        </Box>
                    )
                }
            </Container>
        </MainLayout>
    );
};