import {styled} from "@mui/material/styles";
import {Table, TableRow} from "@mui/material";

export const StyledTable = styled(Table)({
    minWidth: '1200px',
    overflow: 'auto'
});

export const StyledHeadRow = styled(TableRow)({
    '& > th': {
        fontWeight: 600,
    }
});
