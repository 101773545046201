import React, {FC, useMemo} from 'react';

import {TaskFilters, TaskType} from "../../types";
import {useGetUserListQuery} from "../../services";
import {IOption, MultiSelectPopover} from "../UI";

interface TaskAssigneeFilterBarProps {
    filters: TaskFilters;
    setFilters: (filters: TaskFilters) => void;
    taskType: TaskType;
}

export const TaskAssigneeFilterBar: FC<TaskAssigneeFilterBarProps> = ({
    filters,
    setFilters,
    taskType
}) => {
    const {data: userList} = useGetUserListQuery({});

    const assigneeFilterOptions = useMemo<IOption[]>(() => {
        if (userList?.data) {
            return userList?.data?.map(user => ({
                label: user.name,
                value: user.id,
            }))
        }
        return [];
    }, [userList]);

    return (
        <MultiSelectPopover
            togglerTitle={taskType === TaskType.Request ? 'Sender' : 'Approver'}
            selectedValues={filters.assigneeIds || []}
            options={assigneeFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, assigneeIds: selectedItems.map(item => +item.value)})
            }}
            onReset={() => setFilters({...filters, assigneeIds: []})}
            searchPlaceholder={`Search for ${taskType === TaskType.Request ? 'a Sender' : 'an Approver'}`}
        />
    );
};