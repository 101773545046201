import React, {FC, useMemo} from 'react';

import {
    TemplateStatus,
    templateStatusList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface TemplateStatusFilterBarProps {
    selectedValues?: TemplateStatus[];
    onApply: (selectedItems: IOption[]) => void;
    onReset: () => void;
}

export const TemplateStatusFilterBar: FC<TemplateStatusFilterBarProps> = ({
  selectedValues,
  onApply,
  onReset,
}) => {
    const statusFilterOptions = useMemo<IOption[]>(() => {
        const statusList = Object.keys(templateStatusList) as TemplateStatus[];
        if (statusList.length) {
            return statusList.map(status => ({
                label: templateStatusList[status]?.label,
                value: templateStatusList[status]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"Status"}
            selectedValues={selectedValues || []}
            options={statusFilterOptions}
            onApply={onApply}
            onReset={onReset}
            searchPlaceholder={'Search for a Status'}
        />
    );
};