import { createApi } from '@reduxjs/toolkit/query/react'

import {Program, Paginated, CreateProgramInput, IProgramQuery} from "../types";
import {Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";



export const programApi = createApi({
    reducerPath: 'programApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['Program'],
    endpoints: (builder) => ({
        getProgramList: builder.query<Paginated<Program>, IProgramQuery>({
            query: (params) => ({
                url: Endpoints.Program.List,
                params
            }),
            providesTags: (result) => providesList(result?.data, 'Program')
        }),
        getProgramById: builder.query<Program, any>({
            query: (id) => ({ url: Endpoints.Program.Details(id) }),
            providesTags: (result, error, id) => [{ type: 'Program', id }],
        }),
        addProgram: builder.mutation<Program, CreateProgramInput>({
            query: (body) => ({
                url: Endpoints.Program.Create,
                method: 'POST',
                body: body
            }),
            invalidatesTags: [{ type: 'Program', id: 'LIST' }],
        }),
        updateProgram: builder.mutation<Program, Partial<Program> & Pick<Program, 'id'>>({
            query: (body) => ({
                url: Endpoints.Program.Update(body.id),
                method: 'PUT',
                body: body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Program', id: arg.id }],
        })
    }),
});

export const { useGetProgramListQuery, useGetProgramByIdQuery, useAddProgramMutation, useUpdateProgramMutation } = programApi