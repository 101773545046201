import { createApi } from '@reduxjs/toolkit/query/react'

import {Endpoints} from "../consts";
import {providesList, staggeredBaseQuery} from "./helpers";
import {Paginated, User} from "../types";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: staggeredBaseQuery,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUserList: builder.query<Paginated<User>, any>({
            query: (params) => ({
                url: Endpoints.User.List,
                params: params
            }),
            providesTags: (result) => providesList(result?.data, 'User')
        }),
        getUserById: builder.query<User, any>({
            query: (id) => ({ url: Endpoints.User.Details(id) }),
            providesTags: (result, error, id) => [{ type: 'User', id }],
        }),
        getUserProfile: builder.query<User, any>({
            query: () => ({ url: Endpoints.User.Profile }),
            providesTags: (result, error, id) => [{ type: 'User', id }],
        }),
        updateUser: builder.mutation<User, any>({
            query: (data) => ({
                url: Endpoints.User.Update(data.userId),
                method: 'PUT',
                body: data.body
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
        })
    }),
});

export const { useGetUserListQuery, useGetUserByIdQuery, useGetUserProfileQuery, useLazyGetUserProfileQuery, useLazyGetUserByIdQuery, useUpdateUserMutation } = userApi