import React, {FC} from "react";
import {Typography, Container} from "@mui/material";
import {
    Template,
} from "../../../types";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {RowItem} from "./RowItem";


interface TableProps {
    data: Template[];
}

export const TemplatesTable: FC<TableProps> = ({
  data,
}) => {
    return (
        <Container disableGutters sx={{marginTop: 5}}>
            <Table sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Task Type</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Information Group</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>LOB</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Template Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Description</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Last Updated</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700}>Status</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontWeight={700} align="right">Actions</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        data.map((template, i) => (
                            <RowItem
                                key={`template-item-${i}`}
                                template={template}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </Container>
    );
};

