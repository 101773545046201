import React, {FC, useMemo} from 'react';

import {
    ProgramLOB,
    programLOBList,
} from "../../types";
import {IOption, MultiSelectPopover} from "../UI";

interface ProgramLobFilterBarProps {
    selectedValues?: ProgramLOB[];
    onApply: (selectedItems: IOption[]) => void;
    onReset: () => void;
}

export const ProgramLobFilterBar: FC<ProgramLobFilterBarProps> = ({
selectedValues,
onApply,
onReset,
}) => {
    const lobFilterOptions = useMemo<IOption[]>(() => {
        const lobsList = Object.keys(programLOBList) as ProgramLOB[];
        if (lobsList.length) {
            return lobsList.map(lob => ({
                label: programLOBList[lob]?.label,
                value: programLOBList[lob]?.value,
            }))
        }
        return [];
    }, []);

    return (
        <MultiSelectPopover
            togglerTitle={"LOB"}
            selectedValues={selectedValues || []}
            options={lobFilterOptions}
            onApply={onApply}
            onReset={onReset}
            searchPlaceholder={'Search for a LOB'}
        />
    );
};