import React, {Suspense} from "react";
import {BrowserRouter, Switch} from "react-router-dom";

import {
    NotificationsPage,
    PlacementsPage,
    PlacementRequestsPage,
    PlacementApprovalsPage,
    PlacementDeliverablesPage,
    PlacementsTeamPage,
    PlacementTaskDetailsPage,
    TasksPage,
    PlacementEventsPage,
    HomePage,
    TemplatesPage,
} from '../pages';
import {PageLoader} from "../components/UI";
import {AuthProvider} from "../contexts/AuthContext";
import {IRoute, RouteWithSubRoutes} from "../components/UI/RouteWithSubRoutes";
import {AlertProvider} from "./AlertProvider";

const NotFoundPage = React.lazy(() => import('../pages/NotFound')); // Lazy-loaded
const LoginCallbackPage = React.lazy(() => import('../pages/auth/LoginCallbackPage')); // Lazy-loaded

export const Router = () => {
    //TODO: Check where is the better place to keep below data
    const routes: IRoute[] = [
        {
            path: "/dashboard",
            component: HomePage,
            secure: true,
            exact: true
        },
        {
            path: "/login",
            component: <></>,
            secure: false,
            exact: true,
            redirect: '/'
        },
        // Placement Routes
        {
            path: "/",
            component: PlacementsPage,
            secure: true,
            exact: true,
        },
        {
            path: "/:placementId/requests",
            component: PlacementRequestsPage,
            secure: true,
            exact: true
        },
        {
            path: "/:placementId/approvals",
            component: PlacementApprovalsPage,
            secure: true,
            exact: true
        },
        {
            path: "/:placementId/deliverables",
            component: PlacementDeliverablesPage,
            secure: true,
            exact: true
        },
        {
            path: "/:placementId/events",
            component: PlacementEventsPage,
            secure: true,
            exact: true
        },
        {
            path: "/:placementId/team",
            component: PlacementsTeamPage,
            secure: true,
            exact: true
        },
        {
            path: "/:placementId/tasks/:taskId",
            component: PlacementTaskDetailsPage,
            secure: true,
            exact: true
        },
        // TODO: Add Permissions Array to Routes
        // Templates Routes
        {
            path: "/templates",
            component: TemplatesPage,
            secure: true,
            exact: true,
        },
        {
            path: "/notifications",
            component: NotificationsPage,
            secure: true,
            exact: true
        },
        {
            path: "/tasks",
            component: TasksPage,
            secure: true,
            exact: true
        },
        {
            path: process.env.REACT_APP_OKTA_CALLBACK_PATH || '',
            component: LoginCallbackPage,
            secure: false,
            exact: false
        },
        {
            path: '*',
            component: NotFoundPage,
            secure: false,
            exact: false
        }
    ];

    return (
        <BrowserRouter>
            <Suspense fallback={<PageLoader/>}>
                <AuthProvider>
                    <Switch>
                        {routes.map((route, i) => (
                            <RouteWithSubRoutes key={`route-item-${i}`} {...route} />
                        ))}
                    </Switch>
                </AuthProvider>
            </Suspense>
            {/* MUI Snackbar Provider  */}
            <AlertProvider />
        </BrowserRouter>
    );
};
