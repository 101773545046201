import React, {useEffect, useMemo, useState} from 'react';
import debounce from 'lodash/debounce';
import {
    Box,
    Button, ButtonGroup, Checkbox,
    Container, FormControlLabel,
    InputAdornment, Menu, MenuItem,
    OutlinedInput, Pagination
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from '@mui/icons-material/Search';

import {MainLayout} from '../../../layouts';
import {CreateProgramModal} from '../../../components/Modals';
import {useAuthContext} from '../../../contexts/AuthContext';
import {PlacementsTable} from '../../../components/Tables/PlacementsTable';
import {DashboardNavigation, StatusTimeInfo, TableEmptyList, TableLoader,} from '../../../components/UI';
import {CreateProgramInput, ProgramFilters, ProgramLOB, ProgramSortParams} from '../../../types';
import {
    ProgramClientFilterBar,
    ProgramEffectiveDateFilterBar,
    ProgramLobFilterBar,
    ProgramStatusFilterBar,
    ProgramTypeFilterBar,
    ProgramYearFilterBar
} from "../../../components/ProgramsFilterBars";
import {useAddProgramMutation, useGetProgramListQuery} from "../../../services";
import {useAppDispatch} from "../../../store/hooks";
import {AlertSuccessModel, openAlert} from "../../../store/alertSlice";
import {CreateTaskModal} from "../../../components/Modals/CreateTaskModal";
import {toPythonISO} from "../../../utils";
import {Routes} from "../../../consts";

export const PlacementsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { userIsManager, userIsAdmin } = useAuthContext();
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [showArchivedPrograms, setShowArchivedPrograms] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [orderBy, setOrderBy] = useState<ProgramSortParams>({
        value: 'id',
        direction: 'desc'
    });
    const [programFilters, setProgramFilters] = useState<ProgramFilters>(
        {
            types: [],
            lobs: [],
            years: [],
            statuses: [],
            effectiveDate: {
                from: null,
                to: null
            },
            clientIds: []
        }
    );

    const {
        data: programsData,
        isLoading: getProgramsLoading,
    } = useGetProgramListQuery({
        name: debouncedSearchValue,
        skip: (page - 1) * limit,
        limit: limit,
        order_by: `${orderBy.direction === 'asc' ? '' : '-'}${orderBy.value}`,
        type: programFilters.types.length ? programFilters.types.join(','): undefined,
        class_of_business: programFilters.lobs.length ? programFilters.lobs.join(',') : undefined,
        status: programFilters.statuses.length ? programFilters.statuses.join(',') : undefined,
        client_id: programFilters.clientIds.length ? programFilters.clientIds.join(',') : undefined,
        gt_target_date: programFilters.effectiveDate.from ? toPythonISO(programFilters.effectiveDate.from) : undefined,
        lt_target_date: programFilters.effectiveDate.to ? toPythonISO(programFilters.effectiveDate.to) : undefined,
        year: programFilters.years.length ? programFilters.years.join(',') : undefined,
        is_active: showArchivedPrograms ? 2 : 0,
        level_deep: 1
    });

    const handleSetFilters = (filters: ProgramFilters) => {
        setProgramFilters(filters);
        setPage(1)
    }

    const [
        addProgram, {isSuccess: addProgramSuccess, isLoading: addProgramLoading, data: addProgramData}
    ] = useAddProgramMutation();
    const [showCreateModal, setShowCreateModal] = useState(false);

    // Create Program
    const handleCreateProgram = (values: CreateProgramInput) => {
        addProgram(values);
    };

    // When Add new Program successed Close Add Program Modal
    useEffect(() => {
        if (addProgramSuccess && addProgramData) {
            setShowCreateModal(false);

            dispatch(openAlert({
                title: 'Success',
                color: 'success',
                successModel: AlertSuccessModel.Program,
                linkItems: [
                    { label: addProgramData?.name, path: Routes.placementRequests(addProgramData.id)}
                ]
            }));
        }
        // eslint-disable-next-line
    }, [addProgramSuccess, addProgramData]);

    // Debounce search value
    const debouncedSearchValueSetter = useMemo(() => {
        return debounce(setDebouncedSearchValue, 500);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        debouncedSearchValueSetter(searchValue);
        // eslint-disable-next-line
    }, [searchValue]);

    return (
        <MainLayout>
            <Container
                disableGutters
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '32px auto',
                }}
            >
                {/* Internal Navigation*/}
                <DashboardNavigation />

                <Box sx={{display: 'flex', alignItems: {md: 'center'}, gap: 2, flexDirection: {xs: 'column', md: 'row'}}}>
                    <OutlinedInput
                        placeholder={'Search for a Placement'}
                        value={searchValue}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setSearchValue(event.target.value)
                        }}
                        endAdornment={(
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        )}
                    />

                    {
                        (userIsAdmin || userIsManager) && (
                            <FormControlLabel
                                control={<Checkbox
                                    checked={showArchivedPrograms}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setShowArchivedPrograms(event.target.checked)
                                    }}
                                />}
                                label="Show archived"
                            />
                        )
                    }

                    {/* Add new Program trigger */}
                    {
                        (userIsAdmin || userIsManager) && (
                            <Box sx={{marginLeft: {md: 'auto'}}}>
                                <ButtonGroup variant="outlined" onClick={(e) => {
                                    setAnchorEl(e.currentTarget)
                                }}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        startIcon={<AddIcon fontSize="small" />}
                                    >
                                       CREATE NEW
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                >
                                <MenuItem onClick={() => {
                                    setAnchorEl(null)
                                    setShowCreateModal(true)
                                }}>
                                    New Placement
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    setAnchorEl(null);
                                    setShowAddTaskModal(true);
                                }}>
                                    New Task
                                </MenuItem>
                                </Menu>
                            </Box>
                        )
                    }
                </Box>

                {/* Filters */}
                <Box sx={{
                    display: "flex",
                    flexWrap: 'wrap',
                    gap: 3,
                    borderBottom: '2px solid',
                    borderColor: 'divider',
                    paddingY: 4,
                }}>
                    {/*  Client Filter  | Only for Brokers*/}
                    {
                        (userIsAdmin || userIsManager) && (
                            <ProgramClientFilterBar filters={programFilters} setFilters={handleSetFilters} />
                        )
                    }
                    {/*Type Filter*/}
                    <ProgramTypeFilterBar filters={programFilters} setFilters={handleSetFilters} />
                    {/*Lob Filter*/}
                    <ProgramLobFilterBar
                        selectedValues={programFilters.lobs}
                        onApply={(selectedItems) => {
                            handleSetFilters({
                                ...programFilters,
                                lobs: selectedItems.map(item => item.value as ProgramLOB)
                            })
                        }}
                        onReset={() => handleSetFilters({...programFilters, lobs: []})}
                    />
                    {/*Year Filter*/}
                    <ProgramYearFilterBar filters={programFilters} setFilters={handleSetFilters} />
                    {/*Date Filter*/}
                    <ProgramEffectiveDateFilterBar filters={programFilters} setFilters={handleSetFilters} />
                    {/*Status Filter*/}
                    <ProgramStatusFilterBar filters={programFilters} setFilters={handleSetFilters} />
                </Box>

                <Box sx={{display: 'flex', px: 4, py: 3}}>
                    <Box sx={{display: 'flex', marginTop: 2.5, flex: 1, justifyContent: {md: 'flex-end'}}}>
                        <StatusTimeInfo/>
                    </Box>
                </Box>

                {/* Placements Table */}
                <Container disableGutters sx={{position: 'relative'}}>
                    {
                        getProgramsLoading
                            ?
                            <TableLoader/>
                            :
                            programsData && programsData.data.length > 0
                                ?
                                <PlacementsTable
                                    data={programsData.data}
                                    orderBy={orderBy}
                                    onOrderByChange={setOrderBy}
                                    showClientColumn={(userIsAdmin || userIsManager)}
                                />
                                :
                                <TableEmptyList />
                    }
                </Container>

                {/* Pagination */}
                {
                    (programsData && programsData.pages > 1) && (
                        <Box sx={{display: 'flex', justifyContent: 'center'}} mt={3}>
                            <Pagination
                                count={programsData?.pages || 1}
                                page={page}
                                onChange={(e: React.ChangeEvent<unknown>, value: number) => setPage(value)}
                            />
                        </Box>
                    )
                }
            </Container>

            {/* Create new Program */}
            <CreateProgramModal
                open={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onCreateProgram={handleCreateProgram}
                loading={addProgramLoading}
            />

            {/* Add new Task Modal */}
            <CreateTaskModal
                open={showAddTaskModal}
                onClose={() => setShowAddTaskModal(false)}
            />

        </MainLayout>
    );
};


