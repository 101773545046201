import React from "react";
import {styled} from "@mui/material/styles";

interface DotProps {
    className?: string;
}


export const Dot = ({className = ''}: DotProps) => {
    return (
        <BaseDot className={className}/>
    )
};

const BaseDot = styled('div')(({theme}) => ({
    display: 'flex',
    width: '5px',
    height: '5px',
    borderRadius: '100%',
    background: '#000000'
}));