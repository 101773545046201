import React, {FC, useEffect, useMemo, useState} from "react";
import {
    Box,
    Button, Chip,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import {MoreVertRounded, Edit} from "@mui/icons-material";

import {Routes} from "../../consts";
import {PlacementTabs} from "../PlacementTabs";
import {useUpdateProgramMutation} from "../../services";
import {Breadcrumbs, DashboardNavigation, IBreadcrumbItem} from "../UI";
import {useAuthContext} from "../../contexts/AuthContext";
import {Program, programStatusList} from "../../types";
// TODO: update import path
import {ArchiveProgramModal, UpdateProgramModal} from "../Modals";
import {getProgramStatusColor} from "../../utils";

interface PlacementHeadingProps { 
    placement: Program;
}

export const PlacementHeading: FC<PlacementHeadingProps> = ({placement}) => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const [showEditPlacementModal, setShowEditPlacementModal] = useState(false);
    const [showArchiveProgramModal, setShowArchiveProgramModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [updateProgram, {isSuccess: updateProgramSuccess, isLoading: updateProgramLoading}] = useUpdateProgramMutation();

    const breadcrumbsData = useMemo<IBreadcrumbItem[]>(() => {
        if (placement) {
            // Adding client name to breadcrumbs for Broker UI
            if (userIsAdmin || userIsManager) {
                return [
                    {label: 'Placement', to: Routes.placements},
                    {label: placement.client?.common_name},
                    {label: placement.name, active: true},
                ]
            }

            return [
                {label: 'Placement', to: Routes.placements},
                {label: placement.name, active: true},
            ]
        }

        return [];
    }, [placement, userIsAdmin, userIsManager]);

    const handleUpdateProgram = (values: Program) => {
        updateProgram(values);
    }

    const handleArchiveUnArchiveProgram = (values: Program) => {
        updateProgram(values);
    }

    const handleShowMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setAnchorEl(null);
    };

    const handleShowArchiveModal = () => {
        setShowArchiveProgramModal(true);
        handleCloseMoreMenu();
    }

    useEffect(() => {
        if (updateProgramSuccess) {
            setShowEditPlacementModal(false);
            setShowArchiveProgramModal(false)
        }
    }, [updateProgramSuccess]);

    return (
        <Container
            disableGutters
            sx={{display: 'flex', flexDirection: 'column'}}
        >
            {/* Internal Navigation*/}
            <DashboardNavigation />

            <Breadcrumbs backRoute={Routes.placements} items={breadcrumbsData}/>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginY: 4,
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                    <Box>
                        <Box sx={{display: "flex", alignItems: "center", gap: 3}}>
                            <Typography variant="h4" fontWeight={700}>
                                {placement?.name || ''}
                            </Typography>
                            {(userIsAdmin || userIsManager) && !placement.is_active && (<Chip label="Archived" />)}
                        </Box>

                        <Typography color={getProgramStatusColor(placement)}>
                            Status: {placement ? programStatusList[placement.status]?.label : ''}
                        </Typography>
                    </Box>

                    {
                        (userIsAdmin || userIsManager) && (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <Button
                                    variant="text"
                                    size="large"
                                    type="button"
                                    onClick={() => setShowEditPlacementModal(true)}
                                    startIcon={<Edit />}
                                >
                                    Edit Placement
                                </Button>
                                <IconButton type="button" size="large" onClick={handleShowMoreMenu}>
                                    <MoreVertRounded color={"action"}/>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={handleCloseMoreMenu}
                                >
                                    <MenuItem onClick={handleShowArchiveModal}>
                                        {placement.is_active ? "Archive" : "Unarchive"}
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )
                    }
                </Box>

                <PlacementTabs placementId={placement.id} />
            </Box>

            {/* Edit Program Details Modal */}
            <UpdateProgramModal
                open={showEditPlacementModal}
                onClose={() => setShowEditPlacementModal(false)}
                onEditProgram={handleUpdateProgram}
                loading={updateProgramLoading}
                programData={placement}
            />

            {/* Archive Program Modal */}
            <ArchiveProgramModal
                open={showArchiveProgramModal}
                onClose={() => setShowArchiveProgramModal(false)}
                program={placement}
                onArchiveProgram={handleArchiveUnArchiveProgram}
                loading={updateProgramLoading}
            />

        </Container>
    );
};