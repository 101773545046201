import React, {ChangeEvent, FC, useEffect, useMemo, useState} from "react";
import sortBy from "lodash/sortBy";
import {Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, OutlinedInput, Popover} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {styled} from "@mui/material/styles";

export interface IOption {label: string, value: string | number, disabled?: boolean}

interface MultiSelectPopoverProps {
    togglerTitle: string;
    selectedValues: Array<string | number>;
    options: IOption[];
    onApply: (selectedItems: IOption[]) => void;
    onReset: () => void;
    withSearch?: boolean;
    searchPlaceholder?: string;
}

export const MultiSelectPopover: FC<MultiSelectPopoverProps> = ({
    togglerTitle,
    selectedValues,
    options,
    onApply,
    onReset,
    withSearch= true,
    searchPlaceholder= 'Type To Search'
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);

    useEffect(() => {
        if (options && selectedValues.length) {
            setSelectedOptions(options.filter(option => selectedValues.includes(option.value)));
        }
    }, [options, anchorEl, selectedValues]);

    const filteredOptions = useMemo<IOption[]>(() => {
        if (options.length > 0) {
            return searchValue
                ? options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase()))
                : options;
        }

        return [];
    }, [searchValue, options]);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'all') {
            if (selectedOptions.length === filteredOptions.length) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(filteredOptions);
            }
            return;
        }

        if (selectedOptions.find(option => option.value == e.target.value)) {
            setSelectedOptions(prev => prev.filter(option => option.value != e.target.value));
        } else {
            const newOption = filteredOptions.find(option => option.value == e.target.value);
            if (newOption) {
                setSelectedOptions(prev => [...prev, newOption]);
            }
        }
    };

    const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        onApply(selectedOptions);
        handleClose();
    };

    const handleReset = () => {
        onReset();
        setSearchValue('');
        setSelectedOptions([]);
    }

    return (
        <Box>
            <Button
                variant="contained"
                size="small"
                type="button"
                onClick={handleShow}
                color="inherit"
                endIcon={<ExpandMoreIcon/>}
            >
                {`${togglerTitle} ${selectedValues.length ? `(${selectedValues.length})` : ''}`}
            </Button>

            <Popover
                sx={{marginTop: 2}}
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                <Grid container spacing={2} p={2} minWidth={320} maxWidth="min-content" overflow="hidden">
                    <Grid item xs={12}>
                        {
                            withSearch && (
                                <OutlinedInput
                                    placeholder={searchPlaceholder}
                                    value={searchValue}
                                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setSearchValue(event.target.value)
                                    }}
                                    endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}
                                />
                            )
                        }

                        <CheckboxesContainer>
                            <FormControlLabel
                                sx={{marginX: 0}}
                                label={'Select all'}
                                disabled={selectedOptions.length > 0}
                                control={
                                    <Checkbox
                                        name={'checkbox-item'}
                                        checked={selectedOptions.length === filteredOptions.length}
                                        value={'all'}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                            />
                            {sortBy(filteredOptions, ['label']).map((option, i) => (
                                <FormControlLabel
                                    key={`checkbox-${i}`}
                                    sx={{ marginX: 0 }}
                                    label={option.label}
                                    control={
                                        <Checkbox
                                            name={`option-${option.value}`}
                                            checked={Boolean(selectedOptions.find(opt => opt.value === option.value))}
                                            value={option.value}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                />
                            ))}
                        </CheckboxesContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 1}}>
                            <Button color="secondary" onClick={handleReset}>RESET</Button>
                            <Button color="secondary" onClick={handleApply}>APPLY</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Popover>
        </Box>
    )
}

const CheckboxesContainer = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    overflowY: "auto",
    height: '100%',
    maxHeight: 300,
    '&::-webkit-scrollbar': {
        width: '0.5rem'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.action.disabledBackground,
        borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        borderRadius: 4,
    }
}))