import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import {programApi, userApi, taskApi, clientApi, activityApi, eventApi, dashboardApi} from '../services';
import {alertSlice} from "./alertSlice";

export const store = configureStore({
    reducer: {
        [programApi.reducerPath]: programApi.reducer,
        [taskApi.reducerPath]: taskApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [activityApi.reducerPath]: activityApi.reducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [alertSlice.name]: alertSlice.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            programApi.middleware,
            taskApi.middleware,
            userApi.middleware,
            clientApi.middleware,
            activityApi.middleware,
            dashboardApi.middleware,
            eventApi.middleware
        ),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch