import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    TextField,
    Typography,
    IconButton, Chip, Menu, MenuItem, Select
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import MoreVertRounded from "@mui/icons-material/MoreVertRounded";
import * as yup from "yup";
import {useFormik} from "formik";

import {useAuthContext} from "../../contexts/AuthContext";
import {DeliverableType, deliverableTypeList, Task} from "../../types";
import {useDropzone} from "react-dropzone";

interface DeliverableTaskProps {
    task: Task;
    onUploadFile: (formData: FormData) => void;
    uploadLoading: boolean;
    uploadPercent: number;
    onTaskUpdate: (task: Task) => void;
    updateLoading: boolean;
    updateSuccess: boolean;
    onTaskArchive: () => void;
}

export const DeliverableTask:React.FC<DeliverableTaskProps> = ({
   task,
   onUploadFile,
   uploadLoading,
   uploadPercent,
   onTaskUpdate,
   updateLoading,
    updateSuccess,
   onTaskArchive,
}) => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const updateTaskSchema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        deliverable_type: yup.string(),
    });

    const updateTaskForm = useFormik({
        initialValues: {
            name: task.name,
            description: task.description,
            deliverable_type: task.deliverable_type,
        },
        validationSchema: updateTaskSchema,
        onSubmit: (values, {resetForm}) => {
            onTaskUpdate({...task, ...values});
        }
    });

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        disabled: uploadLoading,
        onDropAccepted: (files: File[]) => {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('fileb', files[0], files[0].name);
                onUploadFile(formData);
            }
        }
    });

    const {values, handleSubmit, handleChange, isValid, dirty, resetForm} = updateTaskForm;

    useEffect(() => {
        if (updateSuccess) {
            resetForm({values});
        }
        // eslint-disable-next-line
    }, [updateSuccess]);


    const handleShowMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setAnchorEl(null);
    };

    const handleToggleArchiveModal = () => {
        onTaskArchive();
        handleCloseMoreMenu();
    };

    return (
        <Container disableGutters sx={{display: 'flex', flexDirection: 'column', margin: '0 auto', marginBottom: 2}}>
            <form onSubmit={handleSubmit}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column-reverse', md: 'row'},
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginTop: 6,
                    marginBottom: 4,
                    gap: 6
                }}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: 3, minWidth: {xs: 'auto', md: 400}}}>
                        {
                            (userIsAdmin || userIsManager) ?
                                (
                                    <TextField
                                        name="name"
                                        fullWidth
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                )
                                :
                                <Typography variant="h4" fontWeight={700}>{task.name}</Typography>
                        }

                        {(userIsAdmin || userIsManager) && !task.is_active && (<Chip label="Archived" />)}
                    </Box>


                    {(userIsAdmin || userIsManager) && (
                        <Box sx={{display: 'flex', alignItems: 'center', gap: {xs: 1, md: 3}}}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                loading={updateLoading}
                                disabled={!dirty || !isValid}
                            >
                                Save
                            </LoadingButton>

                            <IconButton type="button" size="large" onClick={handleShowMoreMenu}>
                                <MoreVertRounded color={"action"}/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={handleCloseMoreMenu}
                            >
                                <MenuItem onClick={handleToggleArchiveModal}>
                                    {task.is_active ? "Archive" : "Unarchive"}
                                </MenuItem>
                            </Menu>
                        </Box>
                    )}
                </Box>

                {/* Task description */}
                {
                    (userIsAdmin || userIsManager)
                        ?
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1}}>
                            <TextField
                                name="description"
                                fullWidth
                                placeholder="Description"
                                multiline
                                rows={10}
                                value={values.description}
                                onChange={handleChange}
                            />
                        </Box>
                        :
                        <Typography variant="body2" sx={{lineBreak: 'normal'}}>
                            {task.description}
                        </Typography>
                }

                {/* Deliverable Type */}
                <Box sx={{display: 'flex', marginY: 3, alignItems: 'center', gap: 3}}>
                {
                    (userIsAdmin || userIsManager) && (
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                            <Typography variant="body2" fontWeight={700}>
                                Deliverable Type:
                            </Typography>
                            <Select
                                variant="standard"
                                labelId="deliverable_type-label"
                                name="deliverable_type"
                                value={values.deliverable_type}
                                onChange={handleChange}
                                label="Deliverable Type"
                            >
                                <MenuItem value="" disabled>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    Object.keys(deliverableTypeList).map((key ) => (
                                        <MenuItem key={`type-${key}`} value={key}>
                                            {deliverableTypeList[key as DeliverableType]?.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    )
                }
                </Box>

                {/*  Dropzone area   */}
                {
                    (userIsAdmin || userIsManager) && (
                        <Box>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: '#DAF0F7',
                                    border: '1px dashed',
                                    borderColor: isDragActive ? 'primary.light' : 'primary.dark'
                                }}>
                                    <Box sx={{display: 'flex', justifyContent: 'center', padding: 7}}>
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    color="primary"*/}
                                        {/*    size="large"*/}
                                        {/*    disabled={uploadLoading}*/}
                                        {/*>*/}
                                        {/*    {uploadLoading ? `Uploaded ${uploadPercent}%` : 'UPLOAD FILE'}*/}
                                        {/*</Button>*/}
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={uploadLoading}
                                            loading={uploadLoading}
                                        >
                                            UPLOAD FILE
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    )
                }
            </form>
        </Container>
    )
};