import React, {FC, Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {LoadingButton} from "@mui/lab";
import {
    Box,
    Container,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import {API} from "../../../API";
import {MainLayout} from "../../../layouts";
import {Endpoints, Routes} from "../../../consts";
import {useAuthContext} from "../../../contexts/AuthContext";
import {RequestTask} from "../../../components/RequestTask";
import {ApprovalTask} from "../../../components/ApprovalTask";
import {DeliverableTask} from "../../../components/DeliverableTask";
import {
    ActivityCard,
    ActivityFileCard,
    Breadcrumbs,
    DashboardNavigation,
    IBreadcrumbItem,
    TableLoader,
} from "../../../components/UI";
import {ActivitiesFilters, Activity, ActivityApprovalStatus, ActivityType, Task, TaskType,} from "../../../types";
import {
    useAddActivityMutation,
    useGetActivityListQuery,
    useGetProgramByIdQuery,
    useGetTaskByIdQuery,
    useUpdateActivityMutation,
    useUpdateTaskMutation
} from "../../../services";
import {getTaskDetailsCorrectBreadcrumb, toPythonISO} from "../../../utils";
import {ActivityAuthorFilterBar, ActivityTypeFilterBar} from "../../../components/ActivitiesFilterBars";
import {ActivityDateFilterBar} from "../../../components/ActivitiesFilterBars/ActivityDateFilterBar";
import {ArchiveTaskModal} from "../../../components/Modals";
import {useAppDispatch} from "../../../store/hooks";
import {openAlert} from "../../../store/alertSlice";

interface PlacementTaskDetailsPageProps {}

export const PlacementTaskDetailsPage: FC<PlacementTaskDetailsPageProps> = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{taskId: string; placementId: string;}>();
    const {user, userIsManager, userIsAdmin} = useAuthContext();
    const [comment, setComment] = useState<string>('');

    const [fileUploadPercent] = useState(0);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    const [showArchiveTaskModal, setShowArchiveTaskModal] = useState(false);
    const [archiveTaskComment, setArchiveTaskComment] = useState('');

    const [activitiesFilters, setActivitiesFilters] = useState<ActivitiesFilters>({
        types: [],
        authorIds: [],
        date: {
            from: null,
            to: null
        },
    });

    const {data: programDetails} = useGetProgramByIdQuery(+params.placementId);
    const {data: taskDetails, isLoading: getTaskDetailsLoading} = useGetTaskByIdQuery(+params.taskId);
    const [updateTask, {isLoading: updateTaskLoading, isSuccess: updateTaskSuccess}] = useUpdateTaskMutation();
    const [archiveTask,
        {data: archiveTaskData, isLoading: archiveTaskLoading, isSuccess: archiveTaskSuccess}
    ] = useUpdateTaskMutation();
    const {data: activityList} = useGetActivityListQuery({
        task_id: +params.taskId,
        orderBy: '-created_at',
        type: activitiesFilters.types.length ? activitiesFilters.types.join(',') : undefined,
        gt_created_at: activitiesFilters.date.from ? toPythonISO(activitiesFilters.date.from) : undefined,
        lt_created_at: activitiesFilters.date.to ? toPythonISO(activitiesFilters.date.to) : undefined,
        author_id: activitiesFilters.authorIds.length ? activitiesFilters.authorIds.join(',') : undefined,
    });
    const {data: fileActivityList} = useGetActivityListQuery({
        task_id: +params.taskId,
        type: ActivityType.FileUpload,
        orderBy: '-created_at'
    });

    const [
        addMessageActivity,
        {isSuccess: addMessageActivitySuccess, isLoading: addMessageActivityLoading}
    ] = useAddActivityMutation();

    const [
        addActivity, {isLoading: addActivityLoading, isSuccess: addActivitySuccess}
    ] = useAddActivityMutation();

    const [
        updateActivity, {isLoading: updateActivityLoading, isSuccess: updateActivitySuccess}
    ] = useUpdateActivityMutation();

    // Breadcrumbs Data
    const breadcrumbsData = useMemo<IBreadcrumbItem[]>(() => {
        if (programDetails && taskDetails) {
            const correctRoute = getTaskDetailsCorrectBreadcrumb(taskDetails.type, programDetails.id);

            if (userIsAdmin || userIsManager) {
                return [
                    {label: 'Placements', to: Routes.placements},
                    {label: programDetails.client?.common_name},
                    {label: programDetails.name, to: Routes.placementRequests(programDetails.id)},
                    {label: correctRoute.label, to: correctRoute.to},
                    {label: taskDetails.name, active: true}
                ]
            }

            return [
                {label: 'Placements', to: Routes.placements},
                {label: programDetails.name, to: Routes.placementRequests(programDetails.id)},
                {label: correctRoute.label, to: correctRoute.to},
                {label: taskDetails.name, active: true}
            ];
        }

        return [];
    }, [programDetails, taskDetails, userIsManager, userIsAdmin]);

    // Create Message Activity
    const handleCreateMessageActivity = (text: string, type?: ActivityType) => {
        addMessageActivity({
            type: type || ActivityType.Message,
            approval_status: ActivityApprovalStatus.New,
            text: text,
            author_id: user?.id,
            task_id: taskDetails?.id,
            parent_id: null,
        });
    };

    // empty textarea when comment created successfully
    useEffect(() => {
        if (addMessageActivitySuccess) {
            setComment('');
        }
        // eslint-disable-next-line
    }, [addMessageActivitySuccess]);

    // Get Upload File percentage
    // const handleUploadProgress = (progressEvent: any) => {
    //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     setFileUploadPercent(percentCompleted);
    // };

    const handleUploadFile = (formData: FormData) => {
        setFileUploadLoading(true);
        addActivity({
            author_id: user?.id,
            task_id: taskDetails?.id,
            type: ActivityType.FileUpload,
            text: 'uploaded a document',
            approval_status: ActivityApprovalStatus.New
        })
        .then((res: any) => {
            // Upload File to DB if activity created successfully
            API.post(
                Endpoints.Activity.UploadFile(res.data.id),
                formData,
                {
                    timeout: 100000000,
                    // onUploadProgress: (progressEvent) => {
                    //     handleUploadProgress(progressEvent);
                    // }
                }
            ).then(res => {
                dispatch(openAlert({title: 'Uploaded successfully!'}))
            })
        })
        .catch(err => {
            dispatch(openAlert({title: 'Something went wrong!', severity: 'error', color: 'error'}))
        })
        .finally(() => {
            setFileUploadLoading(false);
        })
    };

    // Create Approval Activity - Approve | Decline
    const handleApproveOrDeclineActivity = (
        parentActivity: Activity,
        approvalActionStatus: ActivityApprovalStatus,
        comment: string
    ) => {
        const isApprove = approvalActionStatus === ActivityApprovalStatus.Approved;
        // Create new Activity
        addActivity({
            author_id: user?.id,
            task_id: taskDetails?.id,
            type: isApprove ? ActivityType.Approval : ActivityType.Denial,
            text: `${isApprove ? 'approved' : 'declined' } ${parentActivity.filename}`,
            approval_status: isApprove ? ActivityApprovalStatus.Approved : ActivityApprovalStatus.Declined,
            parent_id: parentActivity.id
        }).then((res: any) => {
            if (!!comment && res?.data) {
                addActivity({
                    author_id: user?.id,
                    task_id: taskDetails?.id,
                    type: ActivityType.Message,
                    text: comment,
                    parent_id: res.data.id,
                    approval_status: ActivityApprovalStatus.New,
                })
            }
        })

        // Update Parent Activity approval status with last status
        updateActivity({
            ...parentActivity,
            approval_status: approvalActionStatus
        });
    };

    // Update Task Details
    const handleUpdateTask = (updatedTask: Task) => {
        updateTask(updatedTask);
    };

    // Show Alert When saved Successfully
    useEffect(() => {
        if (updateTaskSuccess) {
            dispatch(openAlert({
                title: 'Saved',
                color: 'success',
            }));
        }
        // eslint-disable-next-line
    }, [updateTaskSuccess]);

    // Archive Task
    const handleArchiveUnarchiveTask = () => {
        if (taskDetails) {
            archiveTask({
                ...taskDetails,
                is_active: !taskDetails.is_active,
            });
        }
    };

    useEffect(() => {
        if (archiveTaskSuccess) {
            // when Task is archived successfully create message activity
            handleCreateMessageActivity(
                archiveTaskComment,
                archiveTaskData?.is_active ? ActivityType.UnarchiveTask : ActivityType.ArchiveTask
            );
        }
        // eslint-disable-next-line
    }, [archiveTaskSuccess]);

    useEffect(() => {
        if (addMessageActivitySuccess) {
            handleCloseArchiveModal();
        }
    }, [addMessageActivitySuccess]);

    const handleCloseArchiveModal = () => {
        setShowArchiveTaskModal(false);
        setArchiveTaskComment('');
    };

    return (
        <MainLayout>
            {/* Archive Task Dialog */}
            <ArchiveTaskModal
                open={showArchiveTaskModal}
                onClose={handleCloseArchiveModal}
                task={taskDetails}
                onArchiveTask={handleArchiveUnarchiveTask}
                loading={archiveTaskLoading || addMessageActivityLoading}
                archiveTaskComment={archiveTaskComment}
                setArchiveTaskComment={setArchiveTaskComment}
            />

            {
                getTaskDetailsLoading
                    ?
                    <Container sx={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: 100}}
                    >
                        <TableLoader/>
                    </Container>
                    :
                    <Container disableGutters sx={{display: 'flex', flexDirection: 'column', margin: '32px auto'}}>
                        {/* Internal Navigation*/}
                        <DashboardNavigation />

                        <Breadcrumbs items={breadcrumbsData} />

                        {taskDetails?.type === TaskType.Request && (
                            <RequestTask
                                task={taskDetails}
                                sender={taskDetails?.assignee}
                                onUploadFile={handleUploadFile}
                                uploadLoading={fileUploadLoading}
                                uploadPercent={fileUploadPercent}
                                onTaskArchive={() => setShowArchiveTaskModal(true)}
                                onTaskUpdate={handleUpdateTask}
                                updateLoading={updateTaskLoading}
                                updateSuccess={updateTaskSuccess}
                                onCreateMessageActivity={handleCreateMessageActivity}
                                createMessageActivityLoading={addMessageActivityLoading}
                                createMessageActivitySuccess={addMessageActivitySuccess}
                            />
                        )}
                        {taskDetails?.type === TaskType.Approval && (
                            <ApprovalTask
                                task={taskDetails}
                                approver={taskDetails?.assignee}
                                onUploadFile={handleUploadFile}
                                uploadLoading={fileUploadLoading}
                                uploadPercent={fileUploadPercent}
                                fileActivities={fileActivityList?.data || []}
                                onApproveOrDecline={handleApproveOrDeclineActivity}
                                onApproveOrDeclineLoading={addActivityLoading || updateActivityLoading}
                                onApproveOrDeclineSuccess={addActivitySuccess && updateActivitySuccess}
                                onTaskArchive={() => setShowArchiveTaskModal(true)}
                                onTaskUpdate={handleUpdateTask}
                                updateLoading={updateTaskLoading}
                                updateSuccess={updateTaskSuccess}
                            />
                        )}
                        {taskDetails?.type === TaskType.Deliverable && (
                            <DeliverableTask
                                task={taskDetails}
                                onUploadFile={handleUploadFile}
                                uploadLoading={fileUploadLoading}
                                uploadPercent={fileUploadPercent}
                                onTaskArchive={() => setShowArchiveTaskModal(true)}
                                onTaskUpdate={handleUpdateTask}
                                updateLoading={updateTaskLoading}
                                updateSuccess={updateTaskSuccess}
                            />
                        )}

                        <Grid
                            container
                            pt={2}
                            sx={{
                                marginTop: 2,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                                boxSizing: 'border-box'
                            }}
                        >
                            <Grid item md={8} xs={12} display="flex" flexDirection="column">
                                {/* Filters */}
                                <Box display="flex" flexDirection="column" mb={4} gap={3}>
                                    <Typography variant="h6" color={'text.primary'} fontWeight={700}>
                                        History:
                                    </Typography>
                                    <Box display="flex" flexWrap="wrap" gap={3}>
                                        <ActivityTypeFilterBar filters={activitiesFilters} setFilters={setActivitiesFilters} />

                                        <ActivityAuthorFilterBar filters={activitiesFilters} setFilters={setActivitiesFilters} />

                                        <ActivityDateFilterBar filters={activitiesFilters} setFilters={setActivitiesFilters} />
                                    </Box>
                                </Box>


                                {/* Message TextArea */}
                                <Box sx={{position: 'relative', marginBottom: 6}}>
                                    <TextField
                                        fullWidth
                                        placeholder="Ask a question or post an update"
                                        multiline
                                        rows={6}
                                        onChange={e => {
                                            setComment(e.target.value)
                                        }}
                                        value={comment}
                                    />
                                    <Box sx={{position: 'absolute', bottom: '10px', right: '10px'}}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleCreateMessageActivity(comment)}
                                            loading={addMessageActivityLoading}
                                            disabled={comment.length === 0}
                                        >
                                            COMMENT
                                        </LoadingButton>
                                    </Box>
                                </Box>


                                {/* Activities List */}
                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                    {
                                        activityList && activityList.data.length > 0 &&
                                        activityList.data.filter(a => (a.type === ActivityType.Approval) || (a.type === ActivityType.Denial) || !a.parent_id)
                                            .map((activity) => (
                                            <Fragment key={`activity-item-${activity.id}`}>
                                                <ActivityCard
                                                    key={`activity-item-${activity.id}`}
                                                    activity={activity}
                                                />
                                                {
                                                    activityList.data.filter(a => (a.parent_id === activity.id) && (a.type === ActivityType.Message))
                                                        .map((activity) => (
                                                            <ActivityCard
                                                                key={`activity-child-item-${activity.id}`}
                                                                activity={activity}
                                                                isChild={true}
                                                            />
                                                    ))
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </Box>

                            </Grid>

                            {
                                taskDetails && (taskDetails.type !== TaskType.Approval) &&
                                <Grid item md={4} xs={12} display="flex" flexDirection="column" sx={{paddingX: {md: 6}}}>
                                    <Typography variant="h6" color={'text.primary'} fontWeight={700} mt={1} mb={3}>
                                        Uploads
                                    </Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        {
                                            fileActivityList && fileActivityList.data.length > 0 &&
                                            fileActivityList.data.map((activity) => (
                                                <ActivityFileCard
                                                    key={`activity-file-${activity.id}`}
                                                    activity={activity}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Grid>
                            }
                        </Grid>

                    </Container>
            }
        </MainLayout>
    );
};
