import {Task} from "../types";
import moment from "moment";

export const getTaskStatusColor = (task: Task): string => {
    // TODO: 5 should be business days
    const soonDate = new Date().setDate(new Date().getDate() + 5);

    if (moment(new Date()).isAfter(task?.target_date)) {
        return 'error.main';
    }

    if (moment(task?.target_date).isBetween(new Date(), soonDate)) {
        return 'warning.main';
    }

    return 'error.main'
}