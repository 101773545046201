
import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import {Box, Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SquareIcon from '@mui/icons-material/Square';
import Skeleton from "@mui/material/Skeleton";

import {Routes} from "../../consts";
import {DashboardData} from "../../types";

interface PlacementsInfoCardProps {
    data: DashboardData | undefined;
    loading: boolean;
}

export const PlacementsInfoCard: FC<PlacementsInfoCardProps> = ({data, loading}) => {

    return (
        <Card sx={{width: '100%', bgcolor: 'grey.50'}}>
            <CardHeader
                title="Placements Status"
                action={
                    <IconButton component={NavLink} to={Routes.placements} aria-label="settings" color="primary">
                        <ArrowForwardIcon />
                    </IconButton>
                }
            />

            <CardContent>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, bgcolor: 'white', py: 3}}>
                    {/* Chart */}
                    {/*FIXME: add correct values*/}
                    <Box sx={{position: 'relative', maxWidth: 152}}>
                        {
                            loading
                            ? <Skeleton animation="wave" variant="circular" width={150} height={150} />
                            : (
                                <>
                                    <Box sx={{
                                        position: 'absolute',
                                        left: 36,
                                        top: 24,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: 82
                                    }}>
                                        <Typography variant="h4" color="black" fontWeight={500}>
                                            {data?.program_count}
                                        </Typography>
                                        <Typography variant="body2" fontWeight={500} textAlign="center">
                                            Total Placements
                                        </Typography>
                                    </Box>

                                    <PieChart
                                        lineWidth={18}
                                        startAngle={270}
                                        totalValue={data?.program_count}
                                        animate
                                        data={[
                                            { value: data?.program_count_in_progress || 0, color: '#009DE0' },
                                            { value: data?.program_count_complete || 0, color: '#002C77' },
                                        ]}
                                    />
                                </>
                            )
                        }

                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{display: 'flex', gap: 1, marginBottom: 3}}>
                            {
                                loading
                                ? <Skeleton width={180} height={20} />
                                : (
                                    <>
                                        <SquareIcon color="secondary" fontSize="small" />

                                        <Typography variant="body2" color="text.secondary">
                                            <b>{data?.program_count_in_progress}</b>
                                            <span> Placements In Progress</span>
                                        </Typography>
                                    </>
                                )
                            }
                        </Box>
                        <Box sx={{display: 'flex', gap: 1}}>
                            {
                                loading
                                    ? <Skeleton width={180} height={20} />
                                    : (
                                        <>
                                            <SquareIcon color="primary" fontSize="small" />

                                            <Typography variant="body2" color="text.secondary">
                                                <b>{data?.program_count_complete}</b>
                                                <span> Placements Completed</span>
                                            </Typography>
                                        </>
                                    )
                            }
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}