import React, {FC, Fragment, useMemo} from "react";
import {NavLink} from "react-router-dom";
import {
    Avatar,
    Card, CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {styled} from "@mui/material/styles";
import TodayIcon from '@mui/icons-material/Today';

import {Routes} from "../../consts";
import {DashboardData, IEvent} from "../../types";

interface EventsInfoCardProps {
    data?: DashboardData;
    loading: boolean;
}
export const EventsInfoCard: FC<EventsInfoCardProps> = ({data, loading}) => {

    const upcomingEvents = useMemo<IEvent[]>(() => {
        if (data) {
            if (data.events?.length === 0) return [];
            // TODO: make this filtering and sorting in BE
            let onlyUpcomingEvents: IEvent[] = []
            if (data && data.events) {
                onlyUpcomingEvents = [...data.events].filter(event => new Date(event.start_time).getTime() > Date.now())
            }
            const sortedEvents = [...onlyUpcomingEvents].sort((a, b) => (
                new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
            ))

            return sortedEvents.slice(0, 3)
        }

        return []
    }, [data]);

    return (
        <Card sx={{width: '100%', bgcolor: 'grey.50'}}>
            <CardHeader title="Upcoming Events"/>

            <CardContent>
                <List sx={{bgcolor: 'white'}} disablePadding>
                    {/* Show Loading Skeleton*/}
                    {
                        loading &&
                        [1, 2, 3].map((item, i) => (
                            <Fragment key={`item-${i}`}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={<Skeleton height={32} />}
                                        secondary={<Skeleton height={24} />}
                                    />
                                </ListItem>
                                <Divider />
                            </Fragment>
                        ))
                    }

                    {
                        !loading
                        ?
                            upcomingEvents.length > 0
                            ?
                            upcomingEvents.map((event, i) => (
                                <Fragment key={`item-${i}`}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    backgroundColor: 'grey.100',
                                                    color: 'primary.main',
                                                    fontWeight: 700,
                                                    width: 40,
                                                    height: 40
                                                }}
                                            >
                                                <TodayIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <StyledNavLink to={Routes.placementEvents(event.program_id)}>
                                                    <Typography variant="body1" color="primary.light" mb={1}>
                                                        {`${event.date || ''} ${event.name || ''}`}
                                                    </Typography>
                                                </StyledNavLink>
                                            }
                                            secondary={
                                                <Typography variant="body1" color="text.secondary">
                                                    {event.program_id || ''}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))
                            :
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: 'grey.100',
                                            color: 'primary.main',
                                            fontWeight: 700,
                                            width: 40,
                                            height: 40
                                        }}
                                    >
                                        <TodayIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography variant="body1" color="primary.light" mb={1}>
                                            No upcoming events
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        :
                        null
                    }
                </List>
            </CardContent>
        </Card>
    )
}

const StyledNavLink = styled(NavLink)(() => ({
    textDecoration: 'none'
}))