import React, {useEffect, useState} from "react";
import moment from "moment";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDropzone} from 'react-dropzone';
import sortBy from "lodash/sortBy";
import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, Menu,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {DesktopDatePicker, LoadingButton} from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import MoreVertRounded from "@mui/icons-material/MoreVertRounded";


import {ActivityType, Task, TaskStatus, User} from "../../types";
import {useAuthContext} from "../../contexts/AuthContext";
import {useGetUserListQuery, useUpdateTaskMutation} from "../../services";

interface RequestTaskProps {
    task: Task;
    sender: User | undefined;
    onUploadFile: (formData: FormData) => void;
    uploadLoading: boolean;
    uploadPercent: number;
    onTaskArchive: () => void
    onTaskUpdate: (task: Task) => void;
    updateLoading: boolean;
    updateSuccess: boolean;
    onCreateMessageActivity: (text: string, type?: ActivityType) => void;
    createMessageActivityLoading: boolean;
    createMessageActivitySuccess: boolean;
}

export const RequestTask: React.FC<RequestTaskProps> = ({
    task,
    sender,
    onUploadFile,
    uploadLoading,
    uploadPercent,
    onTaskUpdate,
    onTaskArchive,
    updateLoading,
    updateSuccess,
    onCreateMessageActivity,
    createMessageActivityLoading,
    createMessageActivitySuccess,
}) => {
    const {userIsAdmin, userIsManager} = useAuthContext();
    const {data: userList} = useGetUserListQuery({});
    const [completeTask,
        {data: completeTaskData, isLoading: completeTaskLoading, isSuccess: completeTaskSuccess}
    ] = useUpdateTaskMutation();
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [comment, setComment] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const updateTaskSchema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        assignee_id: yup.string().required(''),
        target_date: yup.string().required(''),
    });

    const updateTaskForm = useFormik({
        initialValues: {
            name: task.name,
            description: task.description,
            assignee_id: task.assignee_id,
            target_date: new Date(task.target_date),
        },
        validationSchema: updateTaskSchema,
        onSubmit: (values, {resetForm}) => {
            onTaskUpdate({...task, ...values});
        }
    });

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        disabled: uploadLoading,
        onDropAccepted: (files: File[]) => {
            if (files.length > 0) {
                const formData = new FormData();
                formData.append('fileb', files[0], files[0].name);
                onUploadFile(formData);
            }
        }
    });

    const {values, handleSubmit, handleChange, setFieldValue, isValid, dirty, resetForm} = updateTaskForm;

    useEffect(() => {
        if (updateSuccess) {
            resetForm({values});
        }
        // eslint-disable-next-line
    }, [updateSuccess]);

    const handleCloseCompleteTaskModal = () => {
        setShowCompleteModal(false);
        setComment('');
    };

    const handleCompleteUnCompleteTask = () => {
        completeTask({
            ...task,
            status: task.status === TaskStatus.Completed ? TaskStatus.Open : TaskStatus.Completed
        })
    };

    useEffect(() => {
        if (completeTaskSuccess) {
            onCreateMessageActivity(
                comment,
                completeTaskData?.status === TaskStatus.Completed
                    ? ActivityType.CompleteTask : ActivityType.UncompleteTask
            );
        }
        // eslint-disable-next-line
    }, [completeTaskSuccess]);

    useEffect(() => {
        if (createMessageActivitySuccess) {
            handleCloseCompleteTaskModal();
        }
        // eslint-disable-next-line
    }, [createMessageActivitySuccess]);

    const handleShowMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = () => {
        setAnchorEl(null);
    };

    const handleToggleArchiveModal = () => {
        onTaskArchive();
        handleCloseMoreMenu();
    };


    return (
        <Container disableGutters sx={{display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
            <form onSubmit={handleSubmit}>
            <Box sx={{
                display: 'flex',
                flexDirection: {xs: 'column-reverse', md: 'row'},
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: 6,
                marginBottom: 4,
                gap: 6
            }}>
                <Box sx={{display: 'flex', alignItems: 'flex-end', gap: 3, minWidth: {xs: 'auto', md: 400}}}>
                    {
                        (userIsAdmin || userIsManager) ?
                        (
                            <TextField
                                name="name"
                                fullWidth
                                placeholder="Name"
                                value={values.name}
                                onChange={handleChange}
                            />
                        )
                            :
                            <Typography variant="h4" fontWeight={700}>{task.name}</Typography>
                    }

                    {(userIsAdmin || userIsManager) && !task.is_active && (<Chip label="Archived" />)}
                </Box>


                {(userIsAdmin || userIsManager) && (
                    <Box sx={{display: 'flex', alignItems: 'center', gap: {xs: 1, md: 3}}}>
                        <Button
                            size="large"
                            variant={task.status === TaskStatus.Completed ? 'contained' : 'outlined'}
                            color={task.status === TaskStatus.Completed ? 'success' : 'primary'}
                            endIcon={task.status === TaskStatus.Completed ? <CheckIcon/> : null}
                            onClick={() => setShowCompleteModal(true)}
                        >
                            Complete
                        </Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            loading={updateLoading}
                            disabled={!dirty || !isValid}
                        >
                            Save
                        </LoadingButton>

                        <IconButton type="button" size="large" onClick={handleShowMoreMenu}>
                            <MoreVertRounded color={"action"}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={handleCloseMoreMenu}
                        >
                            <MenuItem onClick={handleToggleArchiveModal}>
                                {task.is_active ? "Archive" : "Unarchive"}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Box>

            {/* Task description */}
            {
                (userIsAdmin || userIsManager)
                    ?
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1}}>
                        <TextField
                            name="description"
                            fullWidth
                            placeholder="Description"
                            multiline
                            rows={10}
                            value={values.description}
                            onChange={handleChange}
                        />
                    </Box>
                    :
                    <Typography variant="body2" sx={{lineBreak: 'normal'}}>
                        {task.description}
                    </Typography>
            }

            <Box sx={{display: 'flex', flexWrap: 'wrap', marginY: 3, alignItems: 'center', gap: 3}}>
                {/* Request Sender */}
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <Typography variant="body2" fontWeight={700}>
                        Sender:
                    </Typography>
                    {
                        (userIsAdmin || userIsManager)
                            ?
                                <Select
                                    variant="standard"
                                    labelId="sender-label"
                                    name="assignee_id"
                                    value={values.assignee_id}
                                    onChange={handleChange}
                                    label="Sender"
                                >
                                    <MenuItem value="" disabled>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        (userList && userList.data.length > 0) &&
                                        sortBy(userList.data, ['name']).map((user) => (
                                            <MenuItem key={`user-${user.id}`} value={user.id}>{user.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            :
                                <Typography variant="body2">
                                    {sender ? sender.name : ' - '}
                                </Typography>
                    }
                </Box>

                {/* Request Target Date */}
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <Typography variant="body2" fontWeight={700}>
                        Target Date:
                    </Typography>
                    {
                        (userIsAdmin || userIsManager)
                            ?
                            <DesktopDatePicker
                                value={values.target_date}
                                onChange={(newValue) => {
                                    setFieldValue('target_date', newValue);
                                }}
                                renderInput={(params) => <TextField variant="standard" {...params} />}
                            />
                            :
                            <Typography variant="body2">
                                {task ? moment(task.target_date).format('MM/DD/YYYY') : ' - '}
                            </Typography>
                    }
                </Box>
            </Box>

            {/*  Dropzone area   */}
            <Box>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#DAF0F7',
                        border: '1px dashed',
                        borderColor: isDragActive ? 'primary.light' : 'primary.dark'
                    }}>
                        <Box sx={{display: 'flex', justifyContent: 'center', padding: 7}}>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    size="large"*/}
                            {/*    disabled={uploadLoading}*/}
                            {/*>*/}
                            {/*    {uploadLoading ? `Uploaded ${uploadPercent}%` : 'UPLOAD FILE'}*/}
                            {/*</Button>*/}
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={uploadLoading}
                                loading={uploadLoading}
                            >
                                UPLOAD FILE
                            </LoadingButton>
                        </Box>
                    </Box>
                </div>
            </Box>
            </form>

            {/* Complete Request Dialog */}
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={showCompleteModal}
                onClose={handleCloseCompleteTaskModal}
            >
                <DialogTitle color={'text.primary'}>
                    {
                        task.status === TaskStatus.Completed
                        ? 'Open this request again' : 'Mark this request complete'
                    }
                </DialogTitle>
                <DialogContent>
                    <Box p={1}>
                        <TextField
                            autoFocus={true}
                            fullWidth
                            placeholder={
                                task.status === TaskStatus.Completed
                                ? 'Please add a reason...' : 'Add a comment...'
                            }
                            multiline
                            rows={8}
                            value={comment}
                            onChange={(e) => {
                                setComment(e.target.value)
                            }}
                            helperText={task.status === TaskStatus.Completed ? 'Required' : 'Optional'}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompleteTaskModal}>Cancel</Button>

                    <LoadingButton
                        loading={completeTaskLoading || createMessageActivityLoading}
                        disabled={task.status === TaskStatus.Completed ? comment.length === 0 : false}
                        onClick={handleCompleteUnCompleteTask}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>

        </Container>
    );
};





