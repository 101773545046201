import React, {useMemo} from 'react';

import { ProgramFilters } from "../../types";
import {useGetClientListQuery} from "../../services";
import {IOption, MultiSelectPopover} from "../UI";

interface ProgramClientFilterBarProps {
    filters: ProgramFilters;
    setFilters: (filters: ProgramFilters) => void;
}

export const ProgramClientFilterBar: React.FC<ProgramClientFilterBarProps> = ({
  filters,
  setFilters,
}) => {
    const {data: clientList} = useGetClientListQuery({});

    const clientFilterOptions = useMemo<IOption[]>(() => {
        if (clientList?.data) {
            return clientList?.data?.map(client => ({
                label: client.common_name,
                value: client.id,
            }))
        }
        return [];
    }, [clientList]);

    return (
        <MultiSelectPopover
            togglerTitle={"Client"}
            selectedValues={filters.clientIds}
            options={clientFilterOptions}
            onApply={(selectedItems) => {
                setFilters({...filters, clientIds: selectedItems.map(item => +item.value)})
            }}
            onReset={() => setFilters({...filters, clientIds: []})}
            searchPlaceholder={'Search for a client'}
        />
    );
};