import React from 'react'
import {styled} from "@mui/material";

import { Logo } from '../../../../environment';

export const Header: React.FC = () => {
    return (
       <HeaderWrapper>
           <HeaderContainer>
               <Logo />
           </HeaderContainer>
       </HeaderWrapper>
    )
}

export const HeaderWrapper = styled('header')(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
}))

export const HeaderContainer = styled('div')(() => ({
    display: 'flex',
    maxWidth: '139.5rem',
    margin: '0 auto',
    paddingTop: 44,
    paddingLeft: 45,

    '@media(maxWidth: 780px)': {
        justifyContent: 'left',
        paddingLeft: 0.5,
    }
}))



