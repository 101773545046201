import React from 'react';
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";

interface RoundedCheckIconProps {
    className?: string;
    type: 'checked' | 'defaultChecked' | 'unChecked';
}

export const RoundedCheckIcon:React.FC<RoundedCheckIconProps> = ({
    className,
    type,
}) => {
    return (
        <StyledBox className={className}>
            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.48398 4.77356L2.15366 2.44323L0.717529 3.87936L3.76592 6.92775C3.95644 7.11804 4.21471 7.22492 4.48398 7.22492C4.75326 7.22492 5.01152 7.11804 5.20205 6.92775L10.6214 1.50839L9.18528 0.0722656L4.48398 4.77356Z"
                    fill={type === 'checked' ? '#00AC41' : type === 'defaultChecked' ? '#949393' : 'rgba(0, 0, 0, 0)'}
                />
            </svg>
        </StyledBox>
    );
};

const StyledBox = styled(Box)(() => ({
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    minWidth: '20px',
    height: '20px',
    borderRadius: '100%',
    border: '2px solid #949393'
}));