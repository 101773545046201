import React, {FC, useCallback, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {Box, IconButton, Tooltip, TableRow, TableCell, Collapse, Grid, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from "@mui/icons-material/Archive";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {Endpoints, Routes} from "../../../../consts";
import {useToast} from "../../../../hooks";
import {
    Activity,
    ActivityType,
    Task,
    TaskStatus,
    taskStatusList,
} from "../../../../types";
import {download, getTaskStatusColor, sortByDate} from "../../../../utils";
import {RoundedCheckIcon} from "../../../UI";
import {RowActivityCard} from "../../components/RowActivityCard";
import {useGetActivityListQuery} from "../../../../services";

interface RowItemProps {
    task: Task;
}

export const RowItem: FC<RowItemProps> = ({
  task,
}) => {
    const toast = useToast();
    const [collapsed, setCollapsed] = useState(false);
    const {data: activityListData} = useGetActivityListQuery({task_id: task.id});

    const activityList = useMemo<Activity[]>(() => {
        if (activityListData) {
            return sortByDate<Activity>(activityListData.data, 'created', 'descending');
        }
        return [];
    }, [activityListData]);

    const fileActivityList = useMemo<Activity[]>(() => {
        if (activityListData) {
            return sortByDate<Activity>(activityListData.data, 'created', 'descending')
                .filter(act => act.type === ActivityType.FileUpload);
        }
        return [];
    }, [activityListData]);

    // On Click Download Link download latest (created) file activity
    const handleDownloadLatestActivity = useCallback(() => {
        if(fileActivityList.length > 0) {
            download(Endpoints.Activity.DownloadFile(fileActivityList[0].upload_location), fileActivityList[0].filename);
        } else {
            toast.info('No activities found');
        }
    }, [fileActivityList, toast]);

    return (
        <>
            <TableRow sx={{
                '& > *': { borderBottom: 'unset' },
                bgcolor: task.status === TaskStatus.Completed ? 'grey.100' : ''
            }}>
                <TableCell>
                    <RoundedCheckIcon
                        type={
                            task.status === TaskStatus.Pending ? 'defaultChecked' :
                                task.status === TaskStatus.Completed ? 'checked' : 'unChecked'
                        }
                    />
                </TableCell>

                <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                        <TableLink to={Routes.placementTaskDetails(task.program_id, task.id)}>
                            {task.name}
                        </TableLink>
                        {!task.is_active && (
                            <Tooltip title={task.is_active ? 'Active' : 'Archived'}>
                                <ArchiveIcon fontSize="small" color="disabled" />
                            </Tooltip>
                        )}
                    </Box>
                </TableCell>

                <TableCell>{task.updated_at ? moment(task.updated_at).format('MM/DD/YYYY') : '-'}</TableCell>

                <TableCell>{task.target_date ? moment(task.target_date).format('MM/DD/YYYY') : '-'}</TableCell>

                <TableCell>{task.assignee?.name || '-'}</TableCell>

                <TableCell sx={{color: getTaskStatusColor(task)}}>
                    {taskStatusList[task.status]?.label}
                </TableCell>

                <TableCell>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={3}>
                        {
                            fileActivityList.length > 0 && (
                                <Tooltip title="Download last uploaded document">
                                    <IconButton size="small" onClick={handleDownloadLatestActivity}>
                                        <DownloadIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            )
                        }

                        <IconButton size="small" onClick={() => setCollapsed(!collapsed)}>
                            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Box>
                </TableCell>

            </TableRow>
            {/*Expanding part of row*/}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={collapsed} timeout="auto" unmountOnExit>
                        {/* TODO: Update nested row item*/}
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2} pt={3} pb={4}>
                                <Grid item xs={6} display="flex" flexDirection="column">
                                    <Typography variant="caption" fontWeight={700} color="text.primary">
                                        Description
                                    </Typography>

                                    <Typography variant="caption" color="text.primary">
                                        {task.description.slice(0, 200)}
                                        {
                                            task.description?.length > 200 &&
                                            <TableReadMoreLink to={Routes.placementTaskDetails(task.program_id, task.id)}>
                                                <Typography variant="caption" color="text.primary">
                                                    ...read more
                                                </Typography>
                                            </TableReadMoreLink>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} display="flex" flexDirection="column">
                                    <Typography variant="caption" fontWeight={700} color="text.primary">
                                        Activity
                                    </Typography>

                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                        {
                                            activityList.length > 0 &&
                                            activityList.slice(0, 2).map((activity) => (
                                                <RowActivityCard
                                                    key={`activity-item-${activity.id}`}
                                                    activity={activity}
                                                />
                                            ))
                                        }
                                        {
                                            activityList.length > 2 &&
                                            <TableReadMoreLink to={Routes.placementTaskDetails(task.program_id, task.id)}>
                                                <Typography variant="caption" color="text.primary">
                                                    {`see more >`}
                                                </Typography>
                                            </TableReadMoreLink>
                                        }
                                        {
                                            activityList.length === 0 &&
                                            <Typography variant="caption" color="text.primary">
                                                No activities found
                                            </Typography>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const TableLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main
}));

const TableReadMoreLink = styled(NavLink)(({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.text.primary,
}));
