import {Task, TaskType} from "../types";
import {Routes} from "../consts";

export const getTasksRoute = (task: Task) => {
    if (task.type === TaskType.Deliverable) {
        return Routes.placementDeliverables(task.program_id)
    }

    if (task.type === TaskType.Approval) {
        return Routes.placementApprovals(task.program_id)
    }

    return Routes.placementRequests(task.program_id)
}