import React from "react";
import {Badge, Box, Chip, Tab, Tabs} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import {Routes} from "../../consts";
import { useNavigation} from "../../hooks";

interface PlacementTabsProps {
    placementId: number;
}

// TODO: Update Component
export const PlacementTabs: React.FC<PlacementTabsProps> = ({placementId}) => {
    const location = useLocation();
    const {navigate} = useNavigation();

    const handleChange = (event: any, route: string) => {
        navigate(route);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                width: '100%',
                paddingBottom: 2,
                marginTop: 6,
                borderBottom: '1px solid',
                borderColor: 'divider',
                maxWidth: { xs: 960, md: '100%' }
            }}
        >
            <Tabs
                value={location.pathname}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab
                    sx={{whiteSpace: "nowrap", marginX: {xs: 1, md: 3}, paddingX: {xs: 1}}}
                    component={NavLink}
                    label="REQUESTS"
                    value={Routes.placementRequests(placementId)}
                    to={Routes.placementRequests(placementId)}
                    onClick={(event:React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                    }}
                />
                <Tab
                    sx={{whiteSpace: "nowrap", marginX: {xs: 1, md: 3}, paddingX: {xs: 1}}}
                    component={NavLink}
                    label="APPROVALS"
                    value={Routes.placementApprovals(placementId)}
                    to={Routes.placementApprovals(placementId)}
                    onClick={(event:React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                    }}
                />
                <Tab
                    sx={{whiteSpace: "nowrap", marginX: {xs: 1, md: 3}, paddingX: {xs: 1}}}
                    component={NavLink}
                    label="DELIVERABLES"
                    value={Routes.placementDeliverables(placementId)}
                    to={Routes.placementDeliverables(placementId)}
                    onClick={(event:React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                    }}
                />
                <Tab
                    sx={{whiteSpace: "nowrap", marginX: {xs: 1, md: 3}, paddingX: {xs: 1}}}
                    component={NavLink}
                    label="EVENTS"
                    value={Routes.placementEvents(placementId)}
                    to={Routes.placementEvents(placementId)}
                    onClick={(event:React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                    }}
                />
                <Tab
                    disabled
                    sx={{whiteSpace: "nowrap", marginX: {xs: 1, md: 3}, paddingX: {xs: 1}}}
                    component={NavLink}
                    label={<Box>ACCOUNT TEAM <Chip label="BETA" size="small" variant="outlined"/></Box>}
                    value={Routes.placementTeam(placementId)}
                    to={Routes.placementTeam(placementId)}
                    onClick={(event:React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
                        event.preventDefault();
                    }}
                />
            </Tabs>
        </Box>
    );
};