import React, {useEffect, useState} from "react";

import * as yup from 'yup';
import {useFormik} from 'formik';
import sortBy from 'lodash/sortBy';
import {DatePicker, LoadingButton} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Autocomplete
} from "@mui/material";

import {useAuthContext} from "../../contexts/AuthContext";
import {
    ActivityApprovalStatus,
    ActivityType,
    deliverableTypeList,
    Program,
    programLOBList,
    programTypeList,
    Task,
    TaskStatus,
    TaskType,
    taskTypeList,
} from "../../types";
import {useAddActivityMutation, useAddTaskMutation, useGetProgramListQuery, useGetUserListQuery} from "../../services";
import {FileCard} from "../UI";
import {API} from "../../API";
import {Endpoints, Routes} from "../../consts";
import {AlertSuccessModel, openAlert} from "../../store/alertSlice";
import {useAppDispatch} from "../../store/hooks";
import {getTasksRoute} from "../../utils";

interface CreateTaskModalProps {
    open: boolean;
    onClose: () => void;
    parentProgram?: Program;
    createTaskType?: TaskType;
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
    open,
    onClose,
    parentProgram,
    createTaskType = TaskType.Request,
}) => {
    const dispatch = useAppDispatch();
    const {user} = useAuthContext();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [program, setProgram] = useState<Program | undefined>(parentProgram);

    const {data: userList} = useGetUserListQuery({});

    const [
        addTask,
        {isSuccess: addTaskSuccess, isLoading: addTaskLoading, data: addTaskData}
    ] = useAddTaskMutation();
    const [
        addActivity, {isLoading: addActivityLoading}
    ] = useAddActivityMutation();

    const {data: programList} = useGetProgramListQuery({});

    const createTaskSchema = yup.object().shape({
        type: yup.string().required(''),
        target_date: createTaskType === TaskType.Deliverable ? yup.string() : yup.string().required(''),
        status: createTaskType === TaskType.Deliverable ? yup.string() : yup.string().required(''),
        name: yup.string().required('Name is required'),
        description: yup.string(),
        phase: yup.string(),
        assignee_id: yup.string().required('Please assign a user'),
        program_id: yup.string().required('Please assign a placement'),
        deliverable_type: yup.string(),
    });

    const createTaskForm = useFormik({
        initialValues: {
            name: '',
            description: '',
            phase: '',
            status: TaskStatus.Pending,
            type: createTaskType,
            target_date: new Date(),
            assignee_id: '',
            program_id: program?.id || '',
            deliverable_type: ''
        },
        validateOnMount: false,
        validationSchema: createTaskSchema,
        onSubmit: (formValues) => {
            if (user) {
                // TODO: update any
                const data: any = {
                    ...formValues,
                    owner_id: user.id,
                    program_id: +formValues.program_id,
                    client_id: user.client_id,
                    assignee_id: +formValues.assignee_id
                };

                //only use deliverable_type for Deliverable task type
                if(data.TaskType !== TaskType.Deliverable) {
                    delete data.deliverable_type;
                }
                
                addTask({ ...data, is_active: true})
            }
        }
    });
    const {values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched, validateForm, setErrors, setTouched, resetForm} = createTaskForm;

    // Close Confirm modal when request is created successfully
    useEffect(() => {
        if (addTaskSuccess && addTaskData) {
            dispatch(openAlert({
                title: 'Success',
                color: 'success',
                successModel: AlertSuccessModel.Task,
                linkItems: [
                    {label: addTaskData.name, path: Routes.placementTaskDetails(addTaskData.program_id, addTaskData.id)},
                    { label: addTaskData.program?.name, path: getTasksRoute(addTaskData)}
                ]
            }));

            if (values.type === TaskType.Request) {
                onClose();
                setShowConfirmModal(false);
                resetForm();
            }
        }
        // eslint-disable-next-line
    }, [addTaskSuccess, addTaskData]);


    // Upload Files if there are and if task created successfully
    useEffect(() => {
        if (addTaskData && addTaskData?.type !== TaskType.Request) {
            if (files.length === 0) {
                onClose();
                resetForm();
                return;
            }

            const formData = new FormData();
            formData.append('fileb', files[0], files[0].name);
            handleUploadFile(addTaskData, formData)
        }
        // eslint-disable-next-line
    }, [addTaskData]);

    // TODO: Update if possible
    const handleUploadFile = (task: Task, formData: FormData) => {
        setFileUploadLoading(true);
        addActivity({
            author_id: user?.id,
            task_id: task?.id,
            type: ActivityType.FileUpload,
            text: 'uploaded a document',
            approval_status: ActivityApprovalStatus.New
        })
            .then((res: any) => {
                // Upload File to DB if activity created successfully
                API.post(
                    Endpoints.Activity.UploadFile(res.data.id),
                    formData,
                    {
                        timeout: 100000000,
                        // onUploadProgress: (progressEvent) => {
                        //     handleUploadProgress(progressEvent);
                        // }
                    }
                ).then(res => {
                    // dispatch(openAlert({title: 'Documents attached successfully!'}));
                    resetForm();
                    setFiles([]);
                    onClose();
                })
            })
            .catch(err => {
                dispatch(openAlert({title: 'Something went wrong!', severity: 'error', color: 'error'}))
            })
            .finally(() => {
                setFileUploadLoading(false);
            })
    };

    const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const evFiles = e.target.files;
        if (evFiles && evFiles.length > 0) {
            //TODO: make multiple
            setFiles([evFiles[0]])
        }
    }

    const handleSubmitForm = () => {
        validateForm(values)
        .then(res => {
            if (Object.keys(res).length) {
                const fieldsToTouch: {[key: string]: boolean} = {};
                for (const field of Object.keys(res)) {
                    fieldsToTouch[field] = true
                }
                setTouched(fieldsToTouch)
                setErrors(res);
            } else {
                values.type === TaskType.Request ? setShowConfirmModal(true) : handleSubmit()
            }
        })
        .catch(err => {})
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} scroll="paper" maxWidth={"sm"} fullWidth>
                <DialogTitle color={'text.primary'} sx={{textTransform: 'capitalize'}}>
                    {`New ${taskTypeList[values.type]?.label}`}
                </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}} mb={2}>

                            {/* Type */}
                            <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel>Task Type *</InputLabel>
                                <Select
                                    label="Task Type *"
                                    variant="outlined"
                                    name={'type'}
                                    value={values.type}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={taskTypeList.request.value}>
                                        {taskTypeList.request.label}
                                    </MenuItem>
                                    <MenuItem value={taskTypeList.approval.value}>
                                        {taskTypeList.approval.label}
                                    </MenuItem>
                                    <MenuItem value={taskTypeList.deliverable.value}>
                                        {taskTypeList.deliverable.label}
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            {/* Task Template */}
                            {
                                values.type === TaskType.Request && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel>{taskTypeList[values.type]?.label} Template *</InputLabel>
                                        <Select
                                            disabled
                                            label={`${taskTypeList[values.type]?.label} Template *`}
                                            variant="outlined"
                                            name={'theme'}
                                            value={'Select template'}
                                            onChange={() => {}}
                                        >
                                            <MenuItem value={'Select template'}>
                                                Select template
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }

                            {/* Name */}
                            <FormControl fullWidth variant="outlined" sx={{m: 1, minWidth: 120}}>
                                <TextField
                                    fullWidth
                                    name={"name"}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={`${taskTypeList[values.type]?.label} Name *`}
                                    placeholder={'Type Title'}
                                    color="primary"
                                    variant="outlined"
                                    error={touched.name ? !!errors.name : undefined}
                                    helperText={touched.name ? errors.name : ''}
                                />
                            </FormControl>

                            {/* Description */}
                            <FormControl fullWidth variant="standard" sx={{m: 1, minWidth: 120}}>
                                <TextField
                                    label="Description"
                                    name={"description"}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    color="primary"
                                    error={touched.description ? !!errors.description : undefined}
                                    multiline
                                    rows={4}
                                />
                            </FormControl>


                            {/* Deliverable Type */}
                            {
                                values.type === TaskType.Deliverable && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel>Deliverable Type *</InputLabel>
                                        <Select
                                            label="Deliverable Type *"
                                            variant="outlined"
                                            name={'deliverable_type'}
                                            value={values.deliverable_type}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={deliverableTypeList.presentations.value}>
                                                {deliverableTypeList.presentations.label}
                                            </MenuItem>
                                            <MenuItem value={deliverableTypeList.addition_info.value}>
                                                {deliverableTypeList.addition_info.label}
                                            </MenuItem>
                                            <MenuItem value={deliverableTypeList.contracts.value}>
                                                {deliverableTypeList.contracts.label}
                                            </MenuItem>
                                            <MenuItem value={deliverableTypeList.market_list.value}>
                                                {deliverableTypeList.market_list.label}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }

                            {/* File Upload */}
                            {
                                values.type !== TaskType.Request && (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: 0.5,
                                        marginBottom: 1
                                    }}>
                                        <Typography variant="body1">Upload file</Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            Upload document(s) requiring an approval. File size should not exceed 15MB.
                                        </Typography>

                                        <Box
                                            component={'label'}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                py: 3,
                                                cursor: 'pointer',
                                                backgroundColor: '#DAF0F7',
                                                border: '1px dashed',
                                                borderColor: 'text.secondary'
                                            }}
                                        >
                                            {/*15 * 1024 * 1024*/}
                                            <input
                                                type="file"
                                                style={{display: 'none'}}
                                                onChange={handleFilesChange}
                                                disabled={addActivityLoading || fileUploadLoading}
                                            />
                                            <LoadingButton
                                                variant="outlined"
                                                color="primary"
                                                component="span"
                                                loading={addActivityLoading || fileUploadLoading}
                                            >
                                                CHOOSE A FILE
                                            </LoadingButton>
                                        </Box>

                                        {/*  Selected File  */}
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1,
                                            px: 1,
                                            py: 2
                                        }}>
                                            {
                                                files.length > 0 &&
                                                files.map((file, i) => (
                                                   <FileCard key={`file-${i}`} file={file} />
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                )
                            }

                            <Box sx={{
                                display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: {xs: 'column', md: 'row'}
                            }}>
                                {/*  Task Assignee To */}
                                <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                    <Autocomplete
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="assignee_id"
                                                label="Assignee*"
                                                variant="outlined"
                                                error={touched.assignee_id ? !!errors.assignee_id : undefined}
                                                helperText={touched.assignee_id ? errors.assignee_id : ''}
                                            />
                                        )}
                                        value={userList?.data.find(u => String(u.id) === values.assignee_id)}
                                        onChange={(e, value) => {
                                            setFieldValue('assignee_id' , value ? value.id : '')
                                        }}
                                        options={sortBy(userList?.data, ['name'])}
                                        getOptionLabel={(option) => option.name}
                                    />
                                </FormControl>

                                {/* Task Target Date */}
                                <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Due Date"
                                            value={values.target_date}
                                            minDate={new Date()}
                                            onChange={(newValue) => {
                                                setFieldValue('target_date', newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField fullWidth variant="outlined" {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                            <Divider sx={{}} />

                            {/* Placement */}
                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{ m: 1, minWidth: 120 }}
                                error={touched.program_id ? !!errors.program_id : undefined}
                            >
                                <InputLabel>Placement</InputLabel>
                                <Select
                                    disabled={!!parentProgram}
                                    label="Placement"
                                    variant="outlined"
                                    name={'program_id'}
                                    value={values.program_id}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setProgram(programList?.data?.find(p => p.id === e.target.value))
                                    }}

                                >
                                    {
                                        programList?.data && programList.data.length > 0 &&
                                        programList.data.map((programItem, i) => (
                                            <MenuItem key={`program-${i}`} value={programItem?.id}>
                                                {programItem?.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{touched.program_id ? errors.program_id : ''}</FormHelperText>
                            </FormControl>

                            {/* Placement Client */}
                            {
                                program && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel>Client</InputLabel>
                                        <Select
                                            disabled
                                            label="Client"
                                            variant="outlined"
                                            name={'client_id'}
                                            value={program.client?.id}
                                            onChange={() => {}}
                                        >
                                            <MenuItem value={program.client?.id}>
                                                {program.client?.common_name}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }


                            {/* Placement Type */}
                            {
                                program && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel>Reinsurance Type</InputLabel>
                                        <Select
                                            disabled
                                            label="Reinsurance Type"
                                            variant="outlined"
                                            name={'program_type'}
                                            value={program.type}
                                            onChange={() => {}}
                                        >
                                            <MenuItem value={program.type}>
                                                {programTypeList[program.type]?.label}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }


                            {/* Placement Line Of Business */}
                            {
                                program && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel>Line of Business</InputLabel>
                                        <Select
                                            disabled
                                            label="Line of Business"
                                            variant="outlined"
                                            name={'program_lob'}
                                            value={program.class_of_business}
                                            onChange={() => {}}
                                        >
                                            <MenuItem value={program.class_of_business}>
                                                {programLOBList[program.class_of_business]?.label}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }


                            {/* Placement Renewal Date*/}
                            {
                                program && (
                                    <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled
                                                label="Renewal Date"
                                                value={program.start_date}
                                                onChange={() => {}}
                                                renderInput={(params) => (
                                                    <TextField variant="outlined" {...params} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                )
                            }

                        </Box>
                    </form>
                </DialogContent>
                <DialogActions sx={{gap: 1}}>
                    <Button onClick={onClose}>
                        CANCEL
                    </Button>
                    <Button onClick={() => {}} disabled={values.name === ''}>
                        SAVE DRAFT
                    </Button>
                    <LoadingButton
                        type='submit'
                        loading={addTaskLoading}
                        onClick={handleSubmitForm}
                        disabled={values.name === ''}
                    >
                        {`${values.type === TaskType.Request ? 'SEND' : `SAVE`}`}
                    </LoadingButton>
                </DialogActions>
         </Dialog>

        {/* Confirm add new request dialog */}
        <Dialog open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <DialogTitle color={'text.primary'} sx={{textTransform: 'capitalize'}}>
                Send Information Request?
            </DialogTitle>
            <DialogContent>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1, maxWidth: 300}}>
                    <Typography variant="caption" color="text.secondary">
                        Would you like to send this Information Request to the assigned user? Once sent,
                        they will receive a notification in their inbox.
                    </Typography>
                    <LoadingButton size="large" loading={addTaskLoading} onClick={() => handleSubmit()} color="primary" variant="contained">
                        SEND
                    </LoadingButton>
                    <Button color="primary" size="large" variant="outlined" onClick={() => setShowConfirmModal(false)}>
                        DISCARD
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    </>
    )
}