import React from "react";

import {MainLayout} from "../../layouts";
import { Notifications } from "../../components/Notifications";

export const NotificationsPage = () => {
    return(
        <MainLayout>
            <Notifications />
        </MainLayout>
    )
};


