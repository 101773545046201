import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import {LoadingButton} from "@mui/lab";
import 'react-calendar-timeline/lib/Timeline.css';
import Timeline, {TimelineMarkers, TodayMarker} from 'react-calendar-timeline';
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Popover,
    Typography
} from "@mui/material";

import {PageLoader, TableEmptyList} from '../UI/';
import {useDeleteEventByIdMutation, useUpdateEventMutation} from '../../services';
import {EventCard} from '../EventCard';
import {CreateEventModal} from '../Modals';

export const PlacementEventsTimeline = ({programData, events, getEventListLoading}) => {
    const [groups, setGroups] = useState([]);

    const [eventToShow, setEventToShow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [showEditEventModal, setShowEditEventModal] = useState(false);
    const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);

    const [updateEvent, {isLoading: updateEventLoading, isSuccess: updateEventSuccess}] = useUpdateEventMutation();
    const [deleteEvent, {isLoading: deleteEventLoading, isSuccess: deleteEventSuccess}] = useDeleteEventByIdMutation();


    // TODO: UPDATE The function
    const items = useMemo(() => {
        if (events.length === 0) return [];

        const tempGroups = [{
            id: programData.id,
            title: programData.name,
            color: '#00AC41'
        }];

        setGroups(tempGroups);

        let modifiedEvents = [];

        modifiedEvents = [...events].map(ev => ({
            safeId: ev.id,
            id: ev.id,
            group: Number(ev.program_id),
            start_time: moment(new Date(ev.start_time)),
            // used program.target_date to avoid events overlying in timeline
            end_time: moment(new Date(programData.target_date)),
            title: `${ev.name} - ${moment(new Date(ev.date)).format('MM/DD/YY')}`,
            isProgram: false
        }));

        return modifiedEvents.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

    }, [events, programData]);


    const handleShowEventModal = (itemId, e) => {
        const event = events.find(ev => ev.id === itemId);
        setEventToShow(event);
        setAnchorEl(e.target);
    };

    const handleHideEventModal = () => {
        setEventToShow(null);
        setAnchorEl(null);
    };

    // Update Event
    const handleUpdateEvent = (event) => {
        updateEvent(event);
    }

    useEffect(() => {
        if (updateEventSuccess) {
            setShowEditEventModal(false);
            setEventToShow(null);
        }
    }, [updateEventSuccess]);

    // Delete Event
    const handleDeleteEvent = () => {
        deleteEvent(eventToShow.id)
    }

    useEffect(() => {
        if (deleteEventSuccess) {
            handleHideEventModal();
            setShowDeleteEventModal(false);
        }
    }, [deleteEventSuccess]);

    // For Drawing components
    const groupRenderer = ({group}) => {
        return (
            <div>
                <span className="title" style={{color: group.color}}>{group.title}</span>
            </div>
        );
    };

    const itemRenderer = ({item, itemContext, getItemProps}) => {
        return (
            <div {...getItemProps(item.itemProps)}>
                <div
                    className="rct-item-content"
                    style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                        color: '#000000',
                    }}
                >
                    {item.title}
                </div>
            </div>
        );
    };

    if (getEventListLoading) {
        return <PageLoader />
    }

    return (
        <Container disableGutters
           sx={{
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'flex-start',
               alignItems: 'center',
               width: '100%',
               maxWidth: '100%',
               minHeight: '600px'
           }}
        >
            {
                events.length === 0
                    ?
                        <TableEmptyList />
                    :
                    (groups.length > 0) && (items.length > 0) &&
                    <Timeline
                        className={'rc-timeline_wrapper'}
                        sidebarWidth={0}
                        canMove={false}
                        canResize={false}
                        groups={groups}
                        items={items}
                        defaultTimeStart={new Date(new Date().getFullYear(), 0)}
                        defaultTimeEnd={new Date(new Date().getFullYear() + 1, 0)}
                        timeSteps={{
                            second: 0,
                            minute: 0,
                            hour: 0,
                            day: 0,
                            month: 1,
                            year: 1
                        }}
                        lineHeight={42}
                        itemHeightRatio={.5}
                        canChangeGroup={false}
                        onItemSelect={(itemId, e, time) => {
                            handleShowEventModal(itemId, e)
                        }}
                        onItemClick={(itemId, e, time) => {
                            handleShowEventModal(itemId, e)
                        }}
                        groupRenderer={groupRenderer}
                        itemRenderer={itemRenderer}
                        stackItems={true}
                    >
                        <TimelineMarkers>
                            <TodayMarker>
                                {({ styles, date }) =>
                                    <div style={{...styles, backgroundColor: 'rgba(1, 26, 254, 0.5)'}} />
                                }
                            </TodayMarker>
                        </TimelineMarkers>
                    </Timeline>
            }


            {/* TODO: Event Details */}
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleHideEventModal}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                sx={{borderRadius: 16}}
            >
                {
                    eventToShow && (
                        <EventCard
                            event={eventToShow}
                            onDelete={() => setShowDeleteEventModal(true)}
                            onEdit={() => setShowEditEventModal(true)}
                        />
                    )
                }
            </Popover>

            {/* Update Event */}
                <CreateEventModal
                    open={showEditEventModal}
                    onClose={() => setShowEditEventModal(false)}
                    onCreateEvent={handleUpdateEvent}
                    loading={updateEventLoading}
                    createEventSuccess={updateEventSuccess}
                    event={eventToShow}
                    isEditMode={true}
                    program={programData}
                />

            {/* TODO: Delete Event */}
            <Dialog
                open={showDeleteEventModal}
                onClose={() => setShowDeleteEventModal(false)}
            >
                <DialogTitle color={'text.primary'}>Delete Event</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" mb={4}>
                        Are you sure want to delete "{eventToShow?.name}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteEventModal(false)}>
                        Cancel
                    </Button>

                    <LoadingButton
                        onClick={handleDeleteEvent}
                        loading={deleteEventLoading}
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>

        </Container>
    );
};