import React, {useEffect, useState} from "react";
import moment from "moment";
import {Box, Typography} from "@mui/material";

import {API} from "../../../API";
import {Endpoints} from "../../../consts";
import {Activity, ActivityType, User} from "../../../types";
import { componentLogger } from "../../../logger";

interface RowActivityCardProps {
    activity: Activity;
}

// TODO: Remove
export const RowActivityCard: React.FC<RowActivityCardProps> = ({ activity }) => {
    const [author, setAuthor] = useState<null | User>(null);

    useEffect(() => {
        handleGetUserDetails();
       // eslint-disable-next-line
    }, []);

    // TODO: Remove
    const handleGetUserDetails = async () => {
        try {
            const res = await API.get<User>(Endpoints.User.Details(activity.author.id));
            if(res.data !== null) {
                setAuthor(res.data);
            }
        } catch (err) {
            componentLogger.error('User details API error', err as Error);
        }
    };

    return (
        <Box sx={{display: 'flex', gap: 1}}>
            <Typography variant="caption" color="text.primary">
                {
                    `${author ? author.name : ''} ${
                        (activity.type === ActivityType.FileUpload && 'uploaded a document.') ||
                        (activity.type === ActivityType.Approval && 'approved.') || 
                        (activity.type === ActivityType.Denial && 'declined.') || 
                        'commented.'
                    }`
                }
            </Typography>
            <Typography variant="caption" color="text.secondary">
                {moment(activity.created_at).format('M/D/YYYY')}
            </Typography>
        </Box>
    );
};