import React from "react";
import {styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";

export const StatusTimeInfo = () => {
    return (
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
            <Box sx={{display: 'flex', marginBottom: {xs: 2, md: 0}}}>
                <Square/>
                <Typography variant="caption" color="text.primary" mr={2}>
                    Target date approaching soon
                </Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
                <RedSquare/>
                <Typography variant="caption" color="text.primary">
                    Past target date
                </Typography>
            </Box>
        </Box>
    );
};

const Square = styled(Box)(({theme}) => ({
    display: 'flex',
    width: 16,
    height: 16,
    background: theme.palette.warning.main,
    marginRight: 10
}));

const RedSquare = styled(Square)(({theme}) => ({
    background: theme.palette.error.light,
}));